<h2 mat-dialog-title class="titulo-modal pl-4">
  Pagamentos em Lote
  <i (click)="fecha()" class="material-icons btn-fechar">close</i>
  <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
  <div class="card">
    <div
      class="card-header card-header-primary card-header-icon"
      style="padding-left: 25px"
    >
      <div class="row">
        <div class="col-12 col-md-6" style="padding-left: 0">
          <div class="card-icon">
            <i class="fas fa-cubes fa-2x"></i>
          </div>
          <h4 class="card-title" style="font-weight: 600">
            Lista dos pagamentos
          </h4>
        </div>
      </div>
    </div>
    <div class="card-body" style="padding-top: 1.8rem !important">
      <div class="row">
        <div class="col-12 tabela-area">
          <div [hidden]="!processando" class="loading-bar">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>

          <div [hidden]="processando" class="borda">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              matSortActive="dataCriacao"
              matSortDirection="desc"
            >
              <!-- Descrição Column -->
              <ng-container matColumnDef="nomeBeneficiario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Beneficiário
                </th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  [matTooltip]="
                    row.documento_beneficiario !== 00000000000000
                      ? 'Razão Social: ' +
                        row.nome_beneficiario +
                        ' Documento: ' +
                        (row.documento_beneficiario | cpfCnpj)
                      : 'Razão Social: ' + row.nome_beneficiario
                  "
                >
                  {{ row.nome_beneficiario }}
                </td>
              </ng-container>

              <!-- {{ row.documento_beneficiario | cpfCnpj }}  -->

              <!-- Valor Original Column -->
              <ng-container matColumnDef="valorBoleto">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                >
                  Valor original
                </th>
                <td mat-cell *matCellDef="let row" style="padding-right: 20px">
                  <b>{{ row.valor_boleto | currency : 'BRL' : 'R$' }}</b>
                </td>
              </ng-container>

              <!-- Valor Pagamento Column -->
              <ng-container matColumnDef="valorPagamento">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  Valor pago
                </th>
                <td mat-cell *matCellDef="let row">
                  <b>{{ row.valor_pagamento | currency : 'BRL' : 'R$' }}</b>
                </td>
              </ng-container>

              <!-- Solicitação Column -->
              <ng-container matColumnDef="dataCriacao">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                >
                  Data de solicitação
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.dataCriacao | date : 'dd/MM/yyyy HH:mm' }}
                </td>
              </ng-container>

              <!-- Vencimento Column -->
              <ng-container matColumnDef="dataVencimento">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  Vencimento
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.data_vencimento | date : 'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <!-- Pagamento agendado para Column -->
              <ng-container matColumnDef="dataPagamento">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                >
                  Data de pagamento
                </th>
                <td mat-cell *matCellDef="let row">
                  {{
                    row.data_pagamento
                      ? (row.data_pagamento | date : 'dd/MM/yyyy')
                      : (row.data_agendamento | date : 'dd/MM/yyyy')
                  }}
                </td>
              </ng-container>

              <!-- Mensagem de status -->
              <ng-container matColumnDef="status">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  Status
                </th>
                <td mat-cell *matCellDef="let row">
                  <i
                    *ngIf="row.moderacaoRepresentante?.length > 0"
                    [matTooltip]="getTooltipModeracao(row)"
                    [class]="getIconeModeracao(row)"
                    style="margin-right: 10px; color: #0000008a"
                  ></i>
                  <i
                    [matTooltip]="parseStatus(row).description"
                    [attr.class]="parseStatus(row).class"
                  ></i>
                  <i
                    *ngIf="row.idImportacaoCnab"
                    class="fas fa-file-arrow-up icones"
                    style="color: #4fa9a9"
                    matTooltip="Importação."
                  ></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <div
              *ngIf="!processando && lista?.totalElements == 0"
              class="sem-resultado"
            >
              <fa-icon [icon]="['fas', 'scroll']" [size]="'2x'"></fa-icon>
              <p>Ainda não possui pagamentos cadastrados</p>
            </div>

            <mat-paginator
              [pageSizeOptions]="[10, 30, 50, 100]"
              [length]="lista?.totalElements"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div *ngIf="urlAjuda">
    <b>Ficou com Dúvidas?</b> Acesse nossa
    <a href="{{ urlAjuda }}" target="_blank" class="link">Central de Ajuda</a> e
    conheça o processo de importação de lote
  </div>
</mat-dialog-actions>
