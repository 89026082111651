import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-motivo-encerramento',
  templateUrl: './modal-motivo-encerramento.component.html',
  styleUrls: ['./modal-motivo-encerramento.component.less']
})
export class ModalMotivoEncerramentoComponent {
  motivo: string = "";

  constructor(
    public dialogRef: MatDialogRef<ModalMotivoEncerramentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  confirmar() {
    this.dialogRef.close({ motivo: this.motivo, confirmado: true });
  }

  fechar() {
    this.dialogRef.close({ confirmado: false });
  }
}
