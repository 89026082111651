import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { UtilCaniveteService } from '@project/src/app/service/util/util-canivete.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PagamentoEndpointService } from '@project/src/app/service/pagamento-endpoint.service';
import { PagamentoLoteSistemaDTO } from '@project/src/app/model/financeiro/pagamento/pagamento-lote-sistema-dto.model';

@Component({
  selector: 'app-pagamento-lote-sistema-modelo',
  templateUrl: './pagamento-lote-sistema-modelo.component.html',
  styleUrls: ['./pagamento-lote-sistema-modelo.component.less']
})
export class PagamentoLoteSistemaModeloComponent implements OnInit, OnDestroy{
  versao: string;
  destroy: Subject<void>;

  constructor(
    private pagamentoService: PagamentoEndpointService,
    private utilCaniveteService: UtilCaniveteService
  ) { }

  getForm(): AbstractControl {
    return new FormGroup({});
  }

  isValid(): boolean {
    return true;
  }
  parse(model: PagamentoLoteSistemaDTO): PagamentoLoteSistemaDTO {
    return model;
  }

  ngOnInit() {
    this.destroy = new Subject<void>();
    this.pagamentoService.versaoModeloSistema().pipe(takeUntil(this.destroy)).subscribe(response => {
      this.versao = response;
    }, error => {
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
      console.log(error);
    });
  }

  xlsx() {
    this.pagamentoService.loteModeloSistema().toPromise().then(response => {
      this.utilCaniveteService.downloadFileXlsx(response.base64, `modelo_pagamento_lote_sistema_${this.versao}.xlsx`);
    }, error => {
      console.error(error);
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
