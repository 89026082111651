import { PixEndpointService } from '../../../../../service/pix-endpoint.service';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ConfirmarPortabilidadeComponent } from '../../../pix/confirmar-portabilidade/confirmar-portabilidade.component';
import { MatDialog } from '@angular/material/dialog';
import { TipoChavePix } from '@project/src/app/model/financeiro/transferencia/tipo-chave-pix-dto-mode';


@Component({
  selector: 'app-chaves-pix-contato',
  templateUrl: './chaves-pix-contato.component.html',
  styleUrls: ['./chaves-pix-contato.component.less'],
})
export class ChavesPixContatoComponent implements OnInit {

  formLocal: FormGroup;
  chave: string;
  formTipos: FormGroup;
  isCnpj: boolean = true;
  @Input()
  public form: FormArray;

  // Numeração do ENUM do beckend
  cpf = 0;
  cnpj = 1;
  email = 2;
  celular = 3;
  evp = 4;

  //
  cpfCnpj;
  cpfCnpjMask: string;

  mascaraDinamica: any;

  @Input()
  empresaId: number;
  @Input()
  data: any;

  processando: boolean = false;
  listaChaves: any;
  maskteste: any;
  chavePixForm: any;
  documento: any;
  bancoContato: any;


  constructor(
    private pixService: PixEndpointService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    public toastr: ToastrService,
    public dialog: MatDialog,
    public autenticacao: AutenticacaoEndpointService
  ) {
  }

  ngOnInit(): void {
    this.processando = true;

    this.buildForm();


    if (this.data && this.data.contatoChavePix && this.data.contatoChavePix.length > 0) {
      this.data.contatoChavePix.forEach(chavePix => {
        this.add(chavePix);
      });
    }

    this.processando = false;

    this.documento = this.autenticacao.regraEmpresa.empresa.pessoa.documento
  }

  buildForm() {
    this.formLocal = this.fb.group({
      celular: ['', [Validators.pattern]],
      cpfCnpj: [''],
      email: ['', [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      aleatoria: ['', [Validators.minLength(5)]]
    });
  }

  inicializarChavesPix(): void {
    if (this.data && this.data?.contatoChavePix) {
        this.data.contatoChavePix.forEach((chavePix) => {
            const formGroup = this.criarFormGroupChavePix(chavePix);
            this.form.push(formGroup); // Adiciona ao FormArray para manipulação de formulário
        });
    }
}

criarFormGroupChavePix(chavePix: any): FormGroup {
  return this.fb.group({
      id: [chavePix.id],
      chave: [chavePix.chave, Validators.required],
      tipoChave: [chavePix.tipoChave, Validators.required],
      // Inclua outros campos conforme necessário
  });
}

  public getErrorMessage(field: string) {
    if (this.formLocal.get(field)) {
      const control = this.formLocal.get(field);
      if (control.hasError('pattern')) {
        if (field === 'email') {
          return 'Email inválido';
        } else if (field === 'celular') {
          return 'Telefone inválido';
        } else if (field === 'cpfCnpj') {
          return 'CPF/CNPJ inválido';
        }
      }
    }
    return 'Campo inválido';
  }

  validaTelefone(tel: FormControl) {
    if (tel && tel.value) {
      if (tel.value?.length === 11) {
        tel.setErrors(null);
        tel.clearAsyncValidators();
      } else {
        tel.setErrors({ valid: false });
      }
    }
  }

  buildChavePix(chave, tipoChave) {
    const chavePix = {
      chave: chave,
      tpChave: tipoChave,
    }
    return chavePix;
  }

  formataChaveEnum(param) {
    switch (param) {
      case 'cpf':
        return 0;
      case 'cnpj':
        return 1;
      case 'email':
        return 2;
      case 'celular':
        return 3;
      case 'evp':
        return 4;
    }
  }

  transformTipoPixString(param) {
    switch (param) {
      case 0:
        return 'CPF';
      case 1:
        return 'CNPJ';
      case 2:
        return 'Email';
      case 3:
        return 'Celular';
      case 4:
        return 'Aleatória';
    }
  }



  add(chavePix?: any): void {
    const chavePixGroup = this.fb.group({
      chave: [chavePix?.chave || '', Validators.required],
      tipoChave: [chavePix?.tipoChave || '', Validators.required],
    });

    this.form.push(chavePixGroup);
  }

  async cadastrarPixContato(tipo) {
    try {
      this.processando = true;
      let objetoChavePix;
      let valorChave = this.formLocal.get(tipo).value;

      if (!valorChave) {
        console.log(`Valor não encontrado para o tipo: ${tipo}`);
        this.processando = false;
        return;
      }

      if (this.formLocal.get('cpfCnpj').value?.length > 11 && tipo == 'cpfCnpj') {
        objetoChavePix = this.buildChavePix(this.formLocal.get('cpfCnpj').value,'cnpj');
      } else {
        objetoChavePix = this.buildChavePix(this.formLocal.get('cpfCnpj').value, 'cpf');
      }

      if (tipo == 'celular') {
        objetoChavePix = this.buildChavePix('+55' + this.formLocal.get('celular').value, 'celular');
      }

      if (tipo == 'email') {
        objetoChavePix = this.buildChavePix(this.formLocal.get('email').value, 'email');
      }

      if(tipo == 'aleatoria') {
        objetoChavePix = this.buildChavePix(this.formLocal.get('aleatoria').value, 'evp');
       }

      let existeChave = false;
      let chaveInfos;

      try {
        existeChave = await this.pixService.consultarChave(objetoChavePix.chave, this.formataChaveEnum(objetoChavePix.tpChave)).toPromise();
        chaveInfos = await this.pixService.consultarChave(objetoChavePix.chave, this.formataChaveEnum(objetoChavePix.tpChave)).toPromise();
      } catch (err) {
        existeChave = false;
      }

      if (!existeChave) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Não foi possível encontrar uma chave Pix cadastrada com esses dados.',
          icon: 'warning',
        }).then((result) => {
          if (result.isConfirmed) {
           return;
          }
        })

      }

      if (existeChave) {
        this.buscaBanco(chaveInfos.ispb);

        Swal.fire({
          title: 'Confirme as Informações da Conta',
          html: `
          <style type="text/css">
          .custom-text {
            font-weight: bold;
          }
          .custom-modal-content {
            display: flex;
            flex-direction: column;
            text-align: left;
          }
          .text-class {
            margin-bottom: 5px;
          }
          </style>
          <div class="custom-modal-content">
          <div class="text-class"><span class="custom-text">Nome: </span> ${chaveInfos.nome}</div>
          <div class="text-class"><span class="custom-text">Chave: </span> ${chaveInfos.chave}</div>
          <div class="text-class"><span class="custom-text">CPF/CNPJ: </span> ${chaveInfos.cpfCnpj}</div>
          <div class="text-class"><span class="custom-text">Agência: </span>${chaveInfos.nrAgencia}</div>
          <div class="text-class"><span class="custom-text">Conta: </span> ${chaveInfos.nrConta}</div>
          <div class="text-class"><span class="custom-text">Banco: </span> ${this.bancoContato ? this.bancoContato : 'Não encontrado'}</div>
          <div class="text-class"><span class="custom-text">Tipo: </span> ${this.transformTipoPixString(chaveInfos.tpChave)}</div>
            </div>     `, // Tipo de chave ou tipo de conta, ajuste conforme necessário
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.adicionarChavePix(valorChave, tipo);
            Swal.fire({
              title: 'Sucesso!',
              text: 'Chave cadastrada com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok'
            });
          } else {
            Swal.fire({
              title: 'Operação Cancelada!',
              text: 'O cadastro da chave Pix foi cancelado.',
              icon: 'info',
              confirmButtonText: 'Ok'
            });
          }
        });
      } else {
        Swal.fire({
          title: 'Atenção!',
          text: 'Não foi possível encontrar uma chave Pix cadastrada com esses dados.',
          icon: 'warning',
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: 'Erro!',
        text: err.error,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      this.formLocal.reset();
      this.processando = false;
    }
  }

  async buscaBanco(banco) {
    try {
      const response = await this.pixService.buscarBancoPorIspb(banco).toPromise();
        this.bancoContato = await response.nomeReduzido;
      } catch (error) {
      this.bancoContato = '';
    }
  }



  adicionarChavePix(chave, tpChave): void {
  const chaveJaExiste = this.form.value.some((chavePixForm: any) =>
    chavePixForm.chave === chave && chavePixForm.tipoChave === tpChave
  );

  // Se a chave já existe, não adiciona e pode mostrar uma mensagem de alerta
  if (chaveJaExiste) {
    Swal.fire({
      title: 'Atenção!',
      text: 'Chave Pix já cadastrada',
      icon: 'warning',
      confirmButtonText: 'Ok'
    })
    return;
  }

  let novaChavePixForm;

  if(tpChave != 'celular') {
   novaChavePixForm = this.fb.group({
    chave: [chave, Validators.required],
    tipoChave: [tpChave, Validators.required],
  });
}
  if(tpChave == 'celular') {
     novaChavePixForm = this.fb.group({
      chave: ['+55' + chave, Validators.required],
      tipoChave: [tpChave, Validators.required],
    });
  }

  this.form.push(novaChavePixForm);
  this.changeDetectorRef.detectChanges();
  }

  del(index: number): void {
    this.form.removeAt(index);

    console.log(this.form?.value);
  }


  copiarChave(link) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', link);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.success("Chave Pix copiada!", '', {
      positionClass: "toast-top-center",
      timeOut: 2000
    })
  }

  // async listarChavesContato() {
  //   try {
  //     this.processando = true;
  //     const res = await this.pixService.listarChaveContato(this.empresaId).toPromise();
  //     this.listaChaves = res.map(chave => ({
  //       ...chave,
  //       chaveFormatada: this.formatarChaveComMascara(chave.chave, this.getMask(chave.tpChave))
  //     }));
  //     this.processando = false;
  //     this.changeDetectorRef.detectChanges();

  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  excluirChave(param) {
    try {
      this.processando = true;
      const res = this.pixService.desativarChave(param, null).toPromise();

      if (res) {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Chave Pix excluída com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: 'Erro!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      this.processando = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  formatarChavePix(chave: string, tipoChave): string {
    switch (tipoChave) {
      case 'cpf':
        return this.formatarChaveComMascara(chave, '000.000.000-00');
      case 'cnpj':
        return this.formatarChaveComMascara(chave, '00.000.000/0000-00');
      case 'celular':
        return this.formatarChaveComMascara(chave, '000 00 00000-0000');
      default:
        return chave; // Retorna a chave sem formatação para os tipos que não requerem máscara específica
    }
  }


  formatarChaveComMascara(chave: string, mask: string): string {
    if (!mask || mask.length === 0) {
      return chave;
    }

    let result = '';
    let chaveIndex = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0') {
        if (chaveIndex === chave.length) {
          break;
        }
        result += chave[chaveIndex];
        chaveIndex++;
      } else {
        result += mask[i];
      }
    }

    return result;
  }

  getMask(tpChave): string {
    switch (tpChave) {
      case 'cpf': // CPF
        return '000.000.000-00';
      case 'cnpj': // CNPJ
        return '00.000.000/0000-00';
      case 'celular': // Telefone
        return '000 00 00000-0000';
      default:
        return ''; // Sem máscara
    }
  }

  converterTipoChaveParaEnum(valorNumerico: number): string {
    if  (valorNumerico === 0) {
      return 'cpf';
    }
    if  (valorNumerico === 1) {
      return 'cnpj';
    }
    if  (valorNumerico === 2) {
      return 'email';
    }
    if  (valorNumerico === 3) {
      return 'celular';
    }
    if  (valorNumerico === 4) {
      return 'evp';
    }
  }


  transformTipoPix(param) {
    switch (param) {
      case 'cpf':
        return 'CPF';
      case 'cnpj':
        return 'CNPJ';
      case 'email':
        return 'Email';
      case 'celular':
        return 'Celular';
      case 'evp':
        return 'Aleatória';
    }
  }


}
