<h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    Informe o motivo do encerramento
</h2>
<textarea matInput placeholder="Digite o motivo" [(ngModel)]="motivo" rows="4" maxlength="150"
    style="width: 100%; margin-bottom: 20px; padding: 50px">
</textarea>


<div mat-dialog-actions>
    <div class="row botao-centro">
        <div class="col-12 col-md-4">
            <button type="button" (click)="fechar()" class="btn btn-default btn-block"
                style="color: #fff;margin-left: 55%;" mat-raised-button>
                Cancelar
            </button>
        </div>

        <div class="col-12 col-md-4">
            <button type="submit" class="btn btn-block fundoCorPrimaria" (click)="confirmar()"
                style=" color: #fff;margin-left: 70%;" mat-raised-button>
                Enviar
            </button>
        </div>
    </div>
</div>