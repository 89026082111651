import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FalarComGerenteComponent } from '@project/src/app/components/falar-com-gerente/falar-com-gerente.component';
import { ModalConfirmaTokenComponent } from '@project/src/app/components/modal/modal-confirma-token/modal-confirma-token.component';
import { EmpresaEndpointService } from '@project/src/app/service/empresa-endpoint.service';
import swal from 'sweetalert2';
import { environment } from '@project/src/environments/environment';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { Router } from '@angular/router';
import { CobrancaEndpointService } from '@project/src/app/service/cobranca-endpoint.service';
import { HomeEndpointService } from '@project/src/app/service/home-endpoint.service';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import { ModalMotivoEncerramentoComponent } from './modal-motivo-encerramento/modal-motivo-encerramento.component';

@Component({
  selector: 'app-encerrar-conta',
  templateUrl: './encerrar-conta.component.html',
  styleUrls: ['./encerrar-conta.component.less']
})
export class EncerrarContaComponent implements OnInit {

  @Input()
  public data: any;

  @Input()
  public empresaId: number;
  public form: FormGroup;

  public numeroTelefone: string;
  public celular: string;
  public saldoAtual: number = 0.0;
  public empresaLogada: any;
  public saldosEmpresa: any;
  permissaoEncerrar = false;
  public motivo: string;

  constructor(
    private dialog: MatDialog,
    private empresaService: EmpresaEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    private router: Router,
    private cobrancaService: CobrancaEndpointService,
    private homeService: HomeEndpointService
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;
    const checkAuthorities = new CheckAuthoritiesService(this.autenticacao.sessao)
    this.permissaoEncerrar = checkAuthorities.permissaoGuard('ROLE_EMPRESA_CANCELAR')
  }

  ngOnInit() {
    this.form = new FormGroup({
      ciente: new FormControl(false, Validators.requiredTrue)
    });
    this.data = this.data || {};
  }

  scroll(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, 2);
  }

  public gerenteModal() {
    this.dialog.open(FalarComGerenteComponent, {
      width: "800px",
      panelClass: "fundo-empresa",
    });
  }

  public carregaTelefone(telefoneSeguranca) {
    let num: string = telefoneSeguranca.ddd + "" + telefoneSeguranca.numero;
    if (num.length === 10) {
      this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 6) + '-' + num.substring(6, 10);
    }
    else if (telefoneSeguranca.ddd && telefoneSeguranca.numero) {
      this.numeroTelefone = '(' + num.substring(0, 2) + ')' + num.substring(2, 7) + '-' + num.substring(7, 11);
    }
    else {
      this.numeroTelefone = '';
    }
    this.celular = num;
  }

  public async validarEncerramentoConta() {
    const [qntCobrancas, saldosEmpresa] = await Promise.all([
        this.cobrancaService.contarCobrancas(this.empresaId).toPromise(),
        this.homeService.getCardMeuDinheiro().toPromise()
    ]);
    
    if (qntCobrancas > 0) {
      swal.fire("VOCÊ TEM COBRANÇAS A RECEBER",
         "Exclua-as antes de solicitar o encerramento de sua conta.", "error");
      return;
    }

    if (saldosEmpresa.saldoDisponivel > 0) {
      swal.fire("SALDO NA CONTA!",
        "Transfira o saldo antes de solicitar o encerramento de sua conta.", "error" );
      return;
    } 

    if (saldosEmpresa.saldoDisponivel < 0) {
      swal.fire("CONTA NEGATIVA!",
        "Realize o pagamento antes de solicitar o encerramento de sua conta.", "error");
      return;
    }
    
    if (saldosEmpresa.saldoLiberar > 0) {
      swal.fire("SALDO A LIBERAR!",
        "Você possui saldo a ser liberado.", "error");
      return;
    }

    if (saldosEmpresa.saldoAguardandoProcessamento > 0) {
      swal.fire("SALDO AGUARDANDO PROCESSAMENTO!",
        "Você possui saldo aguardando processamento.", "error");
      return;
    } 

    if (saldosEmpresa?.saldoEmDebito > 0 || this.saldosEmpresa?.saldoEmDebito < 0) {
      swal.fire("SALDO EM DÉBITO!",
        "Você possui saldo em débito.", "error");
      return;
    }
        
    this.encerrarConta();
  }

  public async encerrarConta() {
    this.dialog.open(ModalMotivoEncerramentoComponent, {
      width: '500px',
      backdropClass: 'modal-alerta'
    }).afterClosed().subscribe(motivoResult => {
      if (motivoResult && motivoResult.confirmado) {
        const motivo = motivoResult.motivo;
  
        this.carregaTelefone(this.data.configuracao.telefoneSeguranca[0]);
        this.dialog.open(ModalConfirmaTokenComponent, {
          data: {
            mensagem: "Enviamos um token para solicitação de encerramento de conta.",
            estrutura: "encerrarConta",
            numeroTelefone: this.numeroTelefone,
            celular: this.celular,
          },
          height: '45vh',
          backdropClass: 'modal-alerta'
        }).afterClosed().subscribe(tokenResult => {
          if (tokenResult && tokenResult.validado) {
            const objEncerramento = {
              motivo: motivo,
            };
            this.empresaService.encerrarConta(objEncerramento, this.empresaId).subscribe(
              (res) => {
                swal.fire("SOLICITAÇÃO ENVIADA!",
                  "Lamentamos muito que você quis encerrar a sua conta conosco.", "success")
                .then(() => {
                  if (this.empresaId == this.empresaLogada.id) {
                    this.dialog.closeAll();
                    this.router.navigate(['/privado/principal']);
                  }
                });
              },
              (err) => {
                swal.fire("Erro", err.error, "error");
              }
            );
          }
        });
      }
    });
  }
  
  public showInfo() {
    swal.fire({
      title: "Informações Importantes",
      html: "<p> Caso exista saldo em sua conta, <br>realize a transferência dele. <br> Caso esteja negativado, <br>realize o pagamento antes de solicitar<br> o cancelamento de sua conta.</p>" +
        "<p>Se houver alguma dúvida,<br> estamos à disposição para auxiliar.<br> Entre em contato conosco.</p> " +
        " <p><strong> Contato </strong><br>" + environment.empresa.telefone.suporte + "<br>" + environment.empresa.email.suporte + "<br> Segunda à Sexta - 9:00 às 18:00 </p>"
      ,
      icon: "warning",
      confirmButtonText: "OK",
    });
  }

}
