import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class PublicidadeEndpointService {
  constructor(private client: HttpClient) { }

  salvarPublicidade(publicidade): Observable<any> {
    const url = '/publicidade/criar';
    return this.client.post(url, publicidade);
  }

  listarPublicidade(modalidade: string): Observable<any> {
    let params = new HttpParams();
    const url = '/publicidade/listar'
    params = params.append('modalidade', modalidade);
    return this.client.get(url, { params: params });
  }

  listarPublicidadeId(id): Observable<any> {
    const url = '/publicidade/listarById?id=' + id;
    return this.client.get(url);
  }

  deletarPublicidade(id): Observable<any> {
    const url = '/publicidade/deletar?id=' + id;
    return this.client.delete(url);
  }

  atualizarPublicidade(publicidade): Observable<any> {
    const url = '/publicidade/atualizar';
    return this.client.put(url, publicidade);
  }
}