export enum StatusExtratoEnum {
    taxaAntecipacao = 'taxaAntecipacao',
    taxaLiquidacaoCartao = 'taxaLiquidacaoCartao',
    taxaBaixaCartao = 'taxaBaixaCartao',
    taxaEdicaoCartao = 'taxaEdicaoCartao',
    taxaEmissaoCartao = 'taxaEmissaoCartao',
    taxaLiquidacaoBoleto = 'taxaLiquidacaoBoleto',
    taxaBaixaBoleto = 'taxaBaixaBoleto',
    taxaEdicaoBoleto = 'taxaEdicaoBoleto',
    taxaEmissaoBoleto = 'taxaEmissaoBoleto',
    taxaTransferenciaExterna = 'taxaTransferenciaExterna',
    taxaTransferenciaEntreAgencias = 'taxaTransferenciaEntreAgencias',
    taxaPagamentoConta = 'taxaPagamentoConta',
    taxaMensalidade = 'taxaMensalidade',
    taxaIntermediacaoNegocio = 'taxaIntermediacaoNegocio',
    taxaManutencaoTitulo = 'taxaManutencaoTitulo',
    estornoCartao = 'estornoCartao',
    estornoTaxaCartao = 'estornoTaxaCartao',
    estornoAntecipacao = 'estornoAntecipacao',
    estornoDevidoAFalha = 'estornoDevidoAFalha',
    saidaPagamentoConta = 'saidaPagamentoConta',
    saidaTransferenciaExterna = 'saidaTransferenciaExterna',
    saidaTransferenciaInterna = 'saidaTransferenciaInterna',
    entradaRoot = 'entradaRoot',
    saidaRoot = 'saidaRoot',
    entradaTransferenciaExterna = 'entradaTransferenciaExterna',
    entradaTransferenciaInterna = 'entradaTransferenciaInterna',
    entradaAntecipacao = 'entradaAntecipacao',
    entradaLiquidacaoBoleto = 'entradaLiquidacaoBoleto',
    entradaLiquidacaoCartao = 'entradaLiquidacaoCartao',
    taxaAtualizacaoCadastralAutomatica = 'taxaAtualizacaoCadastralAutomatica',
    taxaAberturaConta = 'taxaAberturaConta',
    taxaMensalidadeContaInativa = 'taxaMensalidadeContaInativa',
    taxaValidacaoCadastralContaPessoaFisicaSimples = 'taxaValidacaoCadastralContaPessoaFisicaSimples',
    taxaValidacaoCadastralContaPessoaFisicaCompleta = 'taxaValidacaoCadastralContaPessoaFisicaCompleta',
    taxaValidacaoCadastralContaPessoaJuridica = 'taxaValidacaoCadastralContaPessoaJuridica',
    taxaPesquisaSerasa = 'taxaPesquisaSerasa',
    taxaNegativacaoSerasa = 'taxaNegativacaoSerasa',
    taxaPagamentoTributosImpostosBoleto = 'taxaPagamentoTributosImpostosBoleto',
    taxaPagamentoBoletosDiversos = 'taxaPagamentoBoletosDiversos',
    taxaPagamentoContasConsumo = 'taxaPagamentoContasConsumo',
    taxaMensalPagamentoCartao = 'taxaMensalPagamentoCartao',
    taxaTransferenciaMinima = 'taxaTransferenciaMinima',
    taxaTransferenciaMaxima = 'taxaTransferenciaMaxima',
    taxaTransferenciaLote = 'taxaTransferenciaLote',
    taxaTransferenciaInterna = 'taxaTransferenciaInterna',
    taxaAvisoEmissaoFaturas = 'taxaAvisoEmissaoFaturas',
    taxaLembretesAntesDepoisVencimento = 'taxaLembretesAntesDepoisVencimento',
    taxaMensagemConfirmacaoPagamento = 'taxaMensagemConfirmacaoPagamento',
    taxaReducaoInadimplencia = 'taxaReducaoInadimplencia',
    taxaEnvioCompromissoAgendamentoCalendario = 'taxaEnvioCompromissoAgendamentoCalendario',
    taxaEnvioToken = 'taxaEnvioToken',
    taxaSolicitacaoExtrato = 'taxaSolicitacaoExtrato',
    estornoTaxaDevidoFalha = 'estornoTaxaDevidoFalha',
    taxaAberturaCredito = 'taxaAberturaCredito',
    taxaCustosCamaras = 'taxaCustosCamaras',
    saidaTaxaSelic = 'saidaTaxaSelic',
    recebimentoSelic = 'recebimentoSelic',
    taxaSensibilizacao = 'taxaSensibilizacao',
    taxaExtratoOutrosMeios = 'taxaExtratoOutrosMeios',
    taxaMicrofilmesMicrofichasAssemelhados = 'taxaMicrofilmesMicrofichasAssemelhados',
    taxaPagamentoArrecadacao = 'taxaPagamentoArrecadacao',
    taxaRegistroRecebiveis = 'taxaRegistroRecebiveis'
}

export type StatusExtrato = StatusExtratoEnum;

export class StatusExtratoInfo {
    static getNome(status: StatusExtrato): string {
        switch (status) {
            case StatusExtratoEnum.taxaAntecipacao:
                return 'Tarifa de antecipação';
            case StatusExtratoEnum.taxaLiquidacaoCartao:
                return 'Tarifa de liquidação de cartão';
            case StatusExtratoEnum.taxaBaixaCartao:
                return 'Tarifa de baixa de cartão';
            case StatusExtratoEnum.taxaEdicaoCartao:
                return 'Tarifa de edição de cartão';
            case StatusExtratoEnum.taxaEmissaoCartao:
                return 'Tarifa de emissão de cartão';
            case StatusExtratoEnum.taxaLiquidacaoBoleto:
                return 'Tarifa de liquidação de boleto';
            case StatusExtratoEnum.taxaBaixaBoleto:
                return 'Tarifa de baixa de boleto';
            case StatusExtratoEnum.taxaEdicaoBoleto:
                return 'Tarifa de edição de boleto';
            case StatusExtratoEnum.taxaEmissaoBoleto:
                return 'Tarifa de emissão de boleto';
            case StatusExtratoEnum.taxaTransferenciaExterna:
                return 'Tarifa de transferência externa';
            case StatusExtratoEnum.taxaTransferenciaEntreAgencias:
                return 'Tarifa de transferência entre agências';
            case StatusExtratoEnum.taxaPagamentoConta:
                return 'Tarifa de pagamento de conta';
            case StatusExtratoEnum.taxaMensalidade:
                return 'Tarifa de mensalidade';
            case StatusExtratoEnum.taxaIntermediacaoNegocio:
                return 'Tarifa de intermediação de negócio';
            case StatusExtratoEnum.taxaManutencaoTitulo:
                return 'Tarifa de manutenção de título';
            case StatusExtratoEnum.estornoCartao:
                return 'Estorno de cartão';
            case StatusExtratoEnum.estornoTaxaCartao:
                return 'Estorno de tarifa de cartão';
            case StatusExtratoEnum.estornoAntecipacao:
                return 'Estorno de antecipação';
            case StatusExtratoEnum.estornoDevidoAFalha:
                return 'Estorno devido a falha';
            case StatusExtratoEnum.saidaPagamentoConta:
                return 'Saída de pagamento de conta';
            case StatusExtratoEnum.saidaTransferenciaExterna:
                return 'Saída de transferência externa';
            case StatusExtratoEnum.saidaTransferenciaInterna:
                return 'Saída de transferência interna';
            case StatusExtratoEnum.entradaRoot:
                return 'Entrada root';
            case StatusExtratoEnum.saidaRoot:
                return 'Saída root';
            case StatusExtratoEnum.entradaTransferenciaExterna:
                return 'Entrada de transferência externa';
            case StatusExtratoEnum.entradaTransferenciaInterna:
                return 'Entrada de transferência interna';
            case StatusExtratoEnum.entradaAntecipacao:
                return 'Entrada de antecipação';
            case StatusExtratoEnum.entradaLiquidacaoBoleto:
                return 'Entrada de liquidação de boleto';
            case StatusExtratoEnum.entradaLiquidacaoCartao:
                return 'Entrada de liquidação de cartão';
            case StatusExtratoEnum.taxaAtualizacaoCadastralAutomatica:
                return 'Tarifa de atualização cadastral automática';
            case StatusExtratoEnum.taxaAberturaConta:
                return 'Tarifa de abertura de conta';
            case StatusExtratoEnum.taxaMensalidadeContaInativa:
                return 'Tarifa de mensalidade de conta inativa';
            case StatusExtratoEnum.taxaValidacaoCadastralContaPessoaFisicaSimples:
                return 'Tarifa de validação cadastral de conta pessoa física simples';
            case StatusExtratoEnum.taxaValidacaoCadastralContaPessoaFisicaCompleta:
                return 'Tarifa de validação cadastral de conta pessoa física completa';
            case StatusExtratoEnum.taxaValidacaoCadastralContaPessoaJuridica:
                return 'Tarifa de validação cadastral de conta pessoa jurídica';
            case StatusExtratoEnum.taxaPesquisaSerasa:
                return 'Tarifa de pesquisa Serasa';
            case StatusExtratoEnum.taxaNegativacaoSerasa:
                return 'Tarifa de negativação Serasa';
            case StatusExtratoEnum.taxaPagamentoTributosImpostosBoleto:
                return 'Tarifa de pagamento de tributos e impostos por boleto';
            case StatusExtratoEnum.taxaPagamentoBoletosDiversos:
                return 'Tarifa de pagamento de boletos diversos';
            case StatusExtratoEnum.taxaPagamentoContasConsumo:
                return 'Tarifa de pagamento de contas de consumo';
            case StatusExtratoEnum.taxaMensalPagamentoCartao:
                return 'Tarifa mensal de pagamento de cartão';
            case StatusExtratoEnum.taxaTransferenciaMinima:
                return 'Tarifa de transferência mínima';
            case StatusExtratoEnum.taxaTransferenciaMaxima:
                return 'Tarifa de transferência máxima';
            case StatusExtratoEnum.taxaTransferenciaLote:
                return 'Tarifa de transferência em lote';
            case StatusExtratoEnum.taxaTransferenciaInterna:
                return 'Tarifa de transferência interna';
            case StatusExtratoEnum.taxaAvisoEmissaoFaturas:
                return 'Tarifa de aviso de emissão de faturas';
            case StatusExtratoEnum.taxaLembretesAntesDepoisVencimento:
                return 'Tarifa de lembretes antes e depois do vencimento';
            case StatusExtratoEnum.taxaMensagemConfirmacaoPagamento:
                return 'Tarifa de mensagem de confirmação de pagamento';
            case StatusExtratoEnum.taxaReducaoInadimplencia:
                return 'Tarifa de redução de inadimplência';
            case StatusExtratoEnum.taxaEnvioCompromissoAgendamentoCalendario:
                return 'Tarifa de envio de compromisso de agendamento de calendário';
            case StatusExtratoEnum.taxaEnvioToken:
                return 'Tarifa de envio de token';
            case StatusExtratoEnum.taxaSolicitacaoExtrato:
                return 'Tarifa de solicitação de extrato';
            case StatusExtratoEnum.estornoTaxaDevidoFalha:
                return 'Estorno de tarifa devido a falha';
            case StatusExtratoEnum.taxaAberturaCredito:
                return 'Tarifa de abertura de crédito';
            case StatusExtratoEnum.taxaCustosCamaras:
                return 'Tarifa de custos de câmaras';
            case StatusExtratoEnum.saidaTaxaSelic:
                return 'Saída de tarifa Selic';
            case StatusExtratoEnum.recebimentoSelic:
                return 'Recebimento Selic';
            case StatusExtratoEnum.taxaSensibilizacao:
                return 'Tarifa de sensibilização';
            case StatusExtratoEnum.taxaExtratoOutrosMeios:
                return 'Tarifa de extrato por outros meios'
            case StatusExtratoEnum.taxaMicrofilmesMicrofichasAssemelhados:
                return 'Tarifa de cópia de microfilmes, microfichas ou assemelhados'
            case StatusExtratoEnum.taxaPagamentoArrecadacao:
                return 'Tarifa de pagamento de arrecadação'
            case StatusExtratoEnum.taxaRegistroRecebiveis:
                return 'Tarifa de registro de recebíveis'
            default:
                return status;
        }
    }
}