<h2 mat-dialog-title class="titulo-modal pl-4">

    Transferências em Lote
    <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
</h2>

<mat-dialog-content class="mat-dialog-content-responsive input-menor mudaCor">
    <div class="card">
        <div class="card-header card-header-primary card-header-icon" style=" padding-left: 25px; ">
            <div class="row">
                <div class="col-12 col-md-6" style="padding-left: 0">
                    <div class="card-icon">
                        <i class="fas fa-cubes fa-2x"></i>
                    </div>
                    <h4 class="card-title" style="font-weight: 600">Lista das transferências</h4>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding-top: 1.8rem !important">
            <div class="row">
                <div class="col-12 tabela-area">
                    <div [hidden]="!processando" class="loading-bar">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>

                    <div [hidden]="processando" class="borda">
                        <table mat-table [dataSource]="dataSource" matSort matSortActive="dataCriacao"
                            matSortDirection="desc">

                            <!-- Data de solicitação -->
                            <ng-container matColumnDef="dataCriacao">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de solicitação</th>
                                <td mat-cell *matCellDef="let row"> {{ row.dataCriacao | date:'dd/MM/yyyy HH:mm' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="contaFavorecida">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Titular da conta bancária</th>
                                <td mat-cell *matCellDef="let row"
                                    matTooltip="Banco: {{row.banco || env.nomeApp}} | Agencia: {{row.agencia || env.codAgencia}} | Conta: {{row.conta?row.conta:row.contaInterna}}">
                                    {{ row.titular }}</td>
                            </ng-container>

                            <ng-container matColumnDef="descricao">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</th>
                                <td mat-cell *matCellDef="let row" class="descricao-wraper"
                                    matTooltip="{{ row.descricao }}">{{ row.descricao
                                    }}</td>
                            </ng-container>

                            <!-- Mensagem de status -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let row">
                                    <i [matTooltip]="(row?.dataAgendamento && row?.status === 'pendente') ?
                                    'Transferência agendada ' + (row?.dataAprovacao ? 'e aprovada ' : '') + 'para ' + (row?.dataAgendamento | date: 'dd/MM/yyyy') :
                                    (row?.dataAgendamento && row?.status === 'rejeitada') ?
                                    'Transferência agendada para ' + (row?.dataAgendamento | date: 'dd/MM/yyyy') + ', cancelada' :
                                    row?.status === 'rejeitada' ? 'Transferência rejeitada' :
                                    row?.status === 'cancelada' ? 'Transferência cancelada na cabine' : row?.situacao"
                                        [class]="(row.status === 'confirmada' || row.status === 'devolvida') ? 'fas fa-check icones' : 
                                            row.status === 'rejeitada' ? 'fas fa-times icones' : 
                                            (row.status === 'pendente' && row.dataAgendamento != null) ? 'fas fa-calendar' : 
                                            row.status === 'cancelada' ? 'fas fa-times icones' :
                                            'fas fa-hourglass-half icones'" [style.color]="(row.status === 'confirmada' || row.status === 'devolvida') ? '#4FA953' : 
                                                  row.status === 'rejeitada' ? '#FE431C' : 
                                                  row.status === 'cancelada' ? '#FE431C' :
                                                  row.dataAprovacao != null ? '#4FA953' : '#DFB924'">
                                    </i>
                                    <i *ngIf="row.status === 'devolvida'" [matTooltip]="'Transferência devolvida'"
                                        class="fas fa-rotate-left" style="margin-right: 10px; color: #4FA953"></i>
                                    <i *ngIf="row.cpfCnpjTedExterna || row.codBancoOrigemTed || row.agenciaBancoOrigemTed"
                                        style="color: #4FA953;" class="fas fa-arrow-down ml-2"
                                        matTooltip="Transferência de entrada"></i>
                                    <i *ngIf="!row.cpfCnpjTedExterna && !row.codBancoOrigemTed && !row.agenciaBancoOrigemTed"
                                        style="color: #FE431C;" class="fas fa-arrow-up ml-2"
                                        matTooltip="Transferência de saída"></i>
                                </td>
                            </ng-container>

                            <!-- Valor -->
                            <ng-container matColumnDef="valor">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.valor | currency:'':'R$':'':'PT-BR'}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>

                        <div *ngIf="!processando && lista?.totalElements == 0" class="sem-resultado">
                            <fa-icon [icon]="['fas', 'scroll']" [size]="'2x'"></fa-icon>
                            <p>Ainda não possui transferências cadastrados</p>
                        </div>

                        <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]" [length]="lista?.totalElements">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div *ngIf="urlAjuda">
        <b>Ficou com Dúvidas?</b> Acesse nossa <a href={{urlAjuda}} target="_blank" class="link">Central de Ajuda</a> e
        conheça o
        processo de importação de lote
    </div>
</mat-dialog-actions>