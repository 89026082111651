<div class="row">
  <ng-container *ngIf="!mostrarResumo">
    <div class="col-12 titulo align-self-end text-center">
      <span> Upload e Validação </span>
    </div>
  
    <div
      class="col-12 subtitulo align-self-end text-center"
      *ngIf="!dadosValidacao"
    >
      <span> Faça o upload do arquivo </span>
    </div>
  
    <ng-container *ngIf="dadosValidacao">
      <div
        class="col-12 subtitulo align-self-end text-center"
        *ngIf="dadosValidacao.status"
      >
        <span> O arquivo esta pronto para ser processado </span>
      </div>
      <div
        class="col-12 subtitulo align-self-end text-center"
        *ngIf="!dadosValidacao.status"
      >
        <span> O arquivo não esta pronto para ser processado </span>
      </div>
    </ng-container>
  
    <div class="col-12 align-self-end d-flex justify-content-center">
      <app-upload-arquivo (onUpload)="upload($event)"></app-upload-arquivo>
    </div>
  
    <ng-container *ngIf="dadosValidacao">
      <div
        class="col-12 subtitulo align-self-end text-center caixa-download"
        *ngIf="!dadosValidacao.status"
      >
        <div>
          <span>
            Para corrigir os erros encontrados no arquivo analisado, baixe o
            arquivo que consta nosso feedback para corrigí-los. Após os ajustes,
            faça o upload do mesmo arquivo nesta página e aguarde a nova validação
          </span>
        </div>
        <div>
          <button
            type="button"
            class="btn mat-primary btn-filtro"
            (click)="download()"
          >
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            Download do arquivo completo com feedbacks
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="mostrarResumo">
    <div class="col-12 titulo align-self-end text-center">
      <span> Resumo das transferências </span>
    </div>
    <table class="tabela-resumo">
      <tr style="border-bottom: solid 1px #f5f5f5 !important;">
        <th>Conta de Origem</th>
        <th>Conta de Destino</th>
        <th>Valor</th>
        <th>Tipo</th>
      </tr>
      <tr *ngFor="let item of dadosValidacao.itens">
        <td style="width: 40%" [matTooltip]="item.dadosOrigem">{{item.dadosOrigem}}</td>
        <td style="width: 45%" [matTooltip]="item.dadosDestino">{{item.dadosDestino}}</td>
        <td style="width: 10%">{{item.valor | currency: 'BRL': 'R$'}}</td>
        <td style="width: 5%">
          {{getTipo(item)}} 
          <i *ngIf="item.dataAgendamento" class="fas fa-calendar" style="color:#4FA953"></i>
        </td>
      </tr>
    </table>
  </ng-container>
</div>
