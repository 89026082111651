import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UsuarioEndpointService } from "@service/usuario-endpoint.service";
import { RegraEmpresaDTO } from "@project/src/app/model/pessoa/regra-empresa-dto.model";
import moment from 'moment';
import {
  IPessoaService,
  PessoaServiceBuilder,
} from "@service/pessoa-endpoint.service";
import { EmpresaEndpointService } from "@service/empresa-endpoint.service";
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { ExportarPlanilhaService } from '@service/exportar-planilha.service';
import { environment } from '@src/environments/environment';
import Swal from 'sweetalert2';
import { StringFormatService } from '@project/src/app/service/util/stringFormater.service';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import { isNumber, isString } from 'highcharts';

@Component({
  selector: 'app-exportar-conciliacao',
  templateUrl: './exportar-conciliacao.component.html',
  styleUrls: ['./exportar-conciliacao.component.less']
})
export class ExportarConciliacaoComponent implements OnInit, OnDestroy {
  tiposConta = [
    {
      tipo: 'contaDigitalS',
      nome: 'Conta Digital S'
    },
    {
      tipo: 'contaCobrancaDigital',
      nome: 'Conta Cobrança Digital'
    },
    {
      tipo: 'contaCorrenteDigital',
      nome: 'Conta Corrente Digital'
    },
    {
      tipo: 'contaVinculadaDigital',
      nome: 'Conta Vinculada Digital'
    },
    {
      tipo: 'contaServicoDigital',
      nome: 'Conta Serviço Digital'
    },
    {
      tipo: 'contaSalarioDigital',
      nome: 'Conta Salário Digital'
    },
    {
      tipo: 'contaBloqueioJudicial',
      nome: 'Conta Bloqueio Judicial'
    },
    {
      tipo: 'contaEscrowDigital',
      nome: 'Conta Escrow Digital'
    },
    {
      tipo: 'contaInvestidorDigital',
      nome: 'Conta Investidor Digital'
    },
    {
      tipo: 'contaDigital',
      nome: 'Conta Digital'
    }
  ]
  form: FormGroup;
  private service: IPessoaService;
  formAgencia: FormGroup;
  formRelatorio: FormGroup;
  formCorrentista: FormGroup;
  formIntegral: FormGroup;
  formInterno: FormGroup;
  formPlanilhas: FormGroup;
  formRelatorioSimplificado: FormGroup;
  formContabil: FormGroup;
  formTarifas: FormGroup;
  corporacaoList: any = [];
  empresaList: any = [];
  filtroCorporacao: any[];
  filtroEmpresa: any[];
  corporacaoNome: any;
  empresaNome: string;
  quantidadeEmpresas: number;
  processando: boolean = false;
  usuarioLogado: any;
  empresaLogada: any;
  anoAtual: Date = new Date();
  anos: any = [];
  filtroData = {
    dataInicial: moment().subtract(30, 'days').toDate(),
    dataFinal: new Date()
  };
  ehColaborador = false;

  tipoExtrato: string;
  exportarAte: any;
  exportarDe: any;
  env = environment;
  listaEmpresa: any;
  today = new Date();
  agencias: any[];

  constructor(
    private fb: FormBuilder,
    private autenticacao: AutenticacaoEndpointService,
    public dialogRef: MatDialogRef<ExportarConciliacaoComponent>,
    private usuario: UsuarioEndpointService,
    public activatedRoute: ActivatedRoute,
    private injector: Injector,
    private empresaService: EmpresaEndpointService,
    private exportarPlanilha: ExportarPlanilhaService,
    public stringFormat: StringFormatService
  ) {
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa;
    const checkAuthorities = new CheckAuthoritiesService(this.autenticacao.sessao)
    this.ehColaborador = checkAuthorities.isColaborador;

    if(this.env.modulos.multiagencias){
      this.getAgencias();
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      corporacao: new FormControl(null, [Validators.required]),
      empresa: new FormControl(null),
    });

    this.processando = false;
    this.formAgencia = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      banco: new FormControl(''),
      tudo: new FormControl(false),
      boletos: new FormControl(false),
      teds: new FormControl(false),
      pagamentos: new FormControl(false),
      cartao: new FormControl(false),
      antecipacoes: new FormControl(false),
      manutencao: new FormControl(false),
      correntista: new FormControl(''),
      corporacao: new FormControl(''),
      empresa: new FormControl(''),
      fechamentoDiario: new FormControl(false),
      conciliacaoSTR: new FormControl(false),
      tiposConta: new FormControl([]),
      agencia: new FormControl('')
    })
    this.formAgencia.setValidators(this.validacaoDatas(this.formAgencia));
    
    this.formRelatorio = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      banco: new FormControl(''),
      agencia: new FormControl('')
    })

    this.formIntegral = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      banco: new FormControl(''),
      agencia: new FormControl('')
    })
    this.formIntegral.setValidators(this.validacaoDatas(this.formIntegral));

    this.formInterno = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      interno: new FormControl('true'),
      agencia: new FormControl('')
    })
    this.formInterno.setValidators(this.validacaoDatas(this.formInterno));

    this.formPlanilhas = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      agencia: new FormControl('')
    })
    this.formPlanilhas.setValidators(this.validacaoDatas(this.formPlanilhas));

    this.formRelatorioSimplificado = new FormGroup({
      agencia: new FormControl('')
    })

    this.formContabil = new FormGroup({
      tipo: new FormControl(''),
      contaContabil: new FormControl('4192500000'),
      de: new FormControl(''),
      ate: new FormControl(''),
      agencia: new FormControl('')
    })
    this.formContabil.setValidators(this.validacaoDatas(this.formContabil));

    this.formTarifas = new FormGroup({
      de: new FormControl(''),
      ate: new FormControl(''),
      corporacao: new FormControl(''),
      empresa: new FormControl(''),
      agencia: new FormControl('')
    })
    this.formTarifas.setValidators(this.validacaoDatas(this.formTarifas));

    this.form
      .get("corporacao")
      .setValue(
        this.autenticacao.regraCorporacao
          ? this.autenticacao.regraCorporacao.corporacao
          : ""
      );
    if (this.autenticacao.regraCorporacao) {
      this.corporacaoNome = this.autenticacao.regraCorporacao.corporacao.nome;
    }

    if (this.autenticacao.regraEmpresa) {
      this.empresaNome = this.displayEmp(this.autenticacao.regraEmpresa.empresa);
    }
    this.corporacaoNome = this.autenticacao.regraCorporacao.corporacao.nome;
    this.form.get("empresa").setValue(this.autenticacao.regraEmpresa || "");

    this.service = PessoaServiceBuilder.getInstance(this.injector, "empresa");

    this.usuario
      .getCorporacoesDoUsuario()
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.corporacaoList = response;

          this.getEmpresasDaCorporacao(null);
        },
        (error) => {
          //this.disloading();
        }
      );

    for (let index = this.anoAtual.getFullYear(); index >= new Date(this.usuarioLogado.dataCriacao).getFullYear(); index--) {
      this.anos.push(index);
    }

    this.formAgencia.get('banco').valueChanges.subscribe((valor)=>{
      if(valor!==""){
        this.formAgencia.get('tiposConta').disable()
      }else{
        this.formAgencia.get('tiposConta').enable()
      }
      this.formAgencia.get('tiposConta').setValue([])
    })
  }

  ngOnDestroy() { }

  public _filterEmpresa(searchValue: string): RegraEmpresaDTO[] {
    if(!isString(searchValue) && !isNumber(searchValue)) return;
    if (isNumber(searchValue)) {
      return this.empresaList.filter((empresa) =>
        empresa.pessoa.documento.toLowerCase().includes(searchValue)
      );
    } else {
      return this.empresaList.filter((empresa) =>
        (empresa.pessoa.razaoSocial || empresa.pessoa.nome)
          .toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  }

  Str(event, name) {
    if (event.checked == true) {
      if (name == "conciliacaoSTR") {
        this.formAgencia.get('fechamentoDiario').setValue(false);
      }
      if (name == "fechamentoDiario") {
        this.formAgencia.get('conciliacaoSTR').setValue(false);
      }
    }
  }

  getEmpresasDaCorporacao(corporacao) {
    let empresa: number;

    if (corporacao) {
      this.form.get('empresa').setValue('');
      empresa = corporacao.id;
    }
    let page = 0, size = 20;
    this.empresaService
      .listar({ idCorporacao: empresa, page, size })
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.empresaList = res.content;
          this.filtroCorporacao = this.corporacaoList;
          this.filtroEmpresa = this.empresaList;

          this.formAgencia.get("corporacao").valueChanges.subscribe((v)=>{
            this.filtroCorporacao = this._filterCorporacao(v)
          });

          this.formAgencia.get("empresa").valueChanges.subscribe((v)=>{
            this.filtroEmpresa = this._filterEmpresa(v)
          });

          this.formTarifas.get("corporacao").valueChanges.subscribe((v)=>{
            this.filtroCorporacao = this._filterCorporacao(v)
          });

          this.formTarifas.get("empresa").valueChanges.subscribe((v)=>{
            this.filtroEmpresa = this._filterEmpresa(v)
          });
        },
        (error) => {
          this.empresaList = [];
          this.form.get("empresa").setValue("");
        }
      );
  }

  getComp(empresa: any) {
    if (empresa.tipoConta === `contaDigitalS`) {
      return `000`;
    } else {
      return this.env.codBanco;
    }
  }

  displayEmp(empresa: any): any {
    if (empresa === "") {
      return empresa;
    }

    if (empresa && !empresa.empresa) {
      if (empresa.tipoConta) {
        let compEmpresa = empresa.tipoConta === `contaDigitalS` ? '000' : environment.codBanco;
        let tipoEmpresa = '';

        if (empresa.pessoa) {
          this.empresaNome = empresa.pessoa.razaoSocial;
        }

        const agencia = empresa.agencia ? empresa.agencia.padStart(4, '0') : environment.codAgencia;

        switch (empresa.tipoConta) {
          case 'contaDigitalS': {
            tipoEmpresa = 'C/Digital S';
            break;
          }
          case 'contaCobrancaDigital': {
            tipoEmpresa = 'C/ContaCobrancaDigital';
            break;
          }
          case 'contaCorrenteDigital': {
            tipoEmpresa = 'C/Corrente';
            break;
          }
          case 'contaVinculadaDigital': {
            tipoEmpresa = 'C/Vinculada';
            break;
          }
          case 'contaEscrowDigital': {
            tipoEmpresa = 'C/Escrow';
            break;
          }
          case 'contaBloqueioJudicial': {
            tipoEmpresa = 'C/Bloqueio';
            break;
          }
          case 'contaSalarioDigital': {
            tipoEmpresa = 'C/Salario';
            break;
          }
          case 'contaServicoDigital': {
            tipoEmpresa = 'C/Serviço';
            break;
          }
          case 'contaInvestidorDigital': {
            tipoEmpresa = 'C/Investidor';
            break;
          }
          case 'contaDigital': {
            tipoEmpresa = 'C/Digital';
            break;
          }
        }

        const contaEmpresa = empresa.numeroConta.substring(0, empresa.numeroConta.length - 1) + '-' + empresa.numeroConta.substring(empresa.numeroConta.length - 1, empresa.numeroConta.length);
        return (
          (empresa.pessoa.razaoSocial || empresa.pessoa.nome) + " - " + empresa.pessoa.documento +
          ' Bco ' + compEmpresa + ' - Ag: ' + agencia + ' - ' + tipoEmpresa + ': ' + contaEmpresa
        );
      } else {
        if (empresa.pessoa.razaoSocial) {
          return empresa.pessoa.razaoSocial + ' - ' + empresa.pessoa.documento
        } else {
          return empresa.pessoa.nome + ' - ' + empresa.pessoa.documento
        }
      }
    } else {
      if (empresa.empresa.tipoConta) {
        let compEmpresa = empresa.empresa.tipoConta === `contaDigitalS` ? '000' : environment.codBanco;
        let tipoEmpresa = '';

        if (empresa.empresa.pessoa) {
          this.empresaNome = empresa.empresa.pessoa.razaoSocial;
        }

        const agencia = empresa.empresa.agencia ? empresa.empresa.agencia.padStart(4, '0') : environment.codAgencia;

        switch (empresa.empresa.tipoConta) {
          case 'contaDigitalS':  {
            tipoEmpresa = 'Conta Digital S';
            break;
          }
          case 'contaCobrancaDigital': {
            tipoEmpresa = 'C/ContaCobrancaDigital';
            break;
          }
          case 'contaCorrenteDigital': {
            tipoEmpresa = 'C/Corrente';
            break;
          }
          case 'contaVinculadaDigital': {
            tipoEmpresa = 'C/Vinculada';
            break;
          }
          case 'contaEscrowDigital': {
            tipoEmpresa = 'C/Escrow';
            break;
          }
          case 'contaBloqueioJudicial': {
            tipoEmpresa = 'C/Bloqueio';
            break;
          }
          case 'contaSalarioDigital': {
            tipoEmpresa = 'C/Salario';
            break;
          }
          case 'contaServicoDigital': {
            tipoEmpresa = 'C/Serviço';
            break;
          }
          case 'contaInvestidorDigital': {
            tipoEmpresa = 'C/Investidor';
            break;
          }
          case 'contaDigital': {
            tipoEmpresa = 'C/Digital';
            break;
          }
        }

        const contaEmpresa = empresa.empresa.numeroConta.substring(0, empresa.empresa.numeroConta.length - 1) + '-' + empresa.empresa.numeroConta.substring(empresa.empresa.numeroConta.length - 1, empresa.empresa.numeroConta.length);
        return (
          (empresa.empresa.pessoa.razaoSocial || empresa.empresa.pessoa.nome) + " - " + empresa.empresa.pessoa.documento +
          ' Bco ' + compEmpresa + ' - Ag: ' + agencia + ' - ' + tipoEmpresa + ': ' + contaEmpresa
        );
      } else {
        if (empresa.empresa.pessoa.razaoSocial) {
          return empresa.empresa.pessoa.razaoSocial + ' - ' + empresa.empresa.pessoa.documento
        } else {
          return empresa.empresa.pessoa.nome + ' - ' + empresa.empresa.pessoa.documento
        }
      }
    }
  }

  getTipo(empresa: any) {
    if (empresa.tipoConta != null) {
      switch (empresa.tipoConta) {
        case `contaDigitalS`: { 
          return `Conta Digital S`;
        }
        case `contaCobrancaDigital`: {
          return `C/ContaCobrancaDigital`;
        }
        case `contaCorrenteDigital`: {
          return `C/Corrente`;
        }
        case `contaVinculadaDigital`: {
          return `C/Vinculada`;
        }
        case `contaEscrowDigital`: {
          return `C/Escrow`;
        }
        case `contaBloqueioJudicial`: {
          return `C/Bloqueio`;
        }
        case `contaSalarioDigital`: {
          return `C/Salario`;
        }
        case `contaServicoDigital`: {
          return `C/Serviço`;
        }
        case `contaInvestidorDigital`: {
          return `C/Investidor`;
        }
        case `contaDigital`: {
          return `C/Digital`;
        }
      }
    }
  }

  displayCorp(corporacao): any {
    if (corporacao.nome) {
      this.corporacaoNome = corporacao.nome;
      return this.corporacaoNome;
    } else if (!isNaN(+corporacao)) {
      return this.corporacaoNome
    } else if (corporacao) {
      this.corporacaoNome = corporacao;
      return this.corporacaoNome;
    }
  }

  atualizarCorporacao(corporacao) {
    this.corporacaoNome = corporacao.nome;
    this.formAgencia.controls.corporacao.setValue(corporacao.id)
  }

  atualizarEmpresa(empresa) {
    this.form.get("empresa").valueChanges.subscribe(()=>{
      this.filtroEmpresa = this._filterEmpresa(empresa)
    });

    this.empresaNome = empresa;
    this.formAgencia.controls.correntista.setValue(empresa.id)
  }

  public _filterCorporacao(searchValue: string): any[] {
    if(!isString(searchValue)) return;
    return this.corporacaoList.filter((corporacao) =>
      corporacao.nome.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  exportarDownload() {
    let data: any = {};
    let request;
    switch (this.tipoExtrato) {
      case '1': //Extrato Personalizável
        this.formAgencia.controls.de.setValue(moment(this.formAgencia.get("de").value).format('DDMMYYYY'))
        this.formAgencia.controls.ate.setValue(moment(this.formAgencia.get("ate").value).format('DDMMYYYY'))
        data = this.formAgencia.value
        if (this.ehColaborador) data.emailColaborador = this.usuarioLogado.email
        request = this.exportarPlanilha.exportarPlanilha(data);
        break;
      case '2': //Extrato entre e dentro da agência
        this.formInterno.controls.de.setValue(moment(this.formInterno.get("de").value).format('DDMMYYYY'))
        this.formInterno.controls.ate.setValue(moment(this.formInterno.get("ate").value).format('DDMMYYYY'))
        data = this.formInterno.value;
        if (this.ehColaborador) data.emailColaborador = this.usuarioLogado.email
        request = this.exportarPlanilha.exportarPlanilha(data);
        break;
      case '3': //Extrato Integralização
        this.formIntegral.controls.de.setValue(moment(this.formIntegral.get("de").value).format('DDMMYYYY'))
        this.formIntegral.controls.ate.setValue(moment(this.formIntegral.get("ate").value).format('DDMMYYYY'))
        data = this.formIntegral.value
        if (this.ehColaborador) data.emailColaborador = this.usuarioLogado.email
        request = this.exportarPlanilha.exportarPlanilha(data);
        break;
      case '4': //Extrato diário por correntista
        this.formRelatorio.controls.de.setValue(moment(this.formRelatorio.get("de").value).format('DDMMYYYY'))
        data = this.formRelatorio.value
        request = this.exportarPlanilha.exportarRelatorio(data, "documento", this.ehColaborador ? this.usuarioLogado.email : null)
        break;
      case '5': //Movimentações por conta
        this.formPlanilhas.controls.de.setValue(moment(this.formPlanilhas.get("de").value).format('DDMMYYYY'))
        this.formPlanilhas.controls.ate.setValue(moment(this.formPlanilhas.get("ate").value).format('DDMMYYYY'))
        data = this.formPlanilhas.value
        data.email = this.usuarioLogado.email
        request = this.exportarPlanilha.exportarMovimentacoesPorConta(data);
        break;
      case '6': //Relatório Simplificado
        data = this.formRelatorioSimplificado.get('agencia').value
        request = this.exportarPlanilha.relatorioSimplificado(data, this.ehColaborador ? this.usuarioLogado.email : null);
        break;
      case '7': //Layout Contábil
        this.formContabil.controls.de.setValue(moment(this.formContabil.get("de").value).format('DDMMYYYY'))
        this.formContabil.controls.ate.setValue(moment(this.formContabil.get("ate").value).format('DDMMYYYY'))
        data = this.formContabil.value
        if (this.ehColaborador) data.emailColaborador = this.usuarioLogado.email
        request = this.exportarPlanilha.extratoLayoutContabil(data);
        break;
      case '8': //Extrato tarifas
        this.formTarifas.controls.de.setValue(moment(this.formTarifas.get("de").value).format('DDMMYYYY'))
        this.formTarifas.controls.ate.setValue(moment(this.formTarifas.get("ate").value).format('DDMMYYYY'))
        data = this.formTarifas.value
        if (this.ehColaborador) data.emailColaborador = this.usuarioLogado.email
        request = this.exportarPlanilha.extratoTarifas(data);
        break;
    }

    request.pipe(untilDestroyed(this)).subscribe(
      (response) => {
        Swal.fire('Sucesso', response, 'success').then(() => this.fecha());
      }, error => {
        Swal.fire('Erro', error.error, 'error').then(() => this.fecha());
        console.error(error);
      }
    );
  }

  fecha(): void {
    this.dialogRef.close();
  }

  tudo() {
    this.formAgencia.controls.antecipacoes.setValue(this.formAgencia.controls.tudo.value)
    this.formAgencia.controls.boletos.setValue(this.formAgencia.controls.tudo.value)
    this.formAgencia.controls.cartao.setValue(this.formAgencia.controls.tudo.value)
    this.formAgencia.controls.teds.setValue(this.formAgencia.controls.tudo.value)
    this.formAgencia.controls.manutencao.setValue(this.formAgencia.controls.tudo.value)
    this.formAgencia.controls.pagamentos.setValue(this.formAgencia.controls.tudo.value)
  }

  calculaNroConta(empresa: any) {
    if (empresa.tipoConta != null) {
      var contaInst = empresa.numeroConta.substring(0, empresa.numeroConta.length - 1) + '-' + empresa.numeroConta.substring(empresa.numeroConta.length - 1, empresa.numeroConta.length);
      return contaInst;
    }
  }

  exibirTooltip(empresa: any) {
    if (empresa.tipoConta != null) {
      let bloqueada: string = empresa.moderacaoEmpresa == "bloqueada" ? " BLOQUEADA" : "";
      switch (empresa.tipoConta) {
        case `contaDigitalS`: { 
          return 'Conta Digital S - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaCobrancaDigital`: {
          return 'Conta Cobrança Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaCorrenteDigital`: {
          return 'Conta Corrente Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaVinculadaDigital`: {
          return 'Conta Vinculada Digital- ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaEscrowDigital`: {
          return 'Conta Escrow Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaBloqueioJudicial`: {
          return 'Conta Bloqueio Judicial - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaSalarioDigital`: {
          return 'Conta Salario Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaServicoDigital`: {
          return 'Conta Servico Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaInvestidorDigital`: {
          return 'Conta Investidor Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case `contaDigital`: {
          return 'Conta Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
      }
    }
  }

  isNotPreenchido() {
    if (this.tipoExtrato == null) {
      return true;
    }
    switch(this.tipoExtrato){
      case '1':
        if ((this.formAgencia.controls.tudo.value == false
          && this.formAgencia.controls.antecipacoes.value == false
          && this.formAgencia.controls.boletos.value == false
          && this.formAgencia.controls.cartao.value == false
          && this.formAgencia.controls.teds.value == false
          && this.formAgencia.controls.manutencao.value == false
          && this.formAgencia.controls.pagamentos.value == false)
          || this.formAgencia.controls.de.value == ''
          || this.formAgencia.controls.ate.value == ''
          || this.formAgencia.invalid) {
          return true;
        }
        break;
      case '2':
        if (this.formInterno.controls.de.value == ''
          || this.formInterno.controls.ate.value == ''
          || this.formInterno.invalid) {
          return true;
        }
        break;
      case '3':
        if (this.formIntegral.controls.de.value == ''
          || this.formIntegral.controls.ate.value == ''
          || this.formIntegral.invalid) {
          return true;
        }
        break;
      case '4':
        if (this.formRelatorio.controls.de.value == ''
          || this.formRelatorio.controls.banco.value == ''
          || this.formRelatorio.invalid) {
          return true;
        }
        break;
      case '5':
        if (this.formPlanilhas.controls.de.value == ''
          || this.formPlanilhas.controls.ate.value == ''
          || this.formPlanilhas.invalid) {
          return true;
        }
        break;
      case '7':
        if (this.formContabil.controls.de.value == ''
          || this.formContabil.controls.ate.value == ''
          || this.formContabil.invalid) {
          return true;
        }
        break;
      case '8':
        if (this.formTarifas.controls.de.value == ''
          || this.formTarifas.controls.ate.value == ''
          || this.formTarifas.invalid) {
          return true;
        }
        break;
    }
  }

  async getAgencias(){
    this.agencias = await this.empresaService.listarAgencias().toPromise();
  }

  validacaoDatas(form: FormGroup){
    return (control: AbstractControl): ValidationErrors | null => {
      const de = form.get('de')?.value, ate = form.get('ate')?.value
      if(de && ate){
        if(moment(de).isAfter(moment(ate)))
          return { dataInvalida: true }
      }
      return null
    }
  }
}