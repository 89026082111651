import { NgModule } from "@angular/core";
import { MaterialModule } from "../../material.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TokenComponent } from "./token.component";
import { IconsModule } from "../../icons.module";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
    declarations: [TokenComponent],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      IconsModule,
      MaterialModule,
      NgxMaskModule
    ],
    exports: [
      TokenComponent
    ]
  })
  export class TokenModule { }