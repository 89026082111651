import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PagamentoLoteSistemaEditarComponent } from '../pagamento-lote-sistema-editar.component';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { Environment, environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-pagamento-lote-sistema-resumo',
  templateUrl: './pagamento-lote-sistema-resumo.component.html',
  styleUrls: ['./pagamento-lote-sistema-resumo.component.less']
})
export class PagamentoLoteSistemaResumoComponent implements OnInit, OnDestroy {
  public env: Environment = environment;
  urlAjuda = null;
  @Input()
  pagamentoLoteEditarComponent: PagamentoLoteSistemaEditarComponent

  constructor(
    private rootService: RootEndpointService,
  ) { }

  ngOnInit() {
    this.getAjuda();
  }

  get saldoAtual() {
    if (this.pagamentoLoteEditarComponent) {
      return this.pagamentoLoteEditarComponent.saldoAtual || 0;
    }
    return 0;
  }

  getAjuda() {
    this.rootService.getAjudas().pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response[4] != '') this.urlAjuda = response[3];
          // Antecipacoes 0
          // Cobrancas 1
          // Contatos 2
          // Transferencias 3
          // Pagamentos 4

        })
  }
  
  get pagamentoLoteEditarUploadComponent() {
    if (this.pagamentoLoteEditarComponent) {
      return this.pagamentoLoteEditarComponent.pagamentoLoteEditarUploadComponent;
    }
  }

  get dadosValidacao() {
    if (this.pagamentoLoteEditarUploadComponent) {
      return this.pagamentoLoteEditarUploadComponent.dadosValidacao;
    }
  }

  get itens() {
    if (this.dadosValidacao) {
      return this.dadosValidacao.itens;
    }
  }

  get valorTotalLote() {
    if (this.itens && this.itens.length > 0) {
      let valor = 0;
      for(let item of this.itens){
        if(item.dadosConsultaBoleto){
          valor += item.dadosConsultaBoleto.valor_pagamento
        }
        if(item.dadosConsultaArrecadacaoItau){
          valor += item.dadosConsultaArrecadacaoItau.valorArrecadacao
        }
      }
      return valor;
    }
    return 0;
  }

  get linhasTotais() {
    if (this.itens) {
      return this.itens.length;
    }
    return 0;
  }

  get linhasErro() {
    if (this.itens) {
      return this.itens.filter(m => !m.status).length;
    }
    return 0;
  }

  get linhasSucesso() {
    return this.linhasTotais - this.linhasErro;
  }

  ngOnDestroy() { }
}
