<div style="display: flex;
justify-content: center;">
  <label for="addfile" class="btn btn-default next" id="color-btn">
    Escolher arquivo
    <input type="file" (change)="fileChangeEvent($event)" id="addfile" style="display: none;" />
  </label>
</div>
<div>
  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" format="png"
    [aspectRatio]="1168/180" (imageCropped)="imageCropped($event)"></image-cropper>
</div>