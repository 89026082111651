<div class="container card-plano">
    <div class="col-12 col-md-12">
        <div id="titulo">
            <span style="display: flex;
            align-items: center;"> <i class="fa-brands fa-pix" style="font-size: 40px;"></i>
                <h3 style="margin-left: 10px;"><strong>MINHAS CHAVES PIX</strong></h3>
            </span>
            <p style="margin-left: 52px;">Cadastre, edite e exclua suas chaves</p>
        </div>
    </div>


    <div id="chaves-ativas">
        <span class="title-chaves">
            Chave(s) ativas(s):
        </span>
        <div class="col-12">
            <mat-progress-bar [hidden]="!processando" mode="indeterminate"></mat-progress-bar>
        </div>
        <div id="ativas-container">
            <div class="ativas" *ngIf="listaChaves?.length > 0" [hidden]="processando">
                <span style="display: flex;" *ngFor="let item of listaChaves">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ transformTipoPix(item.tpChave) }}</mat-label>
                        <input matInput type="text" readonly [value]="item.chaveFormatada" />
                    </mat-form-field>
                    <button matTooltip="Copiar chave"
                        style="border: 0; background-color: transparent;margin-bottom: 25px;">
                        <i class="far fa-copy" (click)="copiarChave(item.chave)" style="color: #707070;"></i>
                    </button>
                    <button matTooltip="Apagar chave" (click)="excluirChave(item.chave, item.tpChave)"
                        style="border: 0; background-color: transparent;margin-bottom: 25px;">
                        <i class="fas fa-trash" style="color: #707070;"></i>
                    </button>
                </span>
            </div>
            <div class="ativas" *ngIf="listaChaves?.length === 0">
                <span style="display: flex;" id="titulo">
                    <p>Não identificamos nenhuma chave cadastrada.</p>
                </span>
            </div>
        </div>
    </div>

    <hr style="width: 650px; color: #D7D7D7;">

    <form [formGroup]="form">
        <div id="cadastrar-chaves">
            <span class="title-chaves">Cadastrar chave(s):</span>

            <div class="chaves" *ngIf="!temCelular">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>Celular</mat-label>
                    <input matInput type="text" mask="(00) 0 0000-0000" class="form-disabled"
                        formControlName="celular" />
                    <mat-error>{{ getErrorMessage("celular") }}</mat-error>

                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="cadastrarPix(celular)" [disabled]="form.get('celular').value?.length < 11">
                    CADASTRAR CHAVE
                </button>
            </div>

            <div class="chaves" *ngIf="!temCpfCnpj">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>CPF/CNPJ</mat-label>
                    <input matInput type="text" mask="CPF_CNPJ" formControlName="cpfCnpj" [value]="documento"
                        readonly />
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="cadastrarPix(cpfCnpj)" [disabled]="form.get('cpfCnpj').value?.length < 11">
                    CADASTRAR CHAVE
                </button>
            </div>

            <div class="chaves" *ngIf="!temEmail">
                <mat-form-field appearance="outline" style="margin-right: 30px;">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="text" class="form-disabled" formControlName="email" />
                    <mat-error>{{ getErrorMessage("email") }}</mat-error>
                </mat-form-field>

                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="cadastrarPix(email)"
                    [disabled]="!form.get('email').value || !form.get('email').value?.includes('@') || form.get('email').hasError('pattern')">
                    CADASTRAR CHAVE
                </button>
            </div>

            <div class="chaves" style="justify-content: center;">
                <button class="btn btn-block btn-primario fundoCorPrimaria"
                    style="border-color: #ee9900; height: 50%; width: 50%; margin-top: 10px;" mat-raised-button
                    (click)="cadastrarChaveAleatoria()" [disabled]="processando">
                    GERAR CHAVE ALEATÓRIA
                </button>
            </div>
        </div>
    </form>

</div>