import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TransferenciaLoteDTO } from '../../model/financeiro/transferencia/transferencia-lote-dto.model';
import { AutenticacaoEndpointService } from '../../service/autenticacao-endpoint.service';
import { TemporizadorService } from '../../service/temporizador.service';
import { UsuarioEndpointService } from '../../service/usuario-endpoint.service';
import { CheckAuthoritiesService } from '../../service/util/checkAuthorities.service';
import { UtilCaniveteService } from '../../service/util/util-canivete.service';
import { EstruturaToken, TipoToken } from '../../model/enum.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.less']
})
export class TokenComponent implements OnInit, OnDestroy {
  dadosValidacao: TransferenciaLoteDTO;
  empresaLogada;
  usuarioLogado;
  env = environment;

  public usuarioOperador: boolean;
  public usuarioColaborador: boolean;
  public diaUtil: boolean = true;
  public operadorAgendar: boolean;
  public operadorCriar: boolean;
  public authToken: boolean = false;
  public telefoneUsuario: any;
  public emailUsuario: any;
  public numeroTelefone: string = "";
  public emailEnvio: string = "";
  public subscription: Subscription;
  public podeReenviar: boolean = true;
  authOperadorCriarTransferencia = false;

  public bloqueiaEnviarAte: Date;
  private EnviadoEm: Date;
  public tempoReenvio: number = 2 //minutos

  @Input()
  public form: FormGroup = new FormGroup({});

  @Input()
  public estrutura: EstruturaToken;

  @Input()
  public agendamento: boolean;

  @Input()
  public celular: string = null;

  constructor(
    private usuarioEndpointService: UsuarioEndpointService,
    public utilCaniveteService: UtilCaniveteService,
    private temporizadorService: TemporizadorService,
    private autenticacao: AutenticacaoEndpointService
  ) {
    this.empresaLogada = this.autenticacao.regraEmpresa.empresa;
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;
    const checkAuthorities = new CheckAuthoritiesService(this.autenticacao.sessao);
    this.usuarioColaborador = checkAuthorities.isColaborador;
    this.usuarioOperador = checkAuthorities.isOperador;
    this.authToken = checkAuthorities.permissaoToken();
    this.authOperadorCriarTransferencia = checkAuthorities.permissaoGuard('ROLE_EMPRESA_TRANSFERENCIAS_CRIAR', this.empresaLogada.id);
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.form.addControl('token', new FormControl({ value: '', disabled: true }, Validators.required));

    if (this.usuarioColaborador) {
      this.form.addControl('isSMS', new FormControl(false));
      this.form.addControl('isEmail', new FormControl(true));
      this.form.addControl('isMfa', new FormControl(false));
    } else {
      this.form.addControl('isSMS', new FormControl(true));
      this.form.addControl('isEmail', new FormControl(false));
      this.form.addControl('isMfa', new FormControl(false));
    }

    const userData = this.autenticacao.sessao;
    this.telefoneUsuario = userData.principal.usuario.celular;
    this.emailUsuario = userData.principal.usuario.email;

    if (this.authToken) {
      if(this.usuarioOperador){
        this.getNumeroEmail([this.telefoneUsuario], [{email: this.emailUsuario}]);
      }else{
        this.getNumeroEmail(this.empresaLogada.configuracao.telefoneSeguranca, [{email: this.emailUsuario}]);
      }
    } else if(this.usuarioOperador && !this.authOperadorCriarTransferencia) {
      this.getNumeroEmail([this.telefoneUsuario], [{email: this.emailUsuario}]);
    } else {
      this.getNumeroEmail(this.empresaLogada.configuracao.telefoneSeguranca, this.empresaLogada.configuracao.emailNotificacao);
    }

    if (this.celular != null) {
      const telefone = {
        ddd: this.celular.toString().substring(0, 2),
        numero: this.celular.toString().substring(2)
      };
      this.numeroTelefone = '(' + telefone.ddd + ')' + ' XXXXX-XX' + telefone.numero.substring((telefone.numero.length - 2), telefone.numero.length);
    }

    if(!this.usuarioLogado.multifatorAtivado || !this.usuarioLogado.multifatorValidado){
      this.form.get("isMfa").disable()
    }

    if (['transferenciaLoteSistema', 'pagamentoLoteSistema'].includes(this.estrutura)) {
      this.form.get("isSMS").disable();
      this.form.get("isEmail").disable();
      this.form.get("isMfa").disable();
      this.form.get("isEmail").setValue(false);
      this.form.get("isSMS").setValue(false);
      this.form.get("isMfa").setValue(true);
      this.form.get('token').enable();
    }
  }

  isValid(): boolean {
    if (this.form.get("token").disabled) return false;
    return this.form.valid;
  }

  parse(model: TransferenciaLoteDTO): TransferenciaLoteDTO {
    return model;
  }

  getForm(): FormGroup {
    return this.form;
  }

  getToken() {
    return this.form.get('token').value;
  }

  public getNumeroEmail(numeros, emails) {
    let hasNumber: boolean = true, hasEmail: boolean = true;

    //Desabilita opção caso não tenha número ou email;
    if ((numeros.length <= 0 || numeros[0].numero == null) && (emails.length <= 0 || emails[0].email == '' || emails[0].email == null)) {
      Swal.fire(
        'Cadastro incompleto',
        'Para realizar as operações, por favor verifique o telefone ou email cadastrado em Empresa-> Configurações -> Autenticação',
        'warning'
      );
      return;
    }

    if (numeros.length <= 0 || numeros[0].numero == null) {
      this.form.get("isSMS").disable();
      this.form.get("isSMS").setValue(false);
      this.form.get("isEmail").setValue(true);
      hasNumber = false;
    }

    if ((emails.length == 0 || emails[0].email == '' || emails[0].email == null)) {
      this.form.get("isEmail").disable();
      this.form.get("isEmail").setValue(false);
      this.form.get("isSMS").setValue(true);
      hasEmail = false;
    }

    //se tem número
    if (hasNumber) {
      const numero = String(numeros[0].numero);
      this.numeroTelefone = '(' + numeros[0].ddd + ')' + ' XXXXX-XX' + numero.substring((numero.length - 2), numero.length);
    }

    //se tem email
    if (hasEmail) {
      const emailText: string[] = emails[0].email.split("@", 2)
      this.emailEnvio = emailText[0].substring(0, 3) + 'XXXXX@' + emailText[1];
    }

    if (hasEmail && !hasNumber) {
      this.form.get("isEmail").disable();
      this.form.get("isSMS").disable();
    }

    if (hasNumber && !hasEmail) {
      this.form.get("isEmail").disable();
      this.form.get("isSMS").disable();
    }
  }

  changeSlide(event: MatSlideToggleChange, slide: string) {
    if (slide == "sms") {
      this.form.get("isEmail").setValue(!event.checked);
      this.form.get("isMfa").setValue(!event.checked);
    } else if(slide == "email"){
      this.form.get("isSMS").setValue(!event.checked);
      this.form.get("isMfa").setValue(!event.checked);
    } else {
      this.form.get("isEmail").setValue(!event.checked);
      this.form.get("isSMS").setValue(!event.checked);
      this.form.get('token').enable();
    }
    
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null;
    }
    this.podeReenviar = true;
  }

  novoCodigo() {
    let tipo: TipoToken = 'sms';
    if (!this.form.get('isSMS').value) {
      tipo = 'email'
    }
    if (this.agendamento && this.estrutura === 'transferencia') {
      this.estrutura = 'agendarTransferencia'
    }
    if ((tipo === 'sms' && this.env.modulos.token.sms) || (tipo === 'email' && this.env.modulos.token.email)) {
      this.usuarioEndpointService.solicitarToken(this.estrutura, tipo, this.celular).pipe(untilDestroyed(this))
        .subscribe((response) => {
          this.form.get('token').enable();
          this.EnviadoEm = this.temporizadorService.setConfirmacaoEnviadoEm();
          this.bloqueiaEnviarAte = this.temporizadorService.setBloqueiaEnviarAte(this.bloqueiaEnviarAte, this.EnviadoEm, this.tempoReenvio, 'EnviadoEm');
          this.temporizadorService.setIntervaloNovoCodigo(this.bloqueiaEnviarAte, 'EnviadoEm');

          this.subscription = this.temporizadorService.podeReenviarSms.pipe(untilDestroyed(this))
            .subscribe((response) => {
              this.podeReenviar = response;
            }, error => {
              console.error(error);
            }
            );
        }, error => {
          Swal.fire('Atenção', error.error, 'warning');
        });
    } else {
      Swal.fire('Atenção', 'Não possui permissão para solicitar token por meio de ' + tipo, 'warning');
    }

  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido'
          : '';
    }
  }

}
