import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltroCobrancaDTO } from '@model/filtro/filtro-cobranca-dto.model';
import { ListaCobrancaDTO } from '@model/lista-cobranca-dto.model';
import { ListaMensagensDTO } from '@model/lista-mensagens-dto.model';
import { PageResponse } from '@model/page-response.model';
import moment from 'moment';
import { Observable } from 'rxjs';
import { CarteiraDTO } from '../model/cobranca/carteira-dto.model';
import { FiltroService } from './filtro.service';

@Injectable({
  providedIn: 'root',
})
export class CobrancaEndpointService {
  constructor(
    private client: HttpClient,
    private filtroService: FiltroService
  ) {}

  emitirCobranca(cobrancaData): Observable<any> {
    const url = '/cobranca/emitir';
    return this.client.post(url, cobrancaData);
  }

  reportarProblema(cobrancaData): Observable<any> {
    const url = '/cobranca/reportar-problema';
    return this.client.post(url, cobrancaData);
  }

  editarCobranca(cobrancaData): Observable<any> {
    const url = '/cobranca/atualizar';
    return this.client.post(url, cobrancaData);
  }

  editarCobrancaLote(cobrancaData): Observable<any> {
    const url = '/cobranca/atualizar-lote';
    return this.client.patch(url, cobrancaData);
  }

  // /cobranca/apagar/{idCobranca}?notificarCliente=true/false
  excluirCobranca(
    cobrancaId,
    notificarCliente: boolean,
    dms: boolean
  ): Observable<any> {
    const url =
      '/cobranca/apagar/' +
      cobrancaId +
      '?notificarCliente=' +
      notificarCliente +
      '&dms=' +
      dms; // colocar mais um parametro aqui
    return this.client.delete(url);
  }

  excluirLote(cobrancasIds) {
    const url = '/cobranca/excluir-lote';
    return this.client.post(url, cobrancasIds);
  }

  getMensagensByIdCobranca(arg?: {
    id?: number;
    order?: string;
    page?: number;
    q?: string;
    size?: number;
  }): Observable<PageResponse<ListaMensagensDTO>> {
    arg = arg || {};
    let params = new HttpParams();

    params = params.append('id', `${arg.id}`);
    params = params.append('page', `${arg.page}`);

    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    if (arg.q) {
      params = params.append('q', `${arg.q}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }
    const url = '/cobranca/mensagens-enviadas';
    return this.client.get(url, { params });
  }

  getCobrancaById(idCobranca): Observable<any> {
    const url = '/cobranca/informacoes/' + idCobranca;
    return this.client.get(url);
  }

  getLastroById(idCobranca, page, size): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    if (size) {
      params = params.append('size', `${size}`);
    }
    const url = `/cobranca/lastros/${idCobranca}`;
    return this.client.get(url, { params });
  }

  getExportar(filtro): Observable<any> {
    let params = this.filtroService.gerarParametrosCobranca(filtro);
    params = params.append('formato', `${filtro.formato}`);

    return this.client.get('/cobranca/exportar', { params });
  }

  getAllCobrancasAvulsas(): Observable<any> {
    const url = '/cobranca/lista-avulsas';
    return this.client.get(url);
  }

  getAllCobrancasAssinaturas(): Observable<any> {
    const url = '/cobranca/lista-assinaturas';
    return this.client.get(url);
  }

  getAllCobrancasParceladas(): Observable<any> {
    const url = '/cobranca/lista-parceladas';
    return this.client.get(url);
  }

  getAllCobrancas(filtro?: FiltroCobrancaDTO): Observable<PageResponse<any>> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosCobranca(filtro);

    const url = '/cobranca/lista';
    return this.client.get(url, { params });
  }

  getAllCobrancasArquivadas(): Observable<any> {
    const url = '/cobranca/lista-arquivadas';
    return this.client.get(url);
  }

  filtroCobrancas(data): Observable<any> {
    const url = '/cobranca/filtrar';
    return this.client.post(url, data);
  }

  visualizarCobranca(cobrancaId, empresaId): Observable<any> {
    let params = new HttpParams();
    if (empresaId) {
      params = params.append('empresaId', `${empresaId}`);
    }
    const url = '/cobranca/' + cobrancaId;
    return this.client.get(url, { params });
  }

  visualizarComprovante(cobrancaId): Observable<any> {
    const url = '/cobranca/ver-comprovante/' + cobrancaId;
    return this.client.get(url);
  }

  consultarTitulo(cobrancaId): Observable<any> {
    const url = '/cobranca/consultar-titulo?id=' + cobrancaId;
    return this.client.get(url);
  }

  visualizarFatura(cobrancaId): Observable<any> {
    const url = '/cobranca/ver-fatura/' + cobrancaId;
    return this.client.get(url);
  }

  // /cobranca/notificar-fatura/{idPublico}
  notificarFatura(idPublico): Observable<any> {
    const url = '/cobranca/notificar-fatura/' + idPublico;
    return this.client.get(url);
  }

  baixarBoleto(cobrancaId): Observable<any> {
    return this.client.get('/cobranca/baixar-boleto', {
      params: new HttpParams().append('idCobranca', cobrancaId),
    });
  }

  baixarCarne(seuNumero): Observable<any> {
    const url = '/cobranca/gerar-carne?seuNumero=' + seuNumero;
    return this.client.get(url);
  }

  reenviarSmsEmail(cobrancaId): Observable<any> {
    const url = '/cobranca/reenviar-email-sms?id=' + cobrancaId;
    return this.client.get(url);
  }

  liquidarCobrancaManualmente(liquidarData): Observable<any> {
    const url = '/cobranca/liquidar-cobranca-manualmente';
    return this.client.post(url, liquidarData);
  }

  consultarProtestoCompleto(cobrancaId): Observable<any> {
    const url = '/protesto/consultar-protesto-completo/' + cobrancaId;
    return this.client.get(url);
  }

  consultarProtestoCompletoPelaLinhaDigitavel(linhaDigitavel): Observable<any> {
    const url =
      '/protesto/consultar-protesto-completo-linha-digitavel/' + linhaDigitavel;
    return this.client.get(url);
  }

  baixarProtesto(cobrancaId): Observable<any> {
    const url = '/protesto/download-informacoes-protesto/' + cobrancaId;
    return this.client.get(url);
  }

  protestarCobranca(protestarData): Observable<any> {
    const url = '/protesto/emitir';
    return this.client.post(url, protestarData);
  }

  operacaoProtesto(operacao, protestoData) {
    if (operacao === 'remover') {
      return this.removerProtesto(protestoData);
    } else if (operacao === 'desistir') {
      return this.desistirProtesto(protestoData);
    } else if (operacao === 'cancelar') {
      return this.cancelarProtesto(protestoData);
    }
  }

  removerProtesto(protestoData): Observable<any> {
    const url = '/protesto/remover';
    return this.client.put(url, protestoData);
  }

  desistirProtesto(protestoData): Observable<any> {
    const url = '/protesto/desistir';
    return this.client.put(url, protestoData);
  }

  cancelarProtesto(protestoData): Observable<any> {
    const url = '/protesto/cancelar';
    return this.client.put(url, protestoData);
  }

  addNovasParcelas(cobrancaId, qtdParcelas, novaData?): Observable<any> {
    const url = '/cobranca/adicionar-novas-parcelas';
    let params = new HttpParams();

    params = params.append('id', `${cobrancaId}`);
    params = params.append('num', `${qtdParcelas}`);

    if (novaData) {
      params = params.append(
        'novaData',
        `${moment(novaData).format('DDMMYYYY')}`
      );
    }

    return this.client.get(url, { params });
  }

  buscarDataBaixa(
    dataVencimento: Date,
    quantidadeDiasBaixa: number
  ): Observable<any> {
    let params = new HttpParams();
    if (dataVencimento) {
      params = params.append(
        'dataVencimento',
        `${moment(dataVencimento).format('DDMMYYYY')}`
      );
    }
    if (quantidadeDiasBaixa) {
      params = params.append('quantidadeDiasBaixa', `${quantidadeDiasBaixa}`);
    }
    const url = '/cobranca/busca-data-baixa';
    return this.client.get(url, { params });
  }

  listar(
    filtro: FiltroCobrancaDTO
  ): Observable<PageResponse<ListaCobrancaDTO>> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosCobranca(filtro);

    return this.client.get('/cobranca/listar', { params });
  }

  calcucalculaValorSomaTotal(filtro: FiltroCobrancaDTO): any {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosCobranca(filtro);

    return this.client.get('/cobranca/calcular-soma-total', { params });
  }

  consultarBoletoCip(cobrancaId): Observable<any> {
    const url = `/cobranca/consultar-boleto-cip/${cobrancaId}`;
    return this.client.get(url);
  }

  listarPorEmpresa(
    filtro: FiltroCobrancaDTO
  ): Observable<PageResponse<ListaCobrancaDTO>> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosCobranca(filtro);

    return this.client.get('/cobranca/listar-por-empresa', { params });
  }

  contarCobrancas(empresaId): Observable<any> {
    const url = `/cobranca/contar-cobrancas-aguardando/${empresaId}`;
    return this.client.get(url);
  }

  contatoPossuiCobrancas(contatoId): Observable<any> {
    const url = `/cobranca/contato-possui-cobrancas?contatoId=${contatoId}`;
    return this.client.get(url);
  }

  darBaixaTodasCobranca(idEmpresa): Observable<any> {
    const url = `/cobranca/baixa-todas-cobrancas?idEmpresa=${idEmpresa}`;
    return this.client.post(url, { idEmpresa });
  }

  exportarBoletos(cobrancasId) {
    const url = '/cobranca/exportar-boletos';
    let params = new HttpParams();
    params = params.append('cobrancasId', cobrancasId);
    return this.client.get<string>(url, { params });
  }

  consultarIntervaloCarteira(
    documentoBeneficiario,
    agencia,
    conta
  ): Observable<any> {
    const url = '/carteira/consultar-intervalo-nosso-numero';
    let params = new HttpParams();
    params = params.append('documentoBeneficiario', documentoBeneficiario);
    params = params.append('agencia', agencia);
    params = params.append('conta', conta);
    return this.client.get<CarteiraDTO[]>(url, { params });
  }

  gerarComprovantePdf(cobrancaId: number): Observable<string> {
    const url = `/cobranca/gerar-comprovante-pdf/${cobrancaId}`;
    return this.client.get<string>(url);
  }

  adicionarCarteira(
    documentoBeneficiario,
    agencia,
    conta,
    tamanhoIntervalo,
    codigoCarteira,
    empresaId
  ): Observable<any> {
    const url = `/carteira/adicionar/?documentoBeneficiario=${documentoBeneficiario}&agencia=${agencia}&conta=${conta}&tamanhoIntervalo=${tamanhoIntervalo}&codigoCarteira=${codigoCarteira}&empresaId=${empresaId}`;

    return this.client.post(url, {
      documentoBeneficiario,
      agencia,
      conta,
      tamanhoIntervalo,
      codigoCarteira,
      empresaId,
    });
  }

  buscarCarteiras(empresaId) {
    const url = '/carteira/buscar-carteiras/' + empresaId;
    return this.client.get(url);
  }

  buscarCobrancasEdicaoLote(listaIds: number[]): Observable<any> {
    const url = '/cobranca/buscar-cobrancas-edicao-lote';
    return this.client.post(url, listaIds);
  }  

}
