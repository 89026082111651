import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ListaNotificacaoDTO } from '@model/lista-notificacao-dto.model';
import { PageResponse } from '@model/page-response.model';
import { NotificacaoEndpointService } from '@service/notificacao-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificacaoComponent } from '../notificacao.component';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-lista-notificacao',
  templateUrl: './lista-notificacao.component.html',
  styleUrls: ['./lista-notificacao.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListaNotificacaoComponent implements OnInit, OnDestroy, OnChanges {
  public dataSource = new MatTableDataSource<ListaNotificacaoDTO>();
  public lista: PageResponse<ListaNotificacaoDTO>;
  processando = false;
  @Input()
  lida: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private destroy = new Subject();
  notificacaoList: any = [];
  public filtro: any;

  displayedColumns: string[] = [
    'button',
    'dataHora',
    'nivel',
    'tipo',
    'mensagem',
    'visualizou',
  ];

  expandedElement: any | null;

  constructor(
    private notificacao: NotificacaoEndpointService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public dialogRef: MatDialogRef<NotificacaoComponent>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // const currentValue = changes!.filtro!.currentValue;
    // const previousValue = changes!.filtro!.previousValue;
    // if (currentValue !== previousValue && !!currentValue) {
    //   this.paginator.firstPage();
    //   this.listar();
    // }
  }

  ngAfterViewInit(): void {
    this.sort.sortChange
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.listar());
    this.paginator.page
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.listar());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  abrir(notificacao) {
    let url = notificacao.url;
    let queryParams: string;
    if (notificacao.url.includes('?')) {
      [url, queryParams] = notificacao.url.split('?');
    }

    const params = {};
    if (queryParams) {
      queryParams.split('&').forEach((param) => {
        const [key, value] = param.split('=');
        params[key] = decodeURIComponent(value);
      });
    }

    if (!notificacao.visualizou) {
      this.atualizarNotificacao(notificacao);
    }

    if (!notificacao.openInNewTab) {
      this.dialogRef.close();
      this.router.navigate([`/privado/${url}`], { queryParams: params });
    } else {
      window.open(url, '_blank');
    }
  }

  ngOnInit() {
    this.listar();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  listar() {
    this.processando = true;
    this.changeDetectorRef.detectChanges();

    const prefixSort = this.sort.direction === 'desc' ? '-' : '';
    let order: string;
    if (this.sort.active) {
      if (this.sort.active === 'valorTotal') {
        order = prefixSort + 'titulo.valorNominal';
      } else {
        order = prefixSort + this.sort.active;
      }
    }

    const lida = this.lida;
    let page = 0,
      size = 10;
    if (this.dataSource) {
      if (this.dataSource.paginator) {
        page = this.dataSource.paginator.pageIndex;
        size = this.dataSource.paginator.pageSize;
      }
    }
    this.notificacao
      .listar({
        order,
        page,
        size,
        lida,
      })
      .toPromise()
      .then((data) => {
        this.dataSource.connect().next(data.content);
        this.lista = data;
        return data;
      })
      .catch(console.error)
      .then(() => {
        this.processando = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  atualizarNotificacao(element: any) {
    this.notificacao
      .alterarNotificacaoParaVisualizado(element.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.notificacao.notificacaoVisualizadaSource.next(element.id);
          this.listar();
        },
        (error) => {
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          });
          console.error(error);
        }
      );
  }
}
