<div class="row">
  <ng-container *ngIf="!mostrarResumo">
    <div class="col-12 titulo align-self-end text-center">
      <span> Upload e Validação </span>
    </div>
  
    <div
      class="col-12 subtitulo align-self-end text-center"
      *ngIf="!dadosValidacao"
    >
      <span> Faça o upload do arquivo </span>
    </div>
  
    <ng-container *ngIf="dadosValidacao">
      <div
        class="col-12 subtitulo align-self-end text-center"
        *ngIf="dadosValidacao.status"
      >
        <span> O arquivo esta pronto para ser processado </span>
      </div>
      <div
        class="col-12 subtitulo align-self-end text-center"
        *ngIf="!dadosValidacao.status"
      >
        <span> O arquivo não esta pronto para ser processado </span>
      </div>
    </ng-container>
  
    <div class="col-12 align-self-end d-flex justify-content-center">
      <app-upload-arquivo (onUpload)="upload($event)"></app-upload-arquivo>
    </div>
  
    <ng-container *ngIf="dadosValidacao">
      <div
        class="col-12 subtitulo align-self-end text-center caixa-download"
        *ngIf="!dadosValidacao.status"
      >
        <div>
          <span>
            Para corrigir os erros encontrados no arquivo analisado, baixe o
            arquivo que consta nosso feedback para corrigí-los. Após os ajustes,
            faça o upload do mesmo arquivo nesta página e aguarde a nova validação
          </span>
        </div>
        <div>
          <button
            type="button"
            class="btn mat-primary btn-filtro"
            (click)="download()"
          >
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            Download do arquivo completo com feedbacks
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="mostrarResumo">
    <div class="col-12 titulo align-self-end text-center">
      <span> Resumo das pagamentos </span>
    </div>
    <table class="tabela-resumo">
      <tr style="border-bottom: solid 1px #f5f5f5 !important;">
        <th>Conta de origem</th>
        <th>Linha digitável</th>
        <th>Beneficiário</th>
        <th>Data de vencimento</th>
        <th>Valor</th>
        <th>Tipo</th>
      </tr>
      <tr *ngFor="let item of dadosValidacao.itens">
        <ng-container *ngIf="!item.dadosConsultaBoleto && !item.dadosConsultaArrecadacaoItau && item.status !== false">
          <td style="width: 10%">
            {{item.contaOrigem.substring(0, item.contaOrigem.length - 1)}}-{{item.contaOrigem.substring(item.contaOrigem.length - 1)}}
          </td>
          <td style="width: 10%" [matTooltip]="item.linhaDigitavel">{{item.linhaDigitavel.substring(0, 10)}}...</td>
          <td style="width: 10%"><mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner></td>
        </ng-container>

        <ng-container *ngIf="item.status === false">
          <td style="width: 10%">
            {{item.contaOrigem.substring(0, item.contaOrigem.length - 1)}}-{{item.contaOrigem.substring(item.contaOrigem.length - 1)}}
          </td>
          <td style="width: 10%" [matTooltip]="item.linhaDigitavel">{{item.linhaDigitavel.substring(0, 10)}}...</td>
          <td style="width: 60%">Erro: {{item.mensagemStatus}}</td>
        </ng-container>

        <ng-container *ngIf="item.dadosConsultaBoleto && item.status === true">
          <td style="width: 10%">
            {{item.contaOrigem.substring(0, item.contaOrigem.length - 1)}}-{{item.contaOrigem.substring(item.contaOrigem.length - 1)}}
          </td>
          <td style="width: 10%" [matTooltip]="item.linhaDigitavel">{{item.linhaDigitavel.substring(0, 10)}}...</td>
          <td style="width: 45%" [matTooltip]="item.dadosConsultaBoleto.nome_beneficiario">
            {{item.dadosConsultaBoleto.nome_beneficiario}} - {{item.dadosConsultaBoleto.documento_beneficiario | cpfCnpj}}
          </td>
          <td style="width: 10%">{{item.dadosConsultaBoleto.data_vencimento | date: 'dd/MM/yyyy'}}</td>
          <td style="width: 15%">{{item.dadosConsultaBoleto.valor_pagamento | currency: 'BRL': 'R$'}}</td>
          <td style="width: 10%">
            Boleto
            <i *ngIf="item.dataAgendamento" class="fas fa-calendar" style="color:#4FA953"></i>
          </td>
        </ng-container>

        <ng-container *ngIf="item.dadosConsultaArrecadacaoItau && item.status === true">
          <td style="width: 10%">
            {{item.contaOrigem.substring(0, item.contaOrigem.length - 1)}}-{{item.contaOrigem.substring(item.contaOrigem.length - 1)}}
          </td>
          <td style="width: 10%" [matTooltip]="item.linhaDigitavel">{{item.linhaDigitavel.substring(0, 10)}}...</td>
          <td style="width: 45%" [matTooltip]="item.dadosConsultaArrecadacaoItau.nomeEmpresa">{{item.dadosConsultaArrecadacaoItau.nomeEmpresa}}</td>
          <td style="width: 10%">{{ moment(item.dataVencimento) | date: 'dd/MM/yyyy'}}</td>
          <td style="width: 15%">{{item.valorArrecadacao | currency: 'BRL': 'R$'}}</td>
          <td style="width: 10%">
            Arrecadação
            <i *ngIf="item.dataAgendamento" class="fas fa-calendar" style="color:#4FA953"></i>
          </td>
        </ng-container>

      </tr>
    </table>
  </ng-container>
</div>
