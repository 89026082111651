<div>
  <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
    ADICIONAR PUBLICIDADE
    <i (click)="fecha()" class="material-icons btn-fechar" id="btn-close">close</i>
    <span id="fechar" (click)="fecha()">FECHAR</span>
  </h2>
  <div mat-dialog-content class="mat-dialog-content-responsive">
    <div class="mudaCor input-size row " id="modal-total">
      <h4 class="color-title">Resolução indicada: 1168x180</h4>
      <app-image-cropper (base64)="capturarBase64($event)" (nameFile)="capturarNomeFile($event)"
        (type)="capturarTypeFile($event)"></app-image-cropper>
      <div class="card-body">
        <form [formGroup]="form">
          <div class="col-24 col-md-24  ">
            <mat-form-field appearance="outline" style="width: 20vw;">
              <mat-label>Link</mat-label>
              <input matInput type="text" placeholder="Link" formControlName="href">
            </mat-form-field>
          </div>
          <div style="display: flex;
          justify-content: center;">
            <button class="btn btn-default next" id="color-btn" mat-raised-button (click)="onSelectFile()">
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>