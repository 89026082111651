import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TransferenciaLoteDTO } from '@project/src/app/model/financeiro/transferencia/transferencia-lote-dto.model';
import { TransferenciaEndpointService } from '@project/src/app/service/transferencia-endpoint.service';
import { UtilCaniveteService } from '@project/src/app/service/util/util-canivete.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { StepStructure } from '../../../../cnab/cnab-editar/cnab-editar.model';

@Component({
  selector: 'app-transferencia-lote-sistema-modelo',
  templateUrl: './transferencia-lote-sistema-modelo.component.html',
  styleUrls: ['./transferencia-lote-sistema-modelo.component.less']
})
export class TransferenciaLoteSistemaModeloComponent implements OnInit, OnDestroy, StepStructure<TransferenciaLoteDTO> {

  versao: string;
  destroy: Subject<void>;

  constructor(
    private transferenciaEndpointService: TransferenciaEndpointService,
    private utilCaniveteService: UtilCaniveteService
  ) { }

  getForm(): AbstractControl {
    return new FormGroup({});
  }

  isValid(): boolean {
    return true;
  }
  parse(model: TransferenciaLoteDTO): TransferenciaLoteDTO {
    return model;
  }

  ngOnInit() {
    this.destroy = new Subject<void>();
    this.transferenciaEndpointService.versaoModeloSistema().pipe(takeUntil(this.destroy)).subscribe(response => {
      this.versao = response;
    }, error => {
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
      console.log(error);
    });
  }

  xlsx() {
    this.transferenciaEndpointService.loteModeloSistema().toPromise().then(response => {
      this.utilCaniveteService.downloadFileXlsx(response.base64, `modelo_transferencia_lote_sistema_${this.versao}.xlsx`);
    }, error => {
      console.error(error);
      Swal.fire({
        title: 'Erro!',
        text: error.error,
        icon: 'error',
      })
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
