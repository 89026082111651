import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TransferenciaLoteDTO } from '@project/src/app/model/financeiro/transferencia/transferencia-lote-dto.model';
import { PageResponse } from '@project/src/app/model/page-response.model';
import { AutenticacaoEndpointService } from '@project/src/app/service/autenticacao-endpoint.service';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { TransferenciaEndpointService } from '@project/src/app/service/transferencia-endpoint.service';
import { ObjectUtilsService } from '@project/src/app/service/util/object-utils.service';
import { StringFormatService } from '@project/src/app/service/util/stringFormater.service';
import { Environment, environment } from '@project/src/environments/environment.default';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transferencia-lote-item-sistema-lista',
  templateUrl: './transferencia-lote-item-sistema-lista.component.html',
  styleUrls: ['./transferencia-lote-item-sistema-lista.component.less']
})

export class TransferenciaLoteItemSistemaListaComponent implements OnInit {
  displayedColumns = [
    'dataCriacao',
    'contaFavorecida',
    'descricao',
    'valor',
    'status',
  ];
  lista: PageResponse<TransferenciaLoteDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public env: Environment = environment;

  empresaLogada: any;
  usuarioLogado: any;
  processando: boolean;
  dataSource = new MatTableDataSource<any>();
  mostrarBusca: boolean = true;
  anoSelecionado: number;
  numeroMes: number;
  urlAjuda = null;
  private destroy: Subject<void>;

  constructor(
    private transferenciaEndpointService: TransferenciaEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    public objectUtilsService: ObjectUtilsService,
    private rootService: RootEndpointService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TransferenciaLoteItemSistemaListaComponent>,
    public stringFormat: StringFormatService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataDialog,
  ) { }

  ngOnInit() {
    this.getAjuda();
    this.destroy = new Subject<void>();
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa : null;
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario
    console.log('dialogref', this.dialogRef);
    console.log('dataDialog', this.dataDialog);
    this.atualizar();
  }

  getAjuda() {
    this.rootService.getAjudas().pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response[3] != '') this.urlAjuda = response[3];
          // Antecipacoes 0
          // Cobrancas 1
          // Contatos 2
          // Transferencias 3
          // Pagamentos 4

        },
        error => {
          this.processando = false;
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK'
          });
        }
      );
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.destroy)).subscribe(() => this.atualizar());
    this.paginator.page.pipe(takeUntil(this.destroy)).subscribe(() => this.atualizar());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.atualizar();
  }

  async atualizar() {
    try {
      this.processando = true;
      this.changeDetectorRef.detectChanges();

      const page = this.paginator.pageIndex;
      const size = this.paginator.pageSize;
      const loteId = this.dataDialog;
      const order = ''; 
      const query = ''; 

      const data = await this.transferenciaEndpointService
        .listarItemSistema(page, size, loteId, order, query)
        .toPromise();

      this.dataSource.connect().next(data.content);
      this.lista = data;
    } catch (error) {
      console.error({ error });
    } finally {
      this.processando = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  onChangeYear(element: number) {
    this.anoSelecionado = element;
  }

  fecha(atualizar = false) {
    this.dialogRef.close(atualizar);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  abreBusca() { }

  abreFiltro() { }
}