<p class="super-title">Informações pessoais</p>

<div class="box-body" [formGroup]="form">
  <div class="col-12" style="text-align: center;">
    <p>Preencha os dados abaixo para realizar o seu cadastro <i class="fas fa-info-circle info-icon"
        matTooltip="Atenção: Ao efetuar o login no portal será solicitada a inclusão dos documentos: Pessoa Física: CPF, RG ou CNH ou RNE e Comprovante de residência. Pessoa Jurídica: Cartão CNPJ, Contrato Social, Comprovante de endereço comercial, RG ou CNH ou RNE e CPF dos sócios."></i>
    </p>
  </div>
  <br>
  <div class="row row-inputs">
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Nome de cadastro</mat-label>
        <input matInput type="text" [value]="usuario.nome + ' ' + usuario.sobrenome" disabled />
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" [value]="usuario.email" disabled />
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Telefone</mat-label>
        <input matInput type="text" [value]="'(' + usuario.celular.ddd + ') ' + usuario.celular.numero" disabled />
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>CPF</mat-label>
        <input matInput type="text" formControlName="cpf" mask="000.000.000-00" required/>
        <mat-error>{{ getErrorMessage(form, "cpf") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-5 col-md-5">
      <mat-form-field appearance="outline" class="dataNascimento">
        <mat-label>Data de nascimento</mat-label>
        <input matInput [matDatepicker]="picker1" (keyup)="mascaraData($event,'dataNascimento')"  
          formControlName="dataNascimento" [min]='dataMinima' [max]="dataDezoitoAnos" required readonly>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error>{{ getErrorMessage(form, "dataNascimento") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 col-md-3">
      <mat-form-field appearance="outline">
        <mat-label>Sexo</mat-label>
        <mat-select formControlName="sexo" required>
          <mat-option value="F">Feminino</mat-option>
          <mat-option value="M">Masculino</mat-option>
          <mat-option value="O">Outro</mat-option>
          <mat-option value="N">Não informar</mat-option>
        </mat-select>
        <mat-error>{{ getErrorMessage(form, "sexo") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>RG</mat-label>
        <input matInput type="text" formControlName="rg" required/>
        <mat-error>{{ getErrorMessage(form, "rg") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Orgão emissor</mat-label>
        <input matInput type="text" formControlName="orgaoEmissor" required/>
        <mat-error>{{ getErrorMessage(form, "orgaoEmissor") }}</mat-error>
      </mat-form-field>
    </div>
    <!-- <div class="col-3 col-md-3">
      <mat-form-field appearance="outline">
        <mat-label>Data de emissão</mat-label>
        <input matInput [matDatepicker]="picker2" id="dataEmissao" (keyup)="mascaraData($event,'dataEmissao')" formControlName="dataEmissao">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-error>{{ getErrorMessage(form, "dataEmissao") }}</mat-error>
      </mat-form-field>
    </div> -->
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Estado civil</mat-label>
        <mat-select formControlName="estadoCivil" required>
          <mat-option value="solteiro">Solteiro</mat-option>
          <mat-option value="casado">Casado</mat-option>
          <mat-option value="viuvo">Viúvo</mat-option>
          <mat-option value="separadoJudicialmente">Separado judicialmente</mat-option>
          <mat-option value="divorciado">Divorciado</mat-option>
        </mat-select>
        <mat-error>{{ getErrorMessage(form, "estadoCivil") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline" class="pessoaPoliticamenteExposta">
        <input matInput type="text" hidden formControlName="pessoaPoliticamenteExposta" />
        <mat-slide-toggle formControlName="pessoaPoliticamenteExposta">Politicamente Exposta</mat-slide-toggle>
        <i class="fas fa-info-circle info-icon"
        matTooltip="É uma pesssoa que desempenha ou desempenhou, no Brasil ou no exterior nos últimos 5 anos, funções públicas relevantes, ou teve familiares, representantes ou colaboradores próximos que desempenham ou desempenharam esse tipo de função. Para saber mais, veja a Circular BCB n.3.978/20."></i>
        <mat-error>{{ getErrorMessage(form, "pessoaPoliticamenteExposta") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Nome da mãe</mat-label>
        <input matInput type="text" formControlName="nomeMae" required/>
        <mat-error>{{ getErrorMessage(form, "nomeMae") }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 col-md-4">
      <mat-form-field appearance="outline">
        <mat-label>Nome do pai</mat-label>
        <input matInput type="text" formControlName="nomePai" required/>
        <mat-error>{{ getErrorMessage(form, "nomePai") }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px;">
  <div class="col-12" style="padding-bottom: 20px;">
    <p style="text-align: center;">Qual o seu endereço residencial?</p>
  </div>
  <app-endereco [(form)]="endereco" [multiplosEnderecos]="false"></app-endereco>
</div>

<div class="box-body" [formGroup]="form" style="margin-top: 20px;">
  <div class="col-12" style="padding-bottom: 20px;">
    <p style="text-align: center;">Qual o propósito da abertura da conta?</p>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Propósito de abertura</mat-label>
    <textarea matInput rows="2" maxlength="100" minlength="7" formControlName="propositoAbertura"
      [required]="configuracoesOnboarding?.propositoAberturaObrigatorio || false" style="resize: none;">
    </textarea>
    <mat-error>{{ getErrorMessage(form, "propositoAbertura") }}</mat-error>
  </mat-form-field>
</div>