import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { CnabComponent } from '@components/cnab/cnab.component';
import { FalarComGerenteComponent } from '@components/falar-com-gerente/falar-com-gerente.component';
import { NotificacaoComponent } from '@components/notificacao/notificacao.component';
import { NovaNotificacaoComponent } from '@components/notificacao/nova-notificacao/nova-notificacao.component';
import { ListaNotificacaoDTO } from '@model/lista-notificacao-dto.model';
import { PageResponse } from '@model/page-response.model';
import { ArquivoDTO } from '@model/sistema/arquivo-dto.model';
import { UsuarioEditarComponent } from '@pages/interno/usuario/usuario-editar/usuario-editar.component';
import { LiquidarArquivoComponent } from '@project/src/app/pages/interno/cobranca/liquidar-arquivo/liquidar-arquivo.component';
import { ListaCobrancaService } from '@project/src/app/pages/interno/cobranca/listar-cobranca/lista-cobranca.service';
import { PessoaEditarComponent } from '@project/src/app/pages/interno/pessoa/pessoa-editar/pessoa-editar.component';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { NotificacaoEndpointService } from '@service/notificacao-endpoint.service';
import { RootEndpointService } from '@service/root-endpoint.service';
import { environment } from '@src/environments/environment.default';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, Subscription } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ROUTES } from '../sidebar/sidebar.component';
import { AdminService } from '../admin.service';
import { ToastrService } from 'ngx-toastr';
import { StringFormatService } from '@project/src/app/service/util/stringFormater.service';
import { SensibilizacaoComponent } from '@project/src/app/components/sensibilizacao/sensibilizacao.component';
import { ModulosHabilitadosComponent } from '@project/src/app/components/modulos-habilitados/modulos-habilitados.component';
import { PersonalizacaoSistemaComponent } from '@project/src/app/components/personalizacao-sistema/personalizacao-sistema.component';
import { ImportacoesComponent } from '@project/src/app/components/importacoes/importacoes.component';
import { CheckAuthoritiesService } from '@project/src/app/service/util/checkAuthorities.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AppsComponent } from '@project/src/app/components/apps/apps.component';
import { SuporteEContatoComponent } from '@project/src/app/components/suporte-e-contato/suporte-e-contato.component';
import { SuporteContatoEndpointService } from '@project/src/app/service/suporte-contato-endpoint.service';
import { PersonalizaoGeralComponent } from '@project/src/app/components/personalizao-geral/personalizao-geral.component';
import { EmailSistemaComponent } from '@project/src/app/components/emails-sistema/email-sistema.component';
import { PublicidadeComponent } from '@project/src/app/components/publicidade/publicidade.component';
import { ConfiguracaoCarrosselComponent } from '@project/src/app/components/configuracao-carrossel/configuracao-carrossel.component';
import { EmpresaEndpointService } from '@project/src/app/service/empresa-endpoint.service';
import { InformacoesFinanceirasDTO } from '@project/src/app/model/informacoes-financeiras-dto.model';
import { EmprestimoEndpointService } from '@project/src/app/service/emprestimo-endpoint.service';
import { DocumentacaoComponent } from '@project/src/app/components/documentacao/documentacao.component';
import { ColaboradorEndpointService } from '@project/src/app/service/colaborador-endpoint.service';
import { LocalizacaoComponent } from '@project/src/app/components/localizacao/localizacao.component';
import { ConfiguracaoLocalizacaoComponent } from '@project/src/app/components/configuracao-localizacao/configuracao-localizacao.component';
import { LocalizacaoEndPointService } from '@project/src/app/service/localizacao-endpoint.service';
import { PersonalizacaoInvestimentosComponent } from '@project/src/app/components/personalizacao-investimentos/personalizacao-investimentos.component';
import { IndicarContatoComponent } from '@project/src/app/components/indicar-contato/indicar-contato.component';
import { CodigoConvenioComponent } from '@project/src/app/components/codigo-convenio/codigo-convenio.component';
import { TelaLoginComponent } from '@project/src/app/components/tela-login/tela-login.component';
import { PersonalizacaoEmprestimoComponent } from '@project/src/app/components/personalizacao-emprestimo/personalizacao-emprestimo.component';
import { ConfigAppsComponent } from '@project/src/app/components/config-apps/config-apps.component';
import { AtendimentoClienteComponent } from '@project/src/app/components/atendimento-cliente/atendimento-cliente.component';
import { TokenAtendimentoEndpointService } from '@service/token-atendimento-endpoint.service';
import { HomeEndpointService } from '@project/src/app/service/home-endpoint.service';
import { HorarioCadastroComponent } from '../../../interno/root/horario-cadastro/horario-cadastro.component';
import { filter } from 'rxjs/operators';
import { WebsocketNotificationsService } from '@project/src/app/service/websocket-notifications.service';
import { IntegracaoDealsignComponent } from '@project/src/app/components/integracao-dealsign/integracao-dealsign.component';
import { PersonalizarMensagensEmailComponent } from '@project/src/app/components/personalizar-mensagens-email/personalizar-mensagens-email.component';
import { NeoFinanceComponent } from '@project/src/app/components/neo-finance/neo-finance.component';
import { SenhasBanidasComponent } from '@project/src/app/components/senhas-banidas/senhas-banidas.component';
import { AlterarSenhaRecorrenteComponent } from '../../../interno/root/alterar-senha-recorrente/alterar-senha-recorrente.component';
import { TransferenciaLoteSistemaListarComponent } from '../../../interno/root/transferencia-lote-sistema/transferencia-lote-sistema-listar/transferencia-lote-sistema-listar.component';
import { PublicidadeFaturaComponent } from '@project/src/app/components/publicidade-fatura/publicidade-fatura.component';
import { PagamentoLoteSistemaListarComponent } from '../../../interno/root/pagamento-lote-sistema/pagamento-lote-sistema-listar/pagamento-lote-sistema-listar.component';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;
@Component({
  selector: 'app-navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.less'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public dataSource = new MatTableDataSource<ListaNotificacaoDTO>();
  public lista: PageResponse<ListaNotificacaoDTO>;
  public checkToggle: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('menu', { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild(LocalizacaoComponent, { static: false })
  localizacaoComponent: LocalizacaoComponent;
  env = environment;
  private destroy = new Subject();
  limiteAntecipacao: number;
  ehAdmin = false;
  ehRoot = false;
  ehDesenvolvimento = false;
  Opened = false;
  fixaSidebar = false;
  public operador: any = null;
  usuarioLogado: any = [];
  empresaLogada: any = {};
  filtroData = {
    dataInicial: moment().subtract(30, 'days').toDate(),
    dataFinal: new Date(),
  };
  ehColaborador: boolean = false;
  newUser: boolean = false;
  public lida: boolean = false;
  notificacaoList: any = [];
  authEmpresa: boolean = false;
  visualizaPainelColaborador: boolean = false;
  authCorrentista: boolean = false;
  authAntecipacao: boolean = false;
  authCobranca: boolean = false;
  authTransferencia: boolean = false;
  authTransferenciaLote: boolean = false;
  authPagamentoLote: boolean = false;
  authPagamentoContas: boolean = false;
  authTesouraria: boolean = false;
  authContatos: boolean = false;
  authIass: boolean = false;
  authLimite: boolean = false;
  authAtendimentoCliente: boolean = false;
  authModerarColaborador: boolean = false;
  authEmailSistema: boolean = false;
  authConfiguracaoCarrossel: boolean = false;
  authConfiguracaoCadastro: boolean = false;
  authConfiguracaoDescricao: boolean = false;
  authSuporteContato: boolean = false;
  authConfiguracaoGeral: boolean = false;
  authTarifasPadroes: boolean = false;
  authApps: boolean = false;
  authIntegracao: boolean = false;
  authOnboardingBaaS: boolean = false;
  authOnboardingIaaS: boolean = false;
  authOnboardingCaaS: boolean = false;
  authListarOperadores: boolean = false

  informacoesFinanceiras: InformacoesFinanceirasDTO;
  valorDisponivel: number;
  temValorEmprestimo: boolean = false;
  exibeValorLimites: boolean = false;
  android = null;
  apple = null;
  listContato: any;
  tipoColaborador: string;
  tipoLocalizacao: string = 'login';
  tipoLocalizacaoLiberado: any;
  tokenAtendimento = '';
  private subscriptionUnreadNotificacoes: Subscription;
  private subscriptionNewNotificacao: Subscription;
  alcadaPagamentoAtivada = false;
  transferenciaLoteSistemaAtivada = false;
  pagamentoLoteSistemaAtivado = false;

  @ViewChild('app-navbar-cmp', { static: true }) button: any;
  isDataAvailable: boolean = false;

  tiposUsuario = [
    {
      tipo: 'colaborador',
      texto: 'Colaborador',
    },
  ];

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public autenticacao: AutenticacaoEndpointService,
    private notificacao: NotificacaoEndpointService,
    private dialog: MatDialog,
    private listaCobrancaService: ListaCobrancaService,
    private changeDetectorRef: ChangeDetectorRef,
    private rootEndpointService: RootEndpointService,
    private adminService: AdminService,
    private colaboradorService: ColaboradorEndpointService,
    private tokenAtendimentoService: TokenAtendimentoEndpointService,
    public toastr: ToastrService,
    public stringFormat: StringFormatService,
    public suporteContatoEndPoint: SuporteContatoEndpointService,
    public empresaService: EmpresaEndpointService,
    public emprestimoService: EmprestimoEndpointService,
    public localizacaoService: LocalizacaoEndPointService,
    private homeService: HomeEndpointService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private webSocketNotificationsService: WebsocketNotificationsService
  ) {
    this.location = location;
    this.sidebarVisible = false;

    const userData = this.autenticacao.sessao;

    if (!this.empresaLogada) {
      this.empresaLogada = JSON.parse(localStorage.getItem(`regraEmpresa`));
      this.empresaLogada = this.empresaLogada.empresa;
    }

    this.usuarioLogado = this.autenticacao.sessao?.principal.usuario;

    if (userData?.name === 'root@root') this.ehRoot = true;
    if (userData?.name === 'desenvolvimento@root') this.ehDesenvolvimento = true;

    if (
      this.storage.get('fixaSidebar') === false ||
      !this.storage.has('fixaSidebar')
    ) {
      this.fixaSidebar = false;
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('justify-center-flex');
      this.adminService.enviaUpdate(true);
      misc.sidebar_mini_active = false;
      this.fixaSidebar = true;
    }

    const checkAuthorities = new CheckAuthoritiesService(userData);

    this.operador = checkAuthorities.isOperador;
    this.ehColaborador = checkAuthorities.isColaborador;
    this.ehAdmin = checkAuthorities.isAdministrador;
    this.authEmpresa = checkAuthorities.permissaoGuard('ROLE_EMPRESA_LISTAR');
    this.visualizaPainelColaborador = checkAuthorities.possuiPermissaoRoot();
    this.authCorrentista = checkAuthorities.permissaoGuard('ROLE_EMPRESA_ROOT');
    this.authAntecipacao = checkAuthorities.permissaoGuard(
      'ROLE_ANTECIPACAO_ROOT'
    );
    this.authCobranca = checkAuthorities.permissaoGuard('ROLE_COBRANCA_ROOT');
    this.authTransferencia = checkAuthorities.permissaoGuard(
      'ROLE_TRANSFERENCIA_ROOT'
    );
    this.authTransferenciaLote = checkAuthorities.permissaoGuard(
      'ROLE_TRANSFERENCIA_LOTE_SISTEMA'
    );
    this.authPagamentoLote = checkAuthorities.permissaoGuard(
      'ROLE_PAGAMENTO_LOTE_SISTEMA'
    );
    this.authPagamentoContas = checkAuthorities.permissaoGuard(
      'ROLE_PAGAMENTOCONTAS_ROOT'
    );
    this.authTesouraria = checkAuthorities.permissaoGuard('ROLE_SISTEMA_ROOT');
    this.authContatos = checkAuthorities.permissaoGuard('ROLE_CONTATO_LISTAR');
    this.authIass = checkAuthorities.permissaoGuard('ROLE_INVESTIMENTO_ROOT');
    this.authLimite = checkAuthorities.permissaoGuard('ROLE_LIMITE_ROOT');
    this.authAtendimentoCliente = checkAuthorities.permissaoGuard(
      'ROLE_ATENDIMENTO_CLIENTE_ROOT'
    );
    this.authModerarColaborador = checkAuthorities.permissaoGuard(
      'ROLE_MODERAR_COLABORADOR_ROOT'
    );

    this.authEmailSistema = checkAuthorities.permissaoGuard('ROLE_CADASTRAR_EMAIL_SISTEMA');
    this.authConfiguracaoCadastro = checkAuthorities.permissaoGuard('ROLE_CONFIGURAR_CADASTRO_SISTEMA');
    this.authConfiguracaoGeral = checkAuthorities.permissaoGuard('ROLE_CONFIGURAR_GERAL_SISTEMA');
    this.authConfiguracaoCarrossel = checkAuthorities.permissaoGuard('ROLE_CONFIGURAR_CAROUSEL_SISTEMA');
    this.authConfiguracaoDescricao = checkAuthorities.permissaoGuard('ROLE_TEXTO_SISTEMA');
    this.authSuporteContato = checkAuthorities.permissaoGuard('ROLE_SUPORTE_CONTATO');
    this.authTarifasPadroes = checkAuthorities.permissaoGuard('ROLE_TARIFAS_PADROES');
    this.authApps = checkAuthorities.permissaoGuard('ROLE_CONFIGURACAO_APPS');
    this.authIntegracao = checkAuthorities.permissaoGuard('ROLE_CONFIGURAR_INTEGRACAO');
    this.authOnboardingBaaS = checkAuthorities.permissaoGuard('ROLE_ONBOARDING_BAAS');
    this.authOnboardingIaaS = checkAuthorities.permissaoGuard('ROLE_ONBOARDING_IAAS');
    this.authOnboardingCaaS = checkAuthorities.permissaoGuard('ROLE_ONBOARDING_CAAS');
    this.authListarOperadores = checkAuthorities.permissaoGuard('ROLE_OPERADORES_LISTAR')

    if (this.env.modulos.usuarios.assessor)
      this.tiposUsuario.push({ tipo: 'assessor', texto: 'Assessor' });
    if (this.env.modulos.usuarios.assistente)
      this.tiposUsuario.push({ tipo: 'assistente', texto: 'Assistente' });
    if (this.env.modulos.usuarios.dtvm)
      this.tiposUsuario.push({ tipo: 'dtvm', texto: 'DTVM' });
    if (this.env.modulos.usuarios.gerenteDeContas)
      this.tiposUsuario.push({
        tipo: 'gerenteDeContas',
        texto: 'Gerente de Contas',
      });
    if (this.env.modulos.usuarios.gestora)
      this.tiposUsuario.push({ tipo: 'gestora', texto: 'Gestora' });

    if (this.ehColaborador) {
      this.colaboradorService.getByEmail(this.usuarioLogado.email).subscribe(
        (res) => {
          this.tipoColaborador = res.tipoColaborador || 'colaborador';
        },
        (error) => {
          console.error(error);
          Swal.fire({
            title: 'Erro!',
            text: error.error,
            icon: 'error',
          });
        }
      );
    }
    this.webSocketNotificationsService.initializeWebSocketConnection();
  }
 
  getConfig() {
    this.homeService
      .getConfiguracoesSistema()
      .pipe()
      .subscribe(
        (response) => {
          this.android = response.urlAppAndroid;
          this.apple = response.urlAppApple;
          this.alcadaPagamentoAtivada = response.alcadaPagamentoAtivada;
          this.transferenciaLoteSistemaAtivada = response.transferenciaLoteSistemaAtivada;
          this.pagamentoLoteSistemaAtivado = response.pagamentoLoteSistemaAtivado;
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error,
          });
          console.error(error);
        }
      );
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      setTimeout(function () {
        body.classList.remove('sidebar-mini');

        misc.sidebar_mini_active = false;
      }, 1);
      this.adminService.enviaUpdate(true);
    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 10);
      this.adminService.enviaUpdate(false);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  tooglePainelAdm() {
    const body = document.getElementsByTagName('body')[0];

    if (this.checkToggle === true) {
      setTimeout(function () {
        //body.classList.remove("sidebar-mini");

        this.checkToggle = false;
      }, 1);
      this.adminService.enviaUpdatePainelAdm(true);
    } else {
      setTimeout(function () {
        //body.classList.add("sidebar-mini");

        this.checkToggle = true;
      }, 10);
      this.adminService.enviaUpdatePainelAdm(false);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');
    } else {
      setTimeout(function () {
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    this.getConfig();
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.autenticacao._regraEmpresa.pipe(untilDestroyed(this)).subscribe(
      (response) => {
        if (response) {
          this.empresaLogada = response.empresa;
        }
      },
      (error) => {
        console.error(error);
      }
    );

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }

    if (!this.operador) {
      this.listar();
    }

    if (!this.ehAdmin) {
      if (this.autenticacao.regraEmpresa) {
        this.exibirValorEmprestimo();
      }
      this.autenticacao._regraEmpresa.pipe(untilDestroyed(this)).subscribe(
        (response) => {
          if (response) {
            this.limiteAntecipacao =
              response.empresa.configuracao.limiteAntecipacao;
            if (this.temValorEmprestimo) {
              this.exibeValorLimites = false;
            } else {
              this.exibeValorLimites = true;
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }

    this.newUser =
      this.autenticacao?.sessao?.principal?.usuario.moderacao === 'incompleto'
        ? false
        : true;
    this.listarItems();
    this.permissaoGeolocalizacao();

    this.notificacao.notificacaoVisualizada$.subscribe((id) => {
      if (this.lista && !this.ehAdmin) {
        this.lista.content = this.lista.content.filter( 
          (notificacao) => notificacao.id !== id
        );
        this.lista.totalElements--;
        this.notificacaoList = this.lista.content;
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkToggle = false;
      });

    this.subscriptionUnreadNotificacoes =
      this.notificacao.unreadNotificacoesList$.subscribe((data) => {
        this.dataSource.connect().next(data?.content);
        this.lista = data;
        this.notificacaoList = data?.content;
        this.changeDetectorRef.detectChanges();
      }, console.error);

    this.subscriptionNewNotificacao =
      this.notificacao.newNotificacao$.subscribe((notificacao) => {
        this.toastr
          .info(
            notificacao.url ? 'Clique aqui para visualizar' : '',
            notificacao.mensagem,
            {
              timeOut: 10000,
              toastClass: `toast-${notificacao.tipo
                .split('_')[0]
                .toLowerCase()} ngx-toastr toast-info`,
              progressBar: true,
            }
          )
          .onTap.pipe()
          .subscribe(() => {
            this.dialog.closeAll();
            if (!this.ehDesenvolvimento && !this.ehRoot) {
              this.webSocketNotificationsService.decrementUnreadNotifications(
                notificacao
              );
            }
            if (notificacao.url) {
              this.abrirNotificacaoUrl(notificacao);
            }
          });
      }, console.error);
  }

  ngOnDestroy() {
    this.subscriptionUnreadNotificacoes?.unsubscribe();
    this.subscriptionNewNotificacao?.unsubscribe();
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes!.filtro!.currentValue;
    const previousValue = changes!.filtro!.previousValue;
    if (currentValue !== previousValue && !!currentValue) {
      this.paginator.firstPage();
      this.listar();
    }
  }

  onResize() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    setTimeout(function () {
      $toggle.classList.add('toggled');
    }, 430);

    const $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    if (body.querySelectorAll('.main-panel')) {
      document.getElementsByClassName('main-panel')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document
        .getElementsByClassName('wrapper-full-page')[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    $layer.onclick = function () {
      // asign a function
      body.classList.remove('nav-open');
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove('visible');

      setTimeout(function () {
        try {
          $layer.remove();
          $toggle.classList.remove('toggled');
        } catch (error) { }
      }, 400);
    }.bind(this);

    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    try {
      this.toggleButton.classList.remove('toggled');
    } catch (error) { }
    const $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    this.sidebarVisible = false;
    body.classList.remove('nav-open');
    body.classList.remove('nav-open');
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      try {
        $toggle.classList.remove('toggled');
      } catch (error) { }
    }, 400);

    this.mobile_menu_visible = 0;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  exibirValorEmprestimo() {
    if (!this.ehColaborador || !this.operador) {
      this.empresaService
        .listarInformacoesFinanceiras(this.autenticacao.regraEmpresa.empresa.id)
        .subscribe(
          (res) => {
            this.informacoesFinanceiras = res;
            if (
              res &&
              res.rendaMensal !== null &&
              res.rendaMensal !== undefined
            ) {
              const taxaEndividamento = 30;
              const salarioAnual = res.rendaMensal * 12;
              this.valorDisponivel = salarioAnual * (taxaEndividamento / 100);
            }
          },
          (error) => {
            console.error(error);
          }
        );
      this.empresaService
        .getEmpresaById(this.autenticacao.regraEmpresa.empresa.id)
        .subscribe(
          (res) => {
            const empresa = res;
            this.emprestimoService
              .listarEmprestimosPorCodigo(empresa.codigoConvenio, 0, 1)
              .subscribe((resEmprestimo) => {
                if (resEmprestimo && resEmprestimo.content.length > 0) {
                  this.temValorEmprestimo = true;
                  this.exibeValorLimites = false;
                } else {
                  this.temValorEmprestimo = false;
                  this.exibeValorLimites = true;
                }
              });
          },
          (error) => {
            console.error(error);
            // Swal.fire({
            //   title: 'Ops!',
            //   text: error.error,
            //   icon: 'error'
            // });
          }
        );
    } else {
      this.exibeValorLimites = true;
      this.temValorEmprestimo = false;
    }
  }

  abrirNotificacao() {
    this.dialog.open(NotificacaoComponent, {
      backdropClass: 'modal-maior',
    });
  }

  suporteContato() {
    this.dialog
      .open(SuporteEContatoComponent, {
        backdropClass: 'modal-medio',
      })
      .afterClosed()
      .subscribe((result) => {
        this.listarItems();
      });
  }

  investimentos() {
    this.dialog.open(PersonalizacaoInvestimentosComponent, {
      backdropClass: 'modal-medio',
    });
  }

  publicidade() {
    this.dialog
      .open(PublicidadeComponent, {
        backdropClass: 'modal-medio',
      })
      .afterClosed()
      .subscribe((result) => {
        if(result){
          window.location.reload();
        }
      });
  }

  telaLogin() {
    this.dialog.open(TelaLoginComponent, {
      backdropClass: 'modal-retangular-medio',
    });
  }

  configApps() {
    this.dialog.open(ConfigAppsComponent, {
      backdropClass: 'modal-retangular-medio',
    });
  }

  configCarrossel() {
    this.dialog
      .open(ConfiguracaoCarrosselComponent, {
        backdropClass: 'modal-retangular-menor-resp',
      })
      .afterClosed()
      .subscribe((result) => {
        window.location.reload();
      });
  }

  codigoConvenio() {
    this.dialog.open(CodigoConvenioComponent, {
      backdropClass: 'modal-medio',
    });
  }

  personalizarEmprestimo() {
    this.dialog.open(PersonalizacaoEmprestimoComponent, {
      backdropClass: 'modal-medio',
    });
  }

  conciliar() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.onchange = ((ev: any) => {
      const file = ev.path[0].files[0];
      if (!file.name.endsWith('.xlsx')) {
        Swal.fire({
          title: 'Ops!',
          text: 'No momento a conciliação funciona apenas com arquivo xlsx',
          icon: 'error',
        });
        return;
      }
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      } as SweetAlertOptions);

      const reader = new FileReader();

      reader.onload = (event: any) => {
        const base64 = event.target.result.substring(
          event.target.result.lastIndexOf(',') + 1,
          event.target.result.length
        );
        const arquivo = {
          locale: 'UTF-8',
          base64: base64,
          mimeType: file.type || 'application/octet-stream',
          nome: file.name,
          tamanho: String(file.size),
        } as ArquivoDTO;
        this.rootEndpointService
          .conciliarTitulosAbertosBanco(arquivo)
          .toPromise()
          .then((response) => {
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${response}`;
            link.download = `resultado_${file.name}`;
            link.click();
          })
          .catch((err) => Swal.fire('Ops!', JSON.parse(err.error), 'error'))
          .then(() => Swal.close());
      };
      reader.readAsDataURL(file);

      reader.onload = (event: any) => {
        const base64 = event.target.result.substring(
          event.target.result.lastIndexOf(',') + 1,
          event.target.result.length
        );
        const arquivo = {
          locale: 'UTF-8',
          base64: base64,
          mimeType: file.type || 'application/octet-stream',
          nome: file.name,
          tamanho: String(file.size),
        } as ArquivoDTO;
        this.rootEndpointService
          .conciliarTitulosAbertosBanco(arquivo)
          .toPromise()
          .then((response) => {
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${response}`;
            link.download = `resultado_${file.name}`;
            link.click();
          })
          .catch((err) => Swal.fire('Ops!', JSON.parse(err.error), 'error'))
          .then(() => Swal.close());
      };
    }).bind(this);
    fileSelector.click();
  }

  getTitle() {
    const titlee: any = this.location.prepareExternalUrl(this.location.path());
    for (let i = 0; i < this.listTitles.length; i++) {
      if (
        this.listTitles[i].type === 'link' &&
        this.listTitles[i].path === titlee
      ) {
        return this.listTitles[i].title;
      } else if (this.listTitles[i].type === 'sub') {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          const subtitle =
            this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return ' '; // Dashboard
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  listar() {
    this.changeDetectorRef.detectChanges();
    let order: string;
    const lida = this.lida;
    let page = 0,
      size = 5;
    if (this.dataSource) {
      if (this.dataSource.paginator) {
        page = this.dataSource.paginator.pageIndex;
        size = this.dataSource.paginator.pageSize;
      }
    }
    this.notificacao
      .listar({
        order,
        page,
        size,
        lida,
      })
      .subscribe((data) => {
        this.dataSource.connect().next(data?.content);
        this.lista = data;
        this.notificacaoList = data?.content;
        return data;
      });
  }

  atualizarNotificacao(element: any) {
    this.notificacao
      .alterarNotificacaoParaVisualizado(element.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.listar();
          if (element.url) {
            this.abrirNotificacaoUrl(element);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  abreTomticket() {
    window.open('https://movvime.tomticket.com/kb', '_blank');
  }

  abrirNotificacaoUrl(notificacao: ListaNotificacaoDTO) {
    let url = notificacao.url;
    let queryParams: string;
    if (notificacao.url.includes('?')) {
      [url, queryParams] = notificacao.url.split('?');
    }

    const params = {};
    if (queryParams) {
      queryParams.split('&').forEach((param) => {
        const [key, value] = param.split('=');
        params[key] = decodeURIComponent(value);
      });
    }

    if (!notificacao.openInNewTab) {
      this.router.navigate([`/privado/${url}`], { queryParams: params });
    } else {
      window.open(url, '_blank');
    }
  }

  logout() {
    this.subscriptionNewNotificacao.unsubscribe();
    this.webSocketNotificationsService.disconnectWebSocketConnection();

    this.autenticacao
      .sair()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.router.navigateByUrl('/acesso');
        },
        (error) => {
          console.error(error);
        }
      );
  }

  gerenteModal() {
    this.dialog.open(FalarComGerenteComponent, {
      width: '800px',
      panelClass: 'fundo-empresa',
    });
  }

  importarCnab() {
    this.dialog.open(CnabComponent, {
      backdropClass: 'modal-medio',
    });
  }

  editarUsuario() {
    this.dialog.open(UsuarioEditarComponent, {
      width: '60%',
      height: '90%',
      backdropClass: 'close-button-position',
    });
  }

  novaCobranca() {
    this.listaCobrancaService.novaCobranca('1');
  }

  novaNotificacao() {
    this.dialog.open(NovaNotificacaoComponent, {
      backdropClass: 'modal-maior',
    });
  }

  editar(itemSelecionado?) {
    this.dialog.open(PessoaEditarComponent, {
      width: '1240px',
      data: {
        tipo: 'contato',
        ...itemSelecionado,
      },
      backdropClass: 'close-button-position',
      panelClass: 'fundo-empresa',
    });
  }

  liquidarBoleto() {
    this.dialog.open(LiquidarArquivoComponent, {
      width: '620px',
      height: '260px',
      backdropClass: 'close-button-position',
      data: {
        tipo: 'boleto',
      },
    });
  }

  liquidarTransferencia() {
    this.dialog.open(LiquidarArquivoComponent, {
      width: '620px',
      height: '260px',
      backdropClass: 'close-button-position',
      data: {
        tipo: 'transferencia',
      },
    });
  }

  abrirModalExportar() {
    this.router.navigate(['/root/exportar-planilha/']);
  }

  todasCobrancasRoot() {
    this.router.navigate(['/root/cobrancas/']);
  }

  gerenciarAntecipacoes() {
    this.router.navigate(['/root/antecipacao/']);
  }

  gerenciarFundos() {
    this.router.navigate(['/root/fundos/']);
  }

  gerenciarEmpresas() {
    this.router.navigate(['/root/pessoa/empresa']);
  }

  gerenciarCadastros() {
    this.router.navigate(['/root/moderacao']);
  }

  gerenciarTransferencias() {
    this.router.navigate(['/root/transferencias']);
  }

  gerenciarPagamentoContas() {
    this.router.navigate(['/root/pagamento-contas']);
  }

  gerenciarUsuarios(tipo: string) {
    this.router.navigate([`/root/usuarios/${tipo}`]);
  }

  gerenciarOperadores(){
    this.router.navigate(['/root/operadores']);
  }

  gerenciarPerfilUsuario() {
    this.router.navigate(['/root/perfil-usuario']);
  }

  moderarEmprestimos() {
    this.router.navigate(['/root/moderar-emprestimos']);
  }

  gerenciarEmprestimos() {
    this.router.navigate(['/root/gerenciar-emprestimos']);
  }

  codigoConvenioRoot() {
    this.router.navigate(['/root/codigos-convenio']);
  }

  listarSensibilizacoes() {
    this.router.navigate(['/root/lista-sensibilizacao']);
  }

  alteraTipoLocalizacao() {
    this.tipoLocalizacao = 'conta';
    this.permissaoGeolocalizacao();
  }

  onClickOpcoes() {
    this.alteraTipoLocalizacao();
    this.getTokenAtendimento();
  }

  getTokenAtendimento() {
    this.tokenAtendimentoService
      .getToken(this.usuarioLogado.id, this.empresaLogada.id)
      .subscribe((res) => {
        const agora = moment(new Date());
        const dataAtualizacao = moment(new Date(res.dataAtualizacao));
        if (
          !res.token ||
          moment.duration(agora.diff(dataAtualizacao)).asHours() >= 1
        ) {
          const data = {
            usuarioId: this.usuarioLogado.id,
            empresaId: this.empresaLogada.id,
            corporacaoId: this.autenticacao.regraCorporacao.corporacao.id,
          };
          this.tokenAtendimentoService
            .novoTokenAtendimento(data)
            .subscribe((res) => {
              this.tokenAtendimento = res;
            });
        } else this.tokenAtendimento = res.token;
      });
  }

  atendimentoCliente() {
    const dialog = this.dialog.open(AtendimentoClienteComponent, {
      width: '70rem',
      height: '38rem',
    });
  }

  async conta() {
    // if (this.localizacaoComponent) {
    //   const checkLocalizacao = await this.localizacaoComponent.getLocationAndCheckPermission();

    //   if (checkLocalizacao !== "OK") {
    //     return Swal.fire(
    //       'Atenção',
    //       'Você precisa permitir o acesso a sua localização para prosseguir',
    //       'warning'
    //     );
    //   }
    // }

    this.router.navigate(['/privado/empresa']);
  }

  sensibilizacao() {
    const dialog = this.dialog.open(SensibilizacaoComponent, {
      backdropClass: 'modal-config',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.sensibilizacao();
        }
      }
    });
  }

  geral() {
    const dialog = this.dialog.open(PersonalizaoGeralComponent, {
      backdropClass: 'modal-maior',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.geral();
        }
      }
    });
  }

  apps(sistema: string) {
    const dialog = this.dialog.open(AppsComponent, {
      backdropClass: 'modal-retangular-menor-resp',
      data: {
        sistema,
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.apps(sistema);
        }
      }
    });
  }

  modulos() {
    const dialog = this.dialog.open(ModulosHabilitadosComponent, {
      panelClass: 'modal-retangular',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.modulos();
        }
      }
    });
  }

  personalizacaoSistema() {
    const dialog = this.dialog.open(PersonalizacaoSistemaComponent, {
      panelClass: 'modal-retangular',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.personalizacaoSistema();
        }
      }
    });
  }

  emailSistema() {
    const dialog = this.dialog.open(EmailSistemaComponent, {
      panelClass: 'modal-retangular',
    });
  }

  personalizarMensagensEmail() {
    const dialog = this.dialog.open(PersonalizarMensagensEmailComponent, {
      panelClass: 'modal-retangular',
      autoFocus: false,
      disableClose: true,
    });
  }

  // falarComGerente() {
  //   const dialog = this.dialog.open(FalarComGerenteEditComponent, {
  //     panelClass: "modal-retangular",
  //   })
  // }

  tarifasPadroes() {
    this.router.navigate(['/root/tarifas-padroes']);
  }

  importacoes() {
    const dialog = this.dialog.open(ImportacoesComponent, {
      panelClass: 'modal-retangular',
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.nova === true) {
          this.importacoes();
        }
      }
    });
  }

  onboarding(tipo: string) {
    this.dialog.open(DocumentacaoComponent, {
      backdropClass: 'modal-config',
      data: {
        tipoDocumentacao: tipo,
      },
    });
  }

  configurarLocalizacao() {
    this.dialog
      .open(ConfiguracaoLocalizacaoComponent, {
        backdropClass: 'modal-retangular-medio',
      })
      .afterClosed()
      .subscribe((result) => {
        window.location.reload();
      });
  }

  exportarPlanilha() {
    this.router.navigate([]);
  }

  getIf() {
    if (this.empresaLogada && this.empresaLogada.tipoConta != null) {
      if (this.empresaLogada.tipoConta === `contaDigitalS`) {
        return this.env.empresa.razaoSocial + ` ` + this.env.empresa.cnpj;
      } else {
        return this.env.empresa.nomeIF + ` ` + this.env.empresa.cnpjIF;
      }
    }
  }

  getComp() {
    if (this.empresaLogada) {
      if (this.empresaLogada.tipoConta === `contaDigitalS`) {
        return `000`;
      } else {
        return this.env.codBanco;
      }
    }
  }

  getTipo() {
    if (this.empresaLogada && this.empresaLogada.tipoConta != null) {
      switch (this.empresaLogada.tipoConta) {
        case `contaDigitalS`: {
          return `Conta Digital S`;
        }
        case `contaCobrancaDigital`: {
          return `Conta Cobrança Digital`;
        }
        case `contaCorrenteDigital`: {
          return `Conta Corrente Digital`;
        }
        case `contaVinculadaDigital`: {
          return `Conta Vinculada Digital`;
        }
        case `contaEscrowDigital`: {
          return `Conta Escrow Digital`;
        }
        case `contaBloqueioJudicial`: {
          return `Conta Bloqueio Judicial`;
        }
        case `contaSalarioDigital`: {
          return `Conta Salário Digital`;
        }
        case `contaServicoDigital`: {
          return `Conta Serviço Digital`;
        }
        case `contaInvestidorDigital`: {
          return `Conta Investidor Digital`;
        }
        case `contaDigital`: {
          return `Conta Digital`;
        }
      }
    }
  }

  calculaNroConta() {
    if (this.empresaLogada.pessoa) {
      var numeroConta = this.empresaLogada.numeroConta;
      if (numeroConta != null) {
        return (
          numeroConta.substring(0, numeroConta.length - 1) +
          '-' +
          numeroConta.substring(numeroConta.length - 1, numeroConta.length)
        );
      }
    }
  }

  getCopiar() {
    let tipoConta: string;
    let nomeDisplay: string;
    let codBanco: string = this.env.codBanco;
    let agencia: string = this.getAgencia(this.empresaLogada);
    if (this.empresaLogada && this.empresaLogada.tipoConta != null) {
      if (this.empresaLogada.pessoa.documento.length > 11) {
        nomeDisplay = this.empresaLogada.pessoa.razaoSocial;
      } else {
        nomeDisplay = this.empresaLogada.pessoa.nome;
      }
      switch (this.empresaLogada.tipoConta) {
        case `contaDigitalS`: {
          tipoConta = 'C/contaDigitalS';
          break;
        }
        case `contaCobrancaDigital`: {
          tipoConta = 'C/ContaCobrancaDigital';
          break;
        }
        case `contaCorrenteDigital`: {
          tipoConta = 'C/Corrente';
          break;
        }
        case `contaVinculadaDigital`: {
          tipoConta = 'C/Vinculada';
          break;
        }
        case `contaEscrowDigital`: {
          tipoConta = 'C/Escrow';
          break;
        }
        case `contaBloqueioJudicial`: {
          tipoConta = 'C/Bloqueio';
          break;
        }
        case `contaSalarioDigital`: {
          tipoConta = 'C/Salario';
          break;
        }
        case `contaServicoDigital`: {
          tipoConta = 'C/Servico';
          break;
        }
        case `contaInvestidorDigital`: {
          tipoConta = 'C/Investidor';
          break;
        }
        case `contaDigital`: {
          tipoConta = 'C/Digital';
          break;
        }
      }
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData(
          'text/plain',
          nomeDisplay +
          ' - ' +
          this.stringFormat.adicionarPontosDocumento(
            this.empresaLogada.pessoa.documento
          ) +
          ' - Bco: ' +
          codBanco +
          ' - Ag: ' +
          agencia +
          ' - ' +
          tipoConta +
          ': ' +
          this.calculaNroConta()
        );
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
    }
    this.toastr.success('Dados Copiados!', '', {
      positionClass: 'toast-top-center',
      timeOut: 2000,
    });
  }

  getAgencia(empresa: any) {
    if (empresa.agencia) {
      return empresa.agencia.padStart(4, '0');
    } else return this.env.codAgencia;
  }

  openMenu(menu) {
    this.menuTrigger = menu;
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  gerenciarCadastrosIAAS() {
    this.router.navigate(['/root/moderacao-iaas']);
  }

  gerenciarCadastrosFundos() {
    this.router.navigate(['/root/moderacao-fundos']);
  }

  isOpened(event: any) {
    var botao = document.getElementById('navbarDropdownMenuLink');
    if (this.Opened == false) {
      this.Opened = true;
      botao.style.border = '3px solid #FFA300';
      botao.style.borderRadius = '100%';
      botao.style.margin = '0px';
    } else {
      this.Opened = false;
      botao.style.border = '';
      botao.style.borderRadius = '';
      botao.style.margin = '3px';
    }
  }

  listarItems() {
    this.suporteContatoEndPoint
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.listContato = res;
      });
  }

  permissaoGeolocalizacao() {
    this.localizacaoService.listarConfigPorTipo(this.tipoLocalizacao).subscribe(
      (res) => {
        this.tipoLocalizacaoLiberado = res;
      },
      (error) => {
        Swal.fire({
          title: 'Erro!',
          text: error.error,
          icon: 'error',
        });
        console.log(error);
      }
    );
  }

  indicarContato() {
    this.dialog
      .open(IndicarContatoComponent, {
        backdropClass: 'modal-medio',
      })
      .afterClosed()
      .subscribe((result) => {
        window.location.reload();
      });
  }

  pixAnalisarNotificaoInfracao() {
    this.router.navigate(["/root/pix-analisar-notificacao"]);
  }

  pixAnalisarSolicitacaoDevolucao() {
    this.router.navigate(["/root/pix-analisar-solicitacao"]);
  }
  modalHorarioCadastro() {
    this.dialog.open(HorarioCadastroComponent, {
      backdropClass: 'modal-retangular-menor',
      width: '500px',
      height: '575px',
    });
  }


  listarChavesReivindicadas() {
    this.router.navigate(["/root/pix-listar-reivindicadas"]);
  }

  listarChavesCadastradas() {
    this.router.navigate(["/root/pix-listar-cadastradas"]);
  }

  listarTransferenciasPix() {
    this.router.navigate(["/root/pix-listar-transferencias"]);
  }

  alcadaPix(){ 
    this.router.navigate(["/root/pix-gerenciar"]);
  }

  configurarDealSign() {
    const dialog = this.dialog.open(IntegracaoDealsignComponent, {
      panelClass: 'modal-retangular',
    });
  }

  neoFinance() {
    const dialog = this.dialog.open(NeoFinanceComponent, {
      panelClass: 'modal-retangular',
    });
  }

  senhasBanidas() {
    const dialog = this.dialog.open(SenhasBanidasComponent, {
      panelClass: 'modal-retangular',
     });
  }

  senhasRecorrentes() {
    const dialog = this.dialog.open(AlterarSenhaRecorrenteComponent, {
      backdropClass: 'modal-retangular-menor',
      width: '500px',
      height: '650px',
      });
  }

  transferenciasEmLote() {
    this.dialog.open(TransferenciaLoteSistemaListarComponent, {
      width: '95%',
      backdropClass: "modal-maior",
    })
  }

  pagamentosEmLote() {
    this.dialog.open(PagamentoLoteSistemaListarComponent, {
      width: '95%',
      backdropClass: "modal-maior",
    })
  }

  publicidadeFatura() {
    this.dialog
    .open(PublicidadeFaturaComponent, {
      backdropClass: 'modal-retangular-menor',
    })
    .afterClosed()
    .subscribe((result) => {
      if(result){
        window.location.reload();
      }
    });
  }
}
