<div class="row resumo">
    <div class="col-12">
        <span class="super-title">Resumo das Transferências em Lote</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <hr>
    </div>
    <div class="col-12">
        <span class="title">Resumo da pré validação</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <span class="font-weight-bolder">Valor total do lote: </span>
        <span>{{valorTotalLote | currency:'':'R$':'':'PT-BR'}}</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <hr>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <span class="font-weight-bolder">Nº de linhas analisadas: </span>
        <span>{{linhasTotais}}</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <span class="font-weight-bolder">Nº de linhas que apresentam erro: </span>
        <span>{{linhasErro}}</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <span class="font-weight-bolder">Nº de linhas corretas: </span>
        <span>{{linhasSucesso}}</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <hr>
    </div>
    <div class="col-12" *ngIf="dadosValidacao" [class.text-success]="dadosValidacao.status"
        [class.text-danger]="!dadosValidacao.status">
        <span class="font-weight-bolder">Status Lote: </span>
        <span>{{dadosValidacao.mensagemStatus}}</span>
    </div>
    <div class="col-12" *ngIf="dadosValidacao">
        <hr>
    </div>
    <div class="col-12" *ngIf="transferenciaLoteEditarUploadComponent?.dataValidacao">
        <span class="font-weight-bolder">Data/hora da consulta: </span>
        <span>{{transferenciaLoteEditarUploadComponent.dataValidacao | date:'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div class="col-12">
        <span class="rodape">Dúvidas?</span>
    </div>
    <div class="col-12" *ngIf="urlAjuda">
        <a class="rodape" target="_blank" href={{urlAjuda}}>
            Visite nossa FAQ
        </a>
    </div>
</div>