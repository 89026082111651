import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { TokenComponent } from '@project/src/app/components/token/token.component';
import { TransferenciaLoteDTO } from '@project/src/app/model/financeiro/transferencia/transferencia-lote-dto.model';
import { TransferenciaEndpointService } from '@project/src/app/service/transferencia-endpoint.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { StepConfig } from '../../../cnab/cnab-editar/cnab-editar.model';
import { PagamentoLoteSistemaModeloComponent } from './pagamento-lote-sistema-modelo/pagamento-lote-sistema-modelo.component';
import { PagamentoLoteSistemaUploadComponent } from './pagamento-lote-sistema-upload/pagamento-lote-sistema-upload.component';
import { PagamentoEndpointService } from '@project/src/app/service/pagamento-endpoint.service';
import { PagamentoLoteSistemaDTO } from '@project/src/app/model/financeiro/pagamento/pagamento-lote-sistema-dto.model';

@Component({
  selector: 'app-pagamento-lote-sistema-editar',
  templateUrl: './pagamento-lote-sistema-editar.component.html',
  styleUrls: ['./pagamento-lote-sistema-editar.component.less']
})
export class PagamentoLoteSistemaEditarComponent  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepGroupComponents', { static: false })
  _stepGroupComponents: MatHorizontalStepper;

  @ViewChild('stepGroupButtons', { static: false })
  _stepGroupButtons: MatHorizontalStepper;

  @ViewChild(PagamentoLoteSistemaModeloComponent, { static: false })
  pagamentoLoteEditarModeloComponent: PagamentoLoteSistemaModeloComponent;

  @ViewChild(PagamentoLoteSistemaUploadComponent, { static: false })
  pagamentoLoteEditarUploadComponent: PagamentoLoteSistemaUploadComponent;

  @ViewChild(TokenComponent, { static: false })
  tokenComponent: TokenComponent;

  currentStepIndex: number;
  steps: StepConfig[];

  processando: boolean;

  private destroy: Subject<void>;
  saldoAtual: number;

  constructor(
    public dialogRef: MatDialogRef<PagamentoLoteSistemaEditarComponent>,
    private transferenciaEndpointService: TransferenciaEndpointService,
    private pagamentoService: PagamentoEndpointService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: TransferenciaLoteDTO,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentStepIndex = 0;
    this.destroy = new Subject<void>();

    this.transferenciaEndpointService
      .saldoDisponivel()
      .toPromise()
      .then((response) => (this.saldoAtual = response ? response : 0))
      .catch((error) => console.error(error));
  }

  ngAfterViewInit(): void {
    this.steps = [];
    this.steps.push({
      step: () => this.pagamentoLoteEditarModeloComponent,
      label: 'Download do Modelo',
      back: {
        isShow: () => false,
        label: 'Voltar',
      },
      next: {
        isShow: () => true,
        label: 'Próximo',
      },
    });
    this.steps.push({
      step: () => this.pagamentoLoteEditarUploadComponent,
      label: 'Upload e Validação',
      back: {
        isShow: () => true,
        label: 'Voltar',
      },
      next: {
        isShow: () => true,
        label: 'Próximo',
      },
    });
    this.steps.push({
      step: () => this.tokenComponent,
      label: 'Autenticação (token)',
      back: {
        isShow: () => true,
        label: 'Voltar',
      },
      next: {
        isShow: () => true,
        label: 'Enviar Lote',
      },
    });
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      if (this.dataDialog && this.dataDialog.id) {
        this._stepGroupButtons.selectedIndex = 1;
        this._stepGroupComponents.selectedIndex = 1;
      }
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  stepSelectionChange(event: StepperSelectionEvent) {
    this.currentStepIndex = event.selectedIndex;
  }

  get currentStep() {
    if (this.steps && this.steps.length) {
      return this.steps[this.currentStepIndex];
    }
  }

  voltar() {
    if (this.currentStepIndex <= 0) {
      this.currentStepIndex = 0;
    } else {
      this.currentStepIndex--;
    }
    this._stepGroupButtons.selectedIndex = this.currentStepIndex;
    this._stepGroupComponents.selectedIndex = this.currentStepIndex;
  }

  proximo() {
    const max = this.steps.length - 1;
    if (this.currentStepIndex >= max) {
      this.finalizar();
    }
    if (this.currentStepIndex >= max) {
      this.currentStepIndex = max;
    } else {
      this.currentStepIndex++;
    }
    this._stepGroupButtons.selectedIndex = this.currentStepIndex;
    this._stepGroupComponents.selectedIndex = this.currentStepIndex;
  }

  async finalizar() {
    try {
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const token = this.tokenComponent.getToken();
      const tipoToken = this.tokenComponent.getForm().get("isSMS").value ? "sms" : 
          this.tokenComponent.getForm().get("isEmail").value ? "email" : "mfa";
      await this.pagamentoService
        .enviarLoteSistema(this.pagamentoLoteEditarUploadComponent.dadosValidacao, token, tipoToken)
        .toPromise();
      Swal.close();
      await Swal.fire(
        'Sucesso!',
        'O lote está sendo processado',
        'success'
      );
      window.location.reload();
    } catch (err) {
      await Swal.fire(
        'Ops!',
        `Falha ao processar o lote: ${err.error.error}`,
        'error'
      );
    }
  }

  showStep(index: number) {
    return index === this.currentStepIndex;
  }

  fecha(atualizar = false) {
    this.dialogRef.close(atualizar);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
