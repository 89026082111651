import { Observable } from "rxjs";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AutenticacaoEndpointService } from "../autenticacao-endpoint.service";
import { CustomErrorHandler } from "../exceptions/error-handler.interceptor";
import swal from "sweetalert2";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatDialog } from "@angular/material/dialog";
import { PrimeirosPassosComponent } from "@components/primeiros-passos/primeiros-passos.component";
import { AutenticacaoSecundariaComponent } from "../../components/autenticacao-secundaria/autenticacao-secundaria.component";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { CheckAuthoritiesService } from "../util/checkAuthorities.service";

@Injectable({
  providedIn: "root",
})
export class AcessoInternoService implements CanActivate, CanActivateChild, OnDestroy {
  corporacaoLogada: any = null;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private autenticacaoService: AutenticacaoEndpointService,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private dialog: MatDialog
  ) {
    if (autenticacaoService.regraCorporacao)
      this.corporacaoLogada = autenticacaoService.regraCorporacao.corporacao;
  }

  ngOnDestroy() { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.autenticacaoService
      .me()
      .toPromise()
      .then(async (response) => {
        let logado = !!(response && response.name);

        if (!logado) {
          this.autenticacaoService
            .sair(false, state.url)
            .pipe(untilDestroyed(this))
            .subscribe();
          return false;
        }

        const checkAuthorities = new CheckAuthoritiesService(response)
        let temCorp = false;
        let multifatorAtivado = false;
        let multifatorValidado = false;
        let ehAdmin = checkAuthorities.isAdministrador;
        let ehOperador = checkAuthorities.isOperador;
        let ehColaborador = checkAuthorities.isColaborador;

        temCorp =
          response.authorities.filter(
            (item) => !!item.corporcaoId || !!item.empresaId
          ).length > 0;

        if (!temCorp) {
          temCorp = ehOperador || ehColaborador;
        }

        if (response.principal.usuario) {
          multifatorAtivado = response.principal.usuario.multifatorAtivado;
          multifatorValidado = response.principal.usuario.multifatorValidado;
        }

        if (multifatorAtivado && !multifatorValidado) {
          this.dialog.open(AutenticacaoSecundariaComponent, {
            disableClose: true,
            width: "60rem",
            height: "40rem",
          });
            
          return false;
        }

        if (ehAdmin) {
          return true;
        }

        if (!temCorp) {
          this.dialog.open(PrimeirosPassosComponent, {
            backdropClass: "modal-maior",
            data: {
              apenasCadastroPessoa: false,
            },
          });
        }
        if (ehOperador) {
          if (state.url === "/privado/empresa") {
            return false;
          }
        }
        return true;
      })
      .catch((err) => {
        this.customErrorHandler.handleError(err);
        return this.autenticacaoService
          .sair(false, state.url)
          .toPromise()
          .then(() => false);
      })
      .then((retorno: boolean) => {
        if (
          !retorno &&
          (this.router.url.startsWith("/privado") ||
            this.router.url.startsWith("/root"))
        ) {
          this.dialog.closeAll();
          if (
            this.autenticacaoService.sessao.principal.authorities.filter(
              (item) =>
                item.permissao === "ROLE_OPERADOR" &&
                item.authority === "ROLE_OPERADOR"
            ).length > 0
          ) {
            return swal
              .fire({
                title: "Acesso negado",
                text: "Não possui permissão para acessar esta página.",
                icon: "warning",
                confirmButtonText: "OK",
              })
              .then(() => retorno);
          }
          return swal
            .fire({
              title: "Sessão expirada",
              text: "Sua sessão expirou. Logue novamente para continuar.",
              icon: "warning",
              confirmButtonText: "OK",
            })
            .then(() => retorno);
        }
        return retorno;
      });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean | Promise<boolean> {
    return this.canActivate(route, state);
  }

}
