<div [formGroup]="form">
  <div class="box-body" style="text-align: -webkit-center; height: 100%">
    <p class="super-title" *ngIf="!isPix">Seu cliente poderá pagar com:</p>
    <p class="super-title" *ngIf="isPix">Selecione o tipo de QR code que deseja gerar:</p>
    <div class="row" style="justify-content: center;">
      <!-- Botão de pix -->
      <div
        *ngIf="(env?.modulos?.pagamento?.boletoPix && (env?.modulos?.pix?.gerarQrCode && areaPix && listaChavesPix.length > 0) && tipoCobranca != 4) || (tipoConta === 'contaDigitalS' && env?.modulos?.pagamento?.boletoPix)"
        class="col-2 col-md-3 mini-box" style="margin-right: 1px; align-self: center"
        (click)="setFormaPagamento('boletoPix')" [ngClass]="
          (form.get('metodoPagamento').value === 'boletoPix')
            ? 'selecionado'
            : 'nao-selecionado'
        "
        [matToolTip]="listaChavesPix.length === 0 ? 'Para gerar boleto Pix você precisa ter uma chave cadastrada': ''"
        [disabled]="listaChavesPix.length === 0">
        <i class="fa-brands fa-pix"></i>
        <span class="title">BOLETO PIX</span>
        <i [ngClass]="form.get('metodoPagamento').value === 'boletoPix'
              ? 'far fa-check-square'
              : 'far fa-square'
          " style="vertical-align: center"></i>
      </div>
      <div class="col-1 col1-icone" style="margin-right: 1%"
        *ngIf="env?.modulos?.pagamento?.boletoPix && (env?.modulos?.pix?.gerarQrCode && areaPix && listaChavesPix.length > 0) && tipoCobranca != 4">
        <i class="fas fa-info-circle info-icon"
          matTooltip="*Boleto Pix pode ser pago de duas maneiras:  QR Code utilizando o PIX, compensado D+0 e com o Código de Barras, compensado em D+1."></i>
      </div>
      <!-- Botão de boleto -->
      <div *ngIf="env?.modulos?.pagamento?.boletoBancario && !isPix" class="col-3 col-md-3 mini-box"
        (click)="setFormaPagamento('boleto')" [ngClass]="
          form.get('metodoPagamento').value === 'boleto'
            ? 'selecionado'
            : 'nao-selecionado'
        ">
        <i class="fas fa-barcode"></i>
        <span class="title">BOLETO BANCÁRIO</span>
        <i [ngClass]="
            form.get('metodoPagamento').value === 'boleto'
              ? 'far fa-check-square'
              : 'far fa-square'
          " style="vertical-align: center"></i>
      </div>
      <!-- Botão de cartão de cŕedito -->
      <div *ngIf="(tipoConta === 'contaDigitalS' && env?.modulos?.pagamento?.cartaoCredito) && !isPix"
        class="col-3 col-md-3 mini-box" (click)="setFormaPagamento('cartaoCredito')" [ngClass]="
          form.get('metodoPagamento').value === 'cartaoCredito'
            ? 'selecionado'
            : 'nao-selecionado'
        ">
        <i class="fas fa-credit-card"></i>
        <span class="title">CARTÃO DE CRÉDITO</span>
        <i [ngClass]="
            form.get('metodoPagamento').value === 'cartaoCredito'
              ? 'far fa-check-square'
              : 'far fa-square'
          " style="vertical-align: center"></i>
      </div>

      <div *ngIf="isPix" class="col-3 col-md-3 mini-box selecionado" (click)="setFormaPagamento('pixEstatico')" style="flex: 0 0 30% !important;
      max-width: 35% !important;" [ngClass]="
      form.get('metodoPagamento').value === 'pixEstatico'
        ? 'selecionado'
        : 'nao-selecionado'">
        <i class="fa-brands fa-pix"></i>
        <span class="title">QR CODE SIMPLES</span>
        <i [ngClass]="
        form.get('metodoPagamento').value === 'pixEstatico'
          ? 'far fa-check-square'
          : 'far fa-square'
      " style="vertical-align: center"></i>
      </div>

      <div *ngIf="isPix" class="col-3 col-md-3 mini-box selecionado" style="flex: 0 0 30% !important;
      max-width: 35% !important;" (click)="setFormaPagamento('pixDinamico')" [ngClass]="
      form.get('metodoPagamento').value === 'pixDinamico'
        ? 'selecionado'
        : 'nao-selecionado'">
        <i class="fa-brands fa-pix"></i>
        <span class="title">QR CODE COM VENCIMENTO</span>
        <i [ngClass]="
        form.get('metodoPagamento').value === 'pixDinamico'
          ? 'far fa-check-square'
          : 'far fa-square'
      " style="vertical-align: center"></i>
      </div>


      <div class="col-12 error-msg">
        <mat-error>{{ getErrorMessage(form, "metodoPagamento") }}</mat-error>

      </div>

    </div>

    <!-- <div class="row">
      <p>Quero receber: </p>
      <mat-radio-group>
        <mat-radio-button value="30" class="col-6"><span style="color: #47525E">30 dias após a confirmação</span>
        </mat-radio-button>
        <mat-radio-button value="3" class="col-6"><span style="color: #47525E">03 dias após a confirmação</span>
        </mat-radio-button>
      </mat-radio-group>
    </div> -->

    <div class="row" style="width: 88%">
      <div class="col-6 col-md-6" *ngIf="!isPix">
        <mat-form-field appearance="outline">
          <mat-label>Qual o valor da cobrança?
          </mat-label>
          <input matInput (change)="getValorMinimo()" currencyMask type="text" onclick="this.setSelectionRange(-1,-1);"
            onfocus="this.setSelectionRange(-1,-1);" formControlName="valorNominal"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" required />
          <mat-error>{{ getErrorMessage(form, "valorNominal") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6 col-md-6" *ngIf="isPix">
        <mat-form-field appearance="outline">
          <mat-label>Qual o valor da cobrança?
          </mat-label>
          <input matInput currencyMask type="text" onclick="this.setSelectionRange(-1,-1);"
            onfocus="this.setSelectionRange(-1,-1);" formControlName="valorPix"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [disabled]="desabilitaValor" required />
        </mat-form-field>
        <p *ngIf="form.get('metodoPagamento').value === 'pixEstatico'" style="text-align: left;">
          <span class="corBase" (click)="gerarSemValor()" style="cursor: pointer;">Gerar QR Code sem valor ></span>
        </p>
      </div>
      <div class="col-6 col-md-6" *ngIf="tipoCobranca == 3">
        <mat-form-field appearance="outline">
          <mat-label>Em quantas parcelas?</mat-label>
          <mat-select (selectionChange)="alterouParcelas($event)" formControlName="numeroParcelas">
            <mat-option *ngFor="
                let i of [
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24
                ]
              " [value]="i">
              {{ i }}x
            </mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "numeroParcelas") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6 col-md-6" *ngIf="tipoCobranca == 2">
        <mat-form-field appearance="outline">
          <mat-label>Qual o intervalo da assinatura?</mat-label>
          <mat-select formControlName="intervaloAssinatura" (selectionChange)="setDataFim($event)">
            <mat-option value="mensal">Mensal</mat-option>
            <mat-option value="trimestral">Trimestral</mat-option>
            <mat-option value="semestral">Semestral</mat-option>
            <mat-option value="anual">Anual</mat-option>
          </mat-select>
          <mat-error>{{
            getErrorMessage(form, "intervaloAssinatura")
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6 col-md-6" *ngIf="!isPix">
        <mat-form-field appearance="outline">
          <mat-label>
            {{
            tipoCobranca == 3
            ? "Qual a data de vencimento da primeira parcela?"
            : tipoCobranca == 2
            ? "Qual a data de início da assinatura?"
            : "Qual a data de vencimento da cobrança?"
            }}
          </mat-label>
          <input id="dataVencimento" matInput [matDatepicker]="picker1" [min]="todayDate"
            formControlName="dataVencimento" (keyup)="mascaraData($event, 'dataVencimento')"
            (dateChange)="onChangeDataVencimento($event)" required readonly />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error>{{ getErrorMessage(form, "dataVencimento") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6" *ngIf="isPix">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Chave Pix" *ngIf="listaChavesPix" formControlName="chavePixCobrador"
            (change)="setChaveValue(chave.chave)">
            <mat-option style="overflow: hidden;" *ngFor="let chave of listaChavesPix" [value]="chave.chave">{{
              chave.chave
              }} - {{
              transformTipoPix(chave.tpChave)}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div class="col-6 col-md-6" *ngIf="tipoCobranca == 2">
        <app-input-date-month [label]="'Qual a data de início da assinatura?'" [dateMin]="todayDate" [date]="form.get('dataVencimento')"
        ></app-input-date-month>
      </div> -->
      <div class="col-6 col-md-6" *ngIf="tipoCobranca == 2">
        <app-input-date-month [label]="'Qual a data de fim da assinatura?'"
          [dateMin]="form.get('dataVencimento').value._d ? form.get('dataVencimento').value._d : form.get('dataVencimento').value"
          [date]="form.get('fimAssinatura')"></app-input-date-month>
      </div>
      <div class="col-11 col11-icone" *ngIf="tipoCobranca != 4">
        <mat-form-field appearance="outline">
          <mat-label>Qual a descrição da cobrança?</mat-label>
          <textarea matInput formControlName="mensagem" rows="2" maxlength="100" required></textarea>
          <mat-error>{{ getErrorMessage(form, "mensagem") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-11 col11-icone" *ngIf="tipoCobranca == 4">
        <mat-form-field appearance="outline">
          <mat-label>Qual a descrição da cobrança?</mat-label>
          <textarea matInput formControlName="descricaoPix" rows="2" maxlength="100"></textarea>
        </mat-form-field>
      </div>

      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon"></i>
      </div>
    </div>
  </div>

  <div class="box-body opcoes" *ngIf="!isPix">
    <div class="row">
      <div class="col-5 col-md-5" style="padding-right: 0">
        <p class="principal-title">Opções de multa e juros</p>
      </div>
      <div class="col-1 col-md-1">
        <mat-slide-toggle formControlName="aplicarMulta" (change)="controleMulta($event)"
          [disabled]="form.get('metodoPagamento').value === 'boletoPix' || isPix">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="col-12">
      <p>
        Aqui você escolhe se aceitará pagamentos após a data de vencimento
        estabelecida e escolhe multas e juros para estes recebimentos atrasados.
      </p>
    </div>

    <div class="row espaco-row" *ngIf="form.get('aplicarMulta').value === true">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Qual a multa pelo pagamento atrasado?</mat-label>
          <input matInput type="text" formControlName="multa" (keyup)="troca($event)" mask="percent" />
          <mat-error>{{ getErrorMessage(form, "multa") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon"
          matTooltip="2% é o valor máximo permitido por lei. A multa será somada ao valor de cada parcela da cobrança após a data de vencimento."></i>
      </div>
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Quais os juros para pagamento atrasado?</mat-label>
          <input matInput type="text" formControlName="juros" (keyup)="troca($event)" mask="percent" />
          <mat-error>{{ getErrorMessage(form, "juros") }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon"
          matTooltip="1% é o valor máximo permitido por lei. Os juros são acumulados e serão somados diariamente ao valor de cada parcela da cobrança."></i>
      </div>
    </div>

    <div class="row" *ngIf="form.get('aplicarMulta').value === true">
      <div class="col-4 col-md-4">
        <p class="super-title" style="font-size: 1em !important">
          Você deseja cancelar a cobrança automaticamente após o vencimento?
        </p>
      </div>
      <div class="col-1 col-md-1">
        <mat-slide-toggle formControlName="pagamentoAposVencimento" (change)="controleDiasDeBaixa($event)">
        </mat-slide-toggle>
      </div>
    </div>

    <div class="row espaco-row" *ngIf="
        form.get('pagamentoAposVencimento').value === true &&
        form.get('aplicarMulta').value === true
      ">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Em quantos dias após o vencimento a cobrança deve ser cancelada?</mat-label>
          <input matInput type="text" formControlName="quantidadeDiasBaixa" min="1" maxlength="2"
            (ngModelChange)="component.buscaDataBaixa()" />
          <mat-error>{{
            getErrorMessage(form, "quantidadeDiasBaixa")
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-1 col1-icone">
        <i class="fas fa-info-circle info-icon"
          matTooltip="Determine por quantos dias após a data de vencimento está fatura ainda poderá ser paga (baixa automática)."></i>
      </div>
    </div>
  </div>

  <div class="box-body opcoes"
    *ngIf="(form.get('metodoPagamento').value!=='cartaoCredito' && !isPix) && env?.modulos?.desconto?.opcoesDesconto">
    <div class="row">
      <div class="col-5 col-md-5">
        <p class="principal-title">Opções de desconto</p>
        <mat-error style="font-size: 12px; margin-bottom: 10px">{{ getErrorMessage(form, "aplicarDesconto") }}
        </mat-error>
      </div>
      <div class="col-1 col-md-1">
        <mat-slide-toggle formControlName="aplicarDesconto" (change)="controleDesconto($event)"
          [disabled]="form.get('metodoPagamento').value === 'boletoPix' || isVencida">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="col-12">
      <p>
        Descontos são bons incentivos para que seus clientes paguem as cobranças
        antes da data de vencimento.
      </p>
    </div>

    <div *ngIf="form.get('aplicarDesconto').value === true">
      <div *ngFor="let desconto of form.get('descontos').controls, let i=index" [formGroup]="desconto"
        class="row espaco-row">
        <div class="col-4 col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Qual o prazo máximo para desconto?</mat-label>
            <input matInput [min]="todayDate" [max]="form.get('dataVencimento').value" [matDatepicker]="picker2"
              formControlName="dataLimiteDesconto">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error>{{ getErrorMessage(desconto, "dataLimiteDesconto") }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-4 col-md-4">
          <mat-form-field appearance="outline">
            <mat-label>Qual o desconto percentual?</mat-label>
            <input matInput type="text" formControlName="desconto" (keyup)="onChangeDesconto($event, i)" mask="percent"
              suffix="%" />
            <mat-error>{{ getErrorMessage(desconto, "desconto") }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3 col-md-3">
          <mat-form-field appearance="outline">
            <mat-label>Qual o valor do desconto?</mat-label>
            <input matInput type="text" formControlName="valorDesconto" currencyMask
            [readonly]="true" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
            <mat-error>{{ getErrorMessage(desconto, "valorDesconto") }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-1 col1-icone">
          <i class="fas fa-info-circle info-icon" matTooltip="O desconto vale para cada parcela da cobrança."></i>
        </div>
        <div *ngIf="i >= 0">
          <i class="fas fa-trash icones" title="Excluir" (click)="removeDesconto(i)" style="color:red;"></i>
        </div>
      </div>
    </div>
    <div
      *ngIf="form.get('descontos').length < 3 && form.get('aplicarDesconto').value === true && tipoConta !== 'contaDigitalS'"
      class="col-6 col-md-6 add" style="padding-top: 10px; padding-bottom: 0px;">
      <span (click)="addDesconto()" style="padding-left: 10px;">+ adicionar novo desconto</span>
    </div>
  </div>

  <div class="box-body opcoes" *ngIf="!isPix">
    <div class="row">
      <div class="col-5 col-md-5" style="padding-right: 0">
        <p class="principal-title">Opções da cobrança</p>
      </div>
      <div class="col-1 col-md-1">
        <mat-slide-toggle formControlName="incluirNumeroDocumentoEspecie"
          (change)="controleNumeroDocumentoEspecie($event)"
          [disabled]="tipoConta === 'contaDigitalS'"></mat-slide-toggle>
      </div>
    </div>
    <div class="col-12" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === false">
      <p>
        Aqui você escolhe a carteira e se irá incluir o número do documento e o código da espécie na emissão da cobrança.
        <br> A não seleção deste campo emitirá a cobrança na carteira 2.
      </p>
    </div>
    <div class="col-12" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true">
      <p>
        Aqui você escolhe a carteira a ser utilizada na emissão da cobrança
      </p>
    </div>
    <div class="row espaco-row" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Qual a carteira?</mat-label>
          <mat-select *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true" formControlName="carteira">
            <mat-option *ngFor="let carteira of listaCarteiras" [value]="carteira.carteira">
              {{ carteira.descricao }}
            </mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "carteira") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12"
      *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true && form.get('carteira').value !== 'carteira2'">
      <p>
        Aqui você escolhe o Nosso Número a ser utilizado na emissão da cobrança
      </p>
    </div>
    <div class="row espaco-row"
      *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true && form.get('carteira').value !== 'carteira2'">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Qual o Nosso Número?</mat-label>
          <input matInput type="text" formControlName="nossoNumero" maxlength="12" />
          <mat-error>{{ getErrorMessage(form, "nossoNumero") }}</mat-error>
        </mat-form-field>
      </div>
      <div style="padding-left: 24px; padding-top: 4px;">
        <button class="btn btn-block btn-secundario button-consulta" (click)="consultarAdicionarIntervalos(false, true)">
          <span>Consultar intervalos</span>
        </button>
      </div>
    </div>
    <div class="col-12" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true">
      <p>
        Aqui você escolhe se irá incluir o número do documento na emissão da cobrança
      </p>
    </div>
    <div class="row espaco-row" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Qual o número do documento?</mat-label>
          <input matInput type="text" formControlName="numeroDocumento" maxlength="15" />
          <mat-error>{{ getErrorMessage(form, "numeroDocumento") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true">
      <p>
        Aqui você escolhe se irá incluir o código da espécie na emissão da cobrança
      </p>
    </div>
    <div class="row espaco-row" *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true">
      <div class="col-5 col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Qual a espécie da cobrança?</mat-label>
          <mat-select *ngIf="form.get('incluirNumeroDocumentoEspecie').value === true" formControlName="especie">
            <mat-option *ngFor="let especie of listaEspecie" [value]="especie.codigo">
              {{ especie.descricao }}
            </mat-option>
          </mat-select>
          <mat-error>{{ getErrorMessage(form, "especie") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div id="protesto">
    <div class="box-body opcoes"
      *ngIf="env.modulos.cobranca.protesto === true && configEmpresaLogada?.enviarParaProtesto === true">
      <div class="row">
        <div class="col-5 col-md-5" style="padding-right: 0">
          <p class="principal-title">Opções do protesto</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="incluirProtesto" (change)="controleProtesto($event)"
            [disabled]="tipoConta === 'contaDigitalS' || !configEmpresaLogada.enviarParaProtesto"></mat-slide-toggle>
        </div>
      </div>
      <div class="col-12" *ngIf="form.get('incluirProtesto').value === false">
        <p>
          Aqui você escolhe se irá incluir as informações para emissão do protesto da cobrança após o vencimento.
        </p>
      </div>
      <div class="col-12" *ngIf="form.get('incluirProtesto').value === true">
        <p>
          Aqui você indica se existe o Aceite da cobrança. O Aceite é o ato pelo qual o devedor declara que aceita pagar
          o
          título de crédito, podendo ser acionado judicialmente caso a obrigação de pagamento não seja cumprida.
        </p>
      </div>
      <div class="row espaco-row" *ngIf="form.get('incluirProtesto').value === true">
        <div class="col-5 col-md-5">
          <mat-form-field appearance="outline">
            <mat-label>Existe o aceite da cobrança?</mat-label>
            <mat-select *ngIf="form.get('incluirProtesto').value === true" formControlName="aceite">
              <mat-option *ngFor="let aceite of listaAceite" [value]="aceite.codigo">
                {{ aceite.descricao }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getErrorMessage(form, "aceite") }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12"
        *ngIf="form.get('incluirProtesto').value === true">
        <p>
          Aqui você define a Autorização do Repasse de Custas do Protesto para o devedor. Caso não seja autorizado, o
          repasse das custas será feito para o emissor da cobrança.
        </p>
      </div>
      <div class="row espaco-row"
        *ngIf="form.get('incluirProtesto').value === true">
        <div class="col-5 col-md-5">
          <mat-form-field appearance="outline">
            <mat-label>Existe a autorização de repasse de custos do protesto?</mat-label>
            <mat-select *ngIf="form.get('incluirProtesto').value === true" formControlName="autorizacaoRepasse">
              <mat-option *ngFor="let autorizacaoRepasse of listaAutorizacaoRepasse"
                [value]="autorizacaoRepasse.codigo">
                {{ autorizacaoRepasse.descricao }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getErrorMessage(form, "autorizacaoRepasse") }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12" *ngIf="form.get('incluirProtesto').value === true">
        <p>
          Aqui você define o tipo do protesto.
        </p>
      </div>
      <div class="row espaco-row" *ngIf="form.get('incluirProtesto').value === true">
        <div class="col-5 col-md-5">
          <mat-form-field appearance="outline">
            <mat-label>Qual o tipo de protesto?</mat-label>
            <mat-select *ngIf="form.get('incluirProtesto').value === true" formControlName="tipoProtesto">
              <mat-option *ngFor="let tipoProtesto of listaTipoProtesto" [value]="tipoProtesto.codigo">
                {{ tipoProtesto.descricao }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getErrorMessage(form, "tipoProtesto") }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12" *ngIf="form.get('incluirProtesto').value === true">
        <p>
          Aqui você define o endosso para o repasse do protesto.
        </p>
      </div>
      <div class="row espaco-row" *ngIf="form.get('incluirProtesto').value === true">
        <div class="col-5 col-md-5">
          <mat-form-field appearance="outline">
            <mat-label>Qual o endosso do protesto?</mat-label>
            <mat-select *ngIf="form.get('incluirProtesto').value === true" formControlName="endossoProtesto">
              <mat-option *ngFor="let endossoProtesto of listaEndossoProtesto" [value]="endossoProtesto.codigo">
                {{ endossoProtesto.descricao }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getErrorMessage(form, "diasDeProtesto") }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12" *ngIf="form.get('incluirProtesto').value === true">
        <p>
          Aqui você define a quantidade de dias para emissão do protesto após a data de vencimento.
        </p>
      </div>
      <div class="row espaco-row" *ngIf="form.get('incluirProtesto').value === true">
        <div class="col-5 col-md-5">
          <mat-form-field appearance="outline">
            <mat-label>Quantidade de dias para emissão do protesto</mat-label>
            <input matInput formControlName="diasDeProtesto" prefix="D+" mask="0?000">
          </mat-form-field>
          <mat-error>{{ getErrorMessage(form, "diasDeProtesto") }}</mat-error>
        </div>
      </div>
    </div>
  </div>

  <div class="box-body opcoes" *ngIf="env?.modulos?.arquivo?.anexarArquivo && !isPix">
    <div class="row">
      <div class="col-5 col-md-5">
        <p class="principal-title">Opções de anexar arquivos</p>
      </div>
      <div class="col-1 col-md-1">
        <mat-slide-toggle formControlName="enviarArquivos"> </mat-slide-toggle>
      </div>
    </div>
    <div class="col-12">
      <p>
        Aqui você pode incluir documentos que serão enviados com sua fatura.
      </p>
    </div>

    <div class="col-12 espaco-row"></div>
  </div>

  <div class="box-body opcoes" *ngIf="env?.modulos?.fiscal?.notaFiscal && !isPix">
    <div class="row">
      <div class="col-5 col-md-5">
        <p class="principal-title">Opções de emitir nota fiscal</p>
      </div>
      <div class="col-1 col-md-1">
        <mat-slide-toggle formControlName="emitirNotaFiscal">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="col-12">
      <p>
        Aqui voce tem opção de emitir nota fiscal que será disponilizada para
        seu cliente na fatura.
      </p>
    </div>

    <div class="col-12 espaco-row"></div>
  </div>

  <div *ngIf="form.get('metodoPagamento').value === 'pixDinamico'">
    <div class="box-body opcoes" *ngIf="isPix">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Dados do pagador</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="controleDocumentoPagadorPix" (change)="controleDocumentoPagadorPix($event)"
            [checked]="form.get('controleDocumentoPagadorPix')"
            [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você insere os dados da pessoa que vai pagar o QR Code.
        </p>
      </div>
      <div class="col-3 col-md-3" *ngIf="form.get('controleDocumentoPagadorPix').value === true">
        <mat-form-field appearance="outline">
          <mat-label>Qual CPF ou CNPJ?</mat-label>
          <input matInput type="text" formControlName="documentoPagadorPix" [mask]="CPF_CNPJ" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Qual o nome da pessoa que vai pagar esse QR Code?</mat-label>
          <input matInput type="text" formControlName="nomePagadorPix">
        </mat-form-field>
      </div>
    </div>

    <div class="box-body opcoes" *ngIf="isPix && !desablitaQrExpiracao()">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Opções de expiração</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="controleExpiracaoQr" (change)="controleExpiracaoQr($event)"
            [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você escolhe a data de expiração do seu QR Code.
        </p>
      </div>
      <div *ngIf="form.get('controleExpiracaoQr').value === true">
        <div>
          <div class="col-4 col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Qual a data de expiração do QR Code?</mat-label>
              <input matInput [min]="todayDate" [max]="dataMaxima" [matDatepicker]="picker2"
                formControlName="expiracaoQR">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="box-body opcoes" *ngIf="isPix && !desabilitaAlteracaoValor()">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Permitir alteração no valor</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="controleAlteracaoValor" (change)="controleAlteracaoValor($event)"
            [disabled]="form.get('metodoPagamento').value != 'pixDinamico'"
            [checked]="form.get('controleAlteracaoValor')">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você decide se o valor do QR code pode ser alterado.
        </p>
      </div>
    </div>

    <div class="box-body opcoes" *ngIf="isPix && form.get('configuracoesVencimento').value == false">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Permitir reutilização</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="reutilizavel" (change)="controleReutilizavel($event)"
            [disabled]="form.get('metodoPagamento').value != 'pixDinamico'" [checked]="form.get('reutilizavel')">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você decide se o valor do QR code pode ser reutilizado.
        </p>
      </div>
    </div>

    <div class="box-body opcoes" *ngIf="isPix && !desabilitaConfigVencimento()">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Configurações de vencimento</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="configuracoesVencimento" (change)="controleConfiguracoesVencimento($event)"
            [checked]="form.get('configuracoesVencimento')"
            [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você escolhe a data de vencimento e suas configurações.
        </p>
      </div>
      <div class="row espaco-row ng-star-inserted" *ngIf="form.get('configuracoesVencimento').value === true">
        <div style="width: 280px;">
          <mat-form-field appearance="outline" style="width: 280px;">
            <mat-label>Selecione a data de vencimento</mat-label>
            <input matInput [min]="todayDate" [max]="dataMaxima" [matDatepicker]="picker2" formControlName="dtVenc">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 280px;">
            <mat-label>Deseja aplicar juros?</mat-label>
            <input matInput type="text" formControlName="jurosPix" mask="separator.2" thousandSeparator="."
              suffix="%" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 280px;">
            <mat-label>Deseja aplicar multa?</mat-label>
            <input matInput type="text" formControlName="multaPix" mask="separator.2" thousandSeparator="."
              suffix="%" />
          </mat-form-field>
        </div>

        <div style="width: 280px; margin-left: 58px;">
          <mat-form-field appearance="outline" style="width: 280px;">
            <mat-label>Deseja aplicar desconto?</mat-label>
            <input matInput type="text" formControlName="descontoPix" mask="separator.2" thousandSeparator="."
              prefix="R$ " />
            <mat-error *ngIf="form.get('descontoPix').hasError('maxValorPixExceeded')">
              A soma dos descontos excede o valor do QR Code.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 280px; margin-top: 20px;">
            <mat-label>Deseja aplicar abatimento?</mat-label>
            <input matInput type="text" formControlName="abatimentoPix" mask="separator.2" thousandSeparator="."
              prefix="R$ " />
            <mat-error *ngIf="form.get('abatimentoPix').hasError('maxValorPixExceeded')">
              A soma dos descontos excede o valor do QR Code.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- <div class="box-body opcoes" *ngIf="isPix && !desabilitaAltTroco()">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Modalidade Pix troco</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="controleValorTroco" (change)="controleValorTroco($event)"
            [checked]="form.get('controleValorTroco')" [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você decide se o QR Code pode ter um valor de troco.
        </p>
      </div>
      <div class="col-3 col-md-3" *ngIf="form.get('controleValorTroco').value === true">
        <mat-form-field appearance="outline">
          <mat-label>Qual o valor do troco?</mat-label>
          <input matInput type="text" formControlName="valorTroco" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
          <i class="fas fa-info-circle info-icon"
            matTooltip="O valor do troco substitui o valor setado anteriormente."></i>
        </mat-form-field>
        <div class="col-1 col-md-1">
          <span style="width: 248px;display: flex; font-size: 14px;align-items: baseline;">Permitir alteração no valor?
            <mat-slide-toggle formControlName="controleModalidadeAltTroco" (change)="controleModalidadeAltTroco($event)"
              style="margin-left: 15px;" [checked]="form.get('modalidadeAltTroco')"
              [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
            </mat-slide-toggle></span>
        </div>
      </div>
    </div>

    <div class="box-body opcoes" *ngIf="isPix && !desabilitaAltSaque()">
      <div class="row">
        <div class="col-5 col-md-5">
          <p class="principal-title">Modalidade Pix saque</p>
        </div>
        <div class="col-1 col-md-1">
          <mat-slide-toggle formControlName="controleModalidadeAltSaque" (change)="controleModalidadeAltSaque($event)"
            [checked]="form.get('controleModalidadeAltSaque')"
            [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-12">
        <p>
          Aqui você decide se o QR Code pode ter um valor de saque.
        </p>
      </div>
      <div class="col-3 col-md-3" *ngIf="form.get('controleModalidadeAltSaque').value === true">
        <mat-form-field appearance="outline">
          <mat-label>Qual o valor do saque?</mat-label>
          <input matInput type="text" formControlName="valorSaque" mask="separator.2" thousandSeparator="."
            prefix="R$ " />
        </mat-form-field>
        <div class="col-1 col-md-1">
          <span style="width: 248px;display: flex; font-size: 14px;align-items: baseline;">Permitir alteração no valor?
            <mat-slide-toggle style="margin-left: 15px;" (checked)="controleCheckboxSaque($event)"
              [disabled]="form.get('metodoPagamento').value != 'pixDinamico'">
            </mat-slide-toggle></span>
        </div>
      </div>
    </div> -->
  </div>
</div>