import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltroPagamentoDTO } from '@model/filtro/filtro-pagamento-dto.model';
import { Observable } from 'rxjs';
import { FiltroService } from './filtro.service';
import { TokenDTO } from '../model/sistema/token-dto.model';
import { PageResponse } from '../model/page-response.model';
import { ArquivoDTO } from '../model/sistema/arquivo-dto.model';
import { PagamentoLoteSistemaDTO } from '../model/financeiro/pagamento/pagamento-lote-sistema-dto.model';
import { SimpleFilter, SimpleFilterUtils } from '../model/util/simple-filter.model';
import { ArrecadacaoItauDTO } from '../model/financeiro/pagamento/arrecadacao-itau-dto.model';
import { PagamentoLoteSistemaItemDTO } from '../model/financeiro/pagamento/pagamento-lote-sistema-item-dto.model';

type ModeracaoPagamentoRepresentante = {
  usuarioId: number,
  pagamentoId: number,
  status: string,
  dataModeracao?: Date,
  nomeUsuario?: string,
  emailUsuario?: string,
  token?: any,
  tipo?: string
}

@Injectable({
  providedIn: 'root'
})
export class PagamentoEndpointService {
  constructor(
    private client: HttpClient,
    private filtroService: FiltroService
  ) { }

  getInfoLinhaDigitavel(codigoBarras): Observable<any> {
    const url = '/pagamento-contas/interpretar-linha-digitavel?ld=' + codigoBarras;
    return this.client.get(url);
  }

  novoPagamento(pagamentoData): Observable<any> {
    const url = '/pagamento-contas/novo-pagamento';
    return this.client.post(url, pagamentoData);
  }

  getAllPagamentos(filtro: FiltroPagamentoDTO): Observable<any> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosPagamento(filtro);
    
    const url = '/pagamento-contas/lista-contas-pagar';
    return this.client.get(url, { params });
  }
  
  listarEmAberto(filtro: FiltroPagamentoDTO): Observable<any> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosPagamento(filtro);
    
    const url = '/pagamento-contas/lista-em-aberto';
    return this.client.get(url, { params });
  }

  getComprovantePagamento(idConta: number , tipo: number): Observable<any> {
    return this.client.get(`/pagamento-contas/ver-comprovante/${tipo}/${idConta}`);
  }

  getComprovantePagamentoTributo(idConta: number,): Observable<any> {
    return this.client.get(`/pagamento-contas/ver-comprovante-tributo/${idConta}`);
  }

  pagamentoCartao(idCobranca, cartaoData): Observable<any> {
    const url = '/cartao/pagamento-cartao/' + idCobranca;
    return this.client.post(url, cartaoData);
  }

  getCodAutenticacao(idPagamento): Observable<any> {
    const url = '/transferencia/gerar-codigo-autenticacao';
    return this.client.patch(url, { id_pagamento: idPagamento });
  }

  listarAlcada(filtro: FiltroPagamentoDTO): Observable<any> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosPagamento(filtro);
    const url = '/pagamento-contas/listar-alcada';
    return this.client.get(url, { params });
  }

  aprovarAlcada(alcada, token: TokenDTO): Observable<any> {
    const base = alcada.arrecadacao ? 'pagamento-arrecadacao' : 'pagamento-contas'
    const url = `/${base}/aprovar-alcada/${alcada.pagamento.id}`;
    return this.client.post(url, token)
  }

  reprovarAlcada(alcada): Observable<any> {
    const base = alcada.arrecadacao ? 'pagamento-arrecadacao' : 'pagamento-contas'
    const url = `/${base}/reprovar-alcada/${alcada.pagamento.id}`;
    return this.client.post(url, null)
  }

  consultarArrecadacao(codigoBarras): Observable<ArrecadacaoItauDTO> {
    const url = '/pagamento-arrecadacao/consultar-arrecadacao?codigoBarras=' + codigoBarras;
    return this.client.get(url);
  }

  pagarArrecadacao(pagamentoData): Observable<any> {
    const url = '/pagamento-arrecadacao/pagar-arrecadacao';
    return this.client.post(url, pagamentoData);
  }

  getArrecadacao(id: number,): Observable<any> {
    return this.client.get(`/pagamento-arrecadacao/${id}`);
  }

  gerarComprovantePdf(pagamentoId: number): Observable<string> {
    const url = `/pagamento-contas/gerar-comprovante-pdf/${pagamentoId}`;
    return this.client.get<string>(url);
  }

  gerarComprovantePdfArrecadacao(pagamentoId: number): Observable<string> {
    const url = `/pagamento-arrecadacao/gerar-comprovante-pdf/${pagamentoId}`;
    return this.client.get<string>(url);
  }

  moderacaoRepresentante(moderacao: ModeracaoPagamentoRepresentante, tipo: string){
    const endpoint = tipo === 'boleto' ? 'pagamento-contas' : 'pagamento-arrecadacao'
    const url = `/${endpoint}/moderacao-representante`
    return this.client.post(url, moderacao)
  }

  moderarPagamentosEmLote(pagamentos: number[], status: string, token: TokenDTO): Observable<any> { 
    const url = "/pagamento-contas/moderar-pagamentos-em-lote";
    return this.client.post(url, { pagamentos, status, token });
  }

  loteModeloSistema() {
    return this.client.get<ArquivoDTO>(`/pagamento/lote/modelo-sistema`);
  }

  versaoModeloSistema() {
    return this.client.get<string>(`/pagamento/lote/versao-sistema`);
  }

  validarLoteSistema(arquivo: ArquivoDTO) {
    return this.client.post<PagamentoLoteSistemaDTO>(`/pagamento/lote/validar-sistema`, arquivo);
  }

  consultarBoletoSistema(item: PagamentoLoteSistemaItemDTO) {
    return this.client.post<PagamentoLoteSistemaItemDTO>(`/pagamento/lote/consultar-boleto-sistema`, item);
  }

  validacaoFinalLoteSistema(item: PagamentoLoteSistemaDTO) {
    return this.client.post<PagamentoLoteSistemaDTO>(`/pagamento/lote/validacao-final-sistema`, item);
  }

  enviarLoteSistema(arquivo: PagamentoLoteSistemaDTO, token: string, tipoToken: string) {
    return this.client.post<number>(`/pagamento/lote/enviar-sistema`, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  downloadFalhasSistema(loteId: number) {
    return this.client.get<PagamentoLoteSistemaDTO>(`/pagamento/lote/download-falha-sistema`, {
      params: new HttpParams()
        .append('loteId', `${loteId}`)
    });
  }

  listarLoteSistema(args?: SimpleFilter) {
    const params = SimpleFilterUtils.toHttpUtils(args);
    return this.client.get<PageResponse<PagamentoLoteSistemaDTO>>(`/pagamento/lote/listar-sistema`, {
      params
    });
  }

  exportarComprovantesLoteSistema(loteId: number, emails): Observable<string>{
    const url = `/pagamento/lote/exportar-comprovantes-sistema/${loteId}`;
    let params = new HttpParams();
    params = params.append("emails", emails);
    
    return this.client.get<string>(url, { params });
  }

  listarItemSistema(
    page: number,
    size: number,
    loteId: string,
    order?: string,
    query?: string,
  ): Observable<PageResponse<any>> {
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    params = params.append('loteId', `${loteId}`);
    order ? params = params.append('order', `${order}`) : null;
    query ? params = params.append('query', `${query}`) : null;

    const url = "/pagamento/lote/listar-item-sistema";
    return this.client.get(url, { params });
  }
}
