import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PublicidadeEndpointService } from '@project/src/app/service/publicidade-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adiciona-imagem',
  templateUrl: './adiciona-imagem.component.html',
  styleUrls: ['./adiciona-imagem.component.less']
})
export class AdicionaImagemComponent implements OnInit {
  form: FormGroup;
  publicidade = null;
  getBase64: string = null;
  getNameFile: string = null;
  getTypeFile: string = null;

  atualiza: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<AdicionaImagemComponent>,
    public formBuilder: FormBuilder,
    public publicidadeService: PublicidadeEndpointService,
  ) { }

  ngOnInit() {
    this.formPublicidade();
  }

  formPublicidade() {
    this.form = this.formBuilder.group({});
    this.form.addControl('publicidade', new FormControl(null));
    this.form.addControl('publicidadePath', new FormControl(''));
    this.form.addControl('href', new FormControl(''));
  }

  publicidadeNull() {
    this.publicidade = null;
  }

  capturarBase64(event) {
    this.getBase64 = event;
  }

  capturarNomeFile(event) {
    this.getNameFile = event;
  }

  capturarTypeFile(event) {
    this.getTypeFile = event;
  }

  onSelectFile() {
    const base64 = this.getBase64.substring(
      this.getBase64.lastIndexOf(',') + 1,
      this.getBase64.length
    );
    const publicidadeData = {
      nome: this.getNameFile,
      tipo: this.getTypeFile,
      s3key: null,
      base64: base64,
      href: this.form.get('href').value,
      modalidade: 'FATURA'
    };

    this.form.get('publicidade').setValue(publicidadeData);

    this.salvarPublicidade();
  }

  salvarPublicidade() {
    const publicidade = this.form.get('publicidade').value;

    if (publicidade) {
      this.publicidadeService.salvarPublicidade(publicidade).subscribe(
        (res) => {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Publicidade salva com sucesso',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            this.dialogRef.close();
          });
        },
        (err: any) => {
          Swal.fire({
            title: 'Erro!',
            text: 'Erro ao salvar publicidade',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            this.dialogRef.close();
          });
        }
      );
    }
  }

  fecha() {
    this.dialogRef.close();
  }

}
