import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirmar-editar-cobranca',
  templateUrl: './confirmar-editar-cobranca.component.html',
  styleUrls: ['./confirmar-editar-cobranca.component.less'],
})
export class ConfirmarEditarCobrancaComponent implements OnInit, OnDestroy {
  processando = false;
  form: FormGroup;
  cobrancaData: any = {};

  @Output()
  public atualizar: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ConfirmarEditarCobrancaComponent>,
    private cobrancaService: CobrancaEndpointService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      notificarPagador: new FormControl(true),
      dms: new FormControl(false),
      key: new FormControl(''),
    });

    if (this.dataDialog.tipoCobranca !== 'avulsa') {
      this.form.get('key').setValidators([this.validacaoKey(this.form)]);
      this.form.get('dms').setValidators([this.validacaoKey(this.form)]);
    }

    this.cobrancaData = this.dataDialog.atualizarData;
  }

  validacaoKey(form: FormGroup) {
    return (control: AbstractControl) => {
      const key = form.get('key').value,
        numeroParcelas = this.dataDialog.numeroParcelas,
        excluirTodos = form.get('dms').value;

      if (key !== 'editar ' + numeroParcelas && excluirTodos === true) {
        return { naoconfirmado: true } as ValidationErrors;
      }

      this.form.get('key').setErrors(null);
      this.form.get('dms').setErrors(null);
    };
  }

  ngOnDestroy() {}

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('naoconfirmado')
        ? 'Entrada inválida'
        : '';
    }
  }

  fecha(): void {
    this.dialogRef.close();
  }

  notificar() {}

  editar() {
    this.processando = true;
    document
    .getElementById('btn_atualizar')
    .setAttribute('disabled', 'disabled');
    
    if (!this.dataDialog.isEdicaoLote) {
      this.cobrancaData.notificar_pagador = this.form.get('notificarPagador').value;
      this.cobrancaData.atualizar_demais_cobrancas = this.form.get('dms').value;
      // edição avulsa
      this.cobrancaService
        .editarCobranca(this.cobrancaData)
        .pipe(untilDestroyed(this))
        .subscribe(
          (response) => {
            this.processando = false;
            Swal.fire({
              title: 'Sucesso',
              text: 'Cobrança atualizada com sucesso',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              if (this.dataDialog) {
                if (this.dataDialog.editouCobranca === false) {
                  this.dialogRef.close({
                    editouCobranca: true,
                    idCobranca: response,
                  });
                }
              } else {
                this.dialogRef.close();
              }
            });
          },
          (error) => {
            console.error(error);
            Swal.fire({
              title: 'Erro',
              text: error.error,
              icon: 'error',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close();
            });
          }
        );
    } else {
      this.processando = true;
      this.cobrancaData.forEach(cobranca => {
        cobranca.notificar_pagador = this.form.get('notificarPagador').value; 
        cobranca.atualizar_demais_cobrancas = this.form.get('dms').value;
      });
      this.cobrancaService
        .editarCobrancaLote(this.cobrancaData)
        .subscribe(
          () => {
            this.processando = false;
            Swal.fire({
              title: 'Solicitação enviada',
              text: 'O feedback chegará nas notificações em alguns instantes.',
              icon: 'info',
              confirmButtonText: 'OK',
            }).then(() => {
              if (this.dataDialog) {
                if (this.dataDialog.editouCobranca === false) {
                  this.dialogRef.close({
                    editouCobranca: true,
                  });
                }
              } else {
                this.dialogRef.close();
              }
            });
          },
          (error) => {
            this.processando = false;
            console.error(error);
            Swal.fire({
              title: 'Erro',
              text: 'Ocorreu um erro ao tentar enviar a solicitação de atualização.',
              icon: 'error',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close();
            });
          }
        );
    }
  }
}
