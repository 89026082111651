import { ArquivoEmpresaDTO } from './../model/pessoa/arquivo-empresa-dto.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ContasDTO } from '@model/contato/contas-dto.model';
import { FiltroRootUsuarioDTO } from '@model/filtro/filtro-root-usuario-dto.model';
import { ListaContatosDTO } from '@model/lista-contatos-dto.model';
import { PageResponse } from '@model/page-response.model';
import { UsuarioDTO } from '@model/usuario/usuario-dto.model';
import { Observable } from 'rxjs';
import { ArquivoRepresentanteEmpresaDTO } from '../model/pessoa/arquivo-representante-empresa-dto.model';
import { RepresentanteEmpresaDTO } from '../model/pessoa/representante-empresa-dto.model';
import { AnyARecord } from 'dns';
import moment from 'moment';
import { ArquivoDTO } from '../model/sistema/arquivo-dto.model';
import { TransferenciaLoteDTO } from '../model/financeiro/transferencia/transferencia-lote-dto.model';

export interface IPessoaService {
  cadastrarPessoaFisica(pessoaData: any);
  cadastrarPessoaJuridica(pessoaData: any);
  cadPrimeirosPassos(data: any);
  atualizarPessoaFisica(pessoaData: any);
  atualizarDocumentacao(pessoaData: ArquivoEmpresaDTO[]);
  atualizarRepresentantes(pessoaData: RepresentanteEmpresaDTO[]);
  adicionarRepresentante(representante: RepresentanteEmpresaDTO, empresaId: number);
  apagarRepresentante(representante: RepresentanteEmpresaDTO, empresaId: number);
  atualizarPessoaJuridica(pessoaData: any);
  deletePessoa(id_pessoa: any);
  getPessoa(id_pessoa: any);
  getAllPessoa(arg?: {
    q?: string;
    order?: string;
    page?: number;
    size?: number;
    favorito?: {
      choice?: boolean
    };
    inadimplente?: {
      choice?: boolean
    };
  });
  getLogotipo(id_logotipo: any);
  getContasBancarias(filtro);
  getAllPessoaRoot(filtro: FiltroRootUsuarioDTO);
  moderarPessoa(gerenciarData);
  getTodasPessoa(arg?: {
    q?: string;
    order?: string;
    page?: number;
    size?: number;
    data_criacao?: {
      de?: string,
      ate?: string
    },
    favorito?: {
      choice?: boolean
    };
    inadimplente?: {
      choice?: boolean
    };
  });
  importacaoModelo();
  versaoModelo();
  validarImportacao(arquivo: ArquivoDTO);
  enviarImportacao(arquivo: ArquivoDTO, token: string, tipoToken: string);
  exportarPlanilhaContatos(corporacaoId: number, email?: string)
}

export class APessoaService implements IPessoaService {
  constructor(private client: HttpClient, private urlBase: string) { }

  cadastrarPessoaFisica(pessoaData: any): Observable<any> {
    pessoaData.usuario = null;
    return this.client.post(`${this.urlBase}/pessoa-fisica`, pessoaData);
  }

  cadastrarPessoaJuridica(pessoaData: any): Observable<any> {
    pessoaData.usuario = null;
    return this.client.post(`${this.urlBase}/pessoa-juridica`, pessoaData);
  }

  cadPrimeirosPassos(data: any): Observable<any> {
    return this.client.post(`${this.urlBase}/primeiros-passos`, data);
  }

  atualizarPessoaFisica(pessoaData: any): Observable<any> {
    return this.client.post(`${this.urlBase}/atualizar-pessoa-fisica`, pessoaData);
  }

  atualizarPessoaJuridica(pessoaData: any): Observable<any> {
    return this.client.post(`${this.urlBase}/atualizar-pessoa-juridica`, pessoaData);
  }
  atualizarDocumentacao(pessoaData: any): Observable<any> {
    return this.client.post(`${this.urlBase}/atualizar-documentacao`, pessoaData);
  }
  atualizarRepresentantes(pessoaData: RepresentanteEmpresaDTO[]): Observable<any> {
    return this.client.post(`${this.urlBase}/atualizar-representantes`, pessoaData);
  }
  adicionarRepresentante(pessoaData: RepresentanteEmpresaDTO, empresaId): Observable<any> {
    return this.client.post(`${this.urlBase}/adicionar-representantes/${empresaId}`, pessoaData);
  }
  apagarRepresentante(pessoaData: RepresentanteEmpresaDTO, empresaId): Observable<any> {
    return this.client.post(`${this.urlBase}/apagar-representantes/${empresaId}`, pessoaData);
  }
  deletePessoa(id_pessoa: any): Observable<any> {
    return this.client.delete(`${this.urlBase}/apagar/${id_pessoa}`);
  }

  getPessoa(id_pessoa: any): Observable<any> {
    return this.client.get(`${this.urlBase}/${id_pessoa}`);
  }

  getPessoaDoc(documento: string, id_corporacao: any): Observable<any> {
    documento = documento.replace(/[^0-9]/g, '');
    return this.client.get(`${this.urlBase}/find-by-doc/${documento}/${id_corporacao}`);
  }

  getContatoByContaId(contaId: number): Observable<ListaContatosDTO[]> {
    const url = `${this.urlBase}/contato-by-conta-id/${contaId}`;
    return this.client.get<ListaContatosDTO[]>(url);
  }

  getContatosByConta(conta: string): Observable<any> {
    const url = `${this.urlBase}/contatos-by-conta/${conta}`;
    return this.client.get(url);
  }

  getAllPessoa(arg: {
    q?: string,
    order?: string,
    page?: number,
    size?: number,
    favorito?: {
      choice?: boolean,
      show?: boolean
    },
    inadimplente?: {
      choice?: boolean,
      show?: boolean
    }
  } = {}): Observable<PageResponse<ListaContatosDTO>> {
    arg = arg || {};
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    params = params.append('page', `${arg.page}`);

    if (arg.q) {
      params = params.append('q', `${arg.q}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }
    if (arg.favorito && arg.favorito.show) {
      params = params.append('is-favorito', `${arg.favorito.choice}`);
    }
    if (arg.inadimplente && arg.inadimplente.show) {
      params = params.append('is-inadimplente', `${arg.inadimplente.choice}`);
    }
    return this.client.get(`${this.urlBase}`, { params });
  }

  getLogotipo(id_logotipo: any): Observable<any> {
    const url = 'ver-logotipo?idLogo=' + id_logotipo;
    return this.client.get(`${this.urlBase}/${url}`);
  }

  // {desativadas/ativadas}
  getContasBancarias(filtro = ''): Observable<any> {
    const url = '/empresa/ver-contas?filtro=' + filtro;
    return this.client.get(url);
  }
  // ROOT -------------------------------//

  getAllPessoaRoot(filtro: any): Observable<PageResponse<UsuarioDTO>> {

    let params = new HttpParams();
    filtro = filtro || {};

    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }
    if (filtro.desativado) {
      params = params.append('desativado', `${filtro.desativado}`);
    }
    params = params.append('page', `${filtro.page}`);

    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }
    if (filtro.size) {
      params = params.append('size', `${filtro.size}`);
    }
    if (filtro.moderacao) {
      params = params.append('filter', `${filtro.moderacao}`);
    }
    const url = '/root' + this.urlBase + '/listar';
    return this.client.get(url, { params });
  }

  moderarPessoa(gerenciarData): Observable<any> {
    const url = '/root' + this.urlBase + '/gerenciar';
    return this.client.post(url, gerenciarData);
  }

  contatoContas(arg: {
    q?: string;
    order?: string;
    page?: number;
    size?: number;
  } = {}): Observable<any> {
    let params = new HttpParams();
    const {
      order = '',
      page = 0,
      q = '',
      size = 0
    } = arg;
    if (order) {
      params = params.append('order', `${order}`);
    }
    if (q) {
      params = params.append('q', `${q}`);
    }
    if (page >= 0) {
      params = params.append('page', `${page}`);
    }
    if (size >= 0) {
      params = params.append('size', `${size}`);
    }
    return this.client.get<PageResponse<ContasDTO>>(`${this.urlBase}/contas`, {
      params
    });
  }

  getTodasPessoa(arg: {
    q?: string,
    order?: string,
    page?: number,
    size?: number,
    data_criacao?: {
      de?: string,
      ate?: string
    },
    favorito?: {
      choice?: boolean,
      show?: boolean
    },
    inadimplente?: {
      choice?: boolean,
      show?: boolean
    }
  } = {}): Observable<PageResponse<ListaContatosDTO>> {
    arg = arg || {};
    let params = new HttpParams();
    if (arg.order) {
      params = params.append('order', `${arg.order}`);
    }

    params = params.append('page', `${arg.page}`);

    if (arg.q) {
      params = params.append('q', `${arg.q}`);
    }
    if (arg.size) {
      params = params.append('size', `${arg.size}`);
    }
    if (arg.favorito && arg.favorito.show) {
      params = params.append('is-favorito', `${arg.favorito.choice}`);
    }
    if (arg.inadimplente && arg.inadimplente.show) {
      params = params.append('is-inadimplente', `${arg.inadimplente.choice}`);
    }
    if (arg.data_criacao.de) {
      params = params.append('i', `${moment(arg.data_criacao.de).format('DDMMYYYY')}`);
    }
    if (arg.data_criacao.ate) {
      params = params.append('f', `${moment(arg.data_criacao.ate).format('DDMMYYYY')}`);
    }
    return this.client.get(`${this.urlBase + '/listarTodas'}`, { params });
  }

  importacaoModelo() {
    return this.client.get<ArquivoDTO>(`/contato/importacao/modelo`);
  }

  versaoModelo() {
    return this.client.get<string>(`/contato/importacao/versao`);
  }


  validarImportacao(arquivo: ArquivoDTO) {
    return this.client.post<TransferenciaLoteDTO>(`/contato/importacao/validar`, arquivo);
  }

  enviarImportacao(arquivo: ArquivoDTO, token: string, tipoToken: string) {
    return this.client.post<number>(`/contato/importacao/enviar`, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  exportarPlanilhaContatos(corporacaoId: number, email?: string){
    let params = new HttpParams();
    params = params.append("corporacaoId", `${corporacaoId}`)
    return this.client.get('/contato/exportar-planilha-contatos', { params });
  }
}

@Injectable({
  providedIn: 'root'
})
export class PessoaEmpresaService extends APessoaService {
  constructor(private httpClient: HttpClient) {
    super(httpClient, '/empresa');
  }
}

@Injectable({
  providedIn: 'root'
})
export class PessoaContatoService extends APessoaService {
  constructor(private httpClient: HttpClient) {
    super(httpClient, '/contato');
  }
}

const classTypes = {
  empresa: PessoaEmpresaService,
  contato: PessoaContatoService
};

export class PessoaServiceBuilder {
  private constructor() { }

  public static getInstance(injector: Injector, type: string): IPessoaService {
    return injector.get<IPessoaService>(classTypes[type]);
  }
}


