import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltroCobrancaDTO } from '@model/filtro/filtro-cobranca-dto.model';
import { FiltroPagamentoDTO } from '@model/filtro/filtro-pagamento-dto.model';
import { FiltroTransferenciaDTO } from '@model/filtro/filtro-transferencia-dto.model';
import moment from 'moment';
import { FiltroAlcadaPixDTO } from '../model/filtro/filtro-alcada-pix-dto.model';

@Injectable({
  providedIn: 'root'
})
export class FiltroService {
  filtroPagamento: FiltroPagamentoDTO = {};

  constructor() { }

  gerarParametrosCobranca(filtro: FiltroCobrancaDTO) {
    let params = new HttpParams()

    if(!filtro) return params;

    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }

    if (filtro.cartao && filtro.cartao.show && filtro.cartao.choice != null) {
      params = params.append('is-cartao', `${filtro.cartao.choice}`);
    }

    if (filtro.pix && filtro.pix.show && filtro.pix.choice != null) {
      params = params.append('is-pix', `${filtro.pix.choice}`);
    }

    if (filtro.boleto && filtro.boleto.show && filtro.boleto.choice != null) {
      params = params.append('is-boleto', `${filtro.boleto.choice}`);
    }

    if (filtro.excluidas && filtro.excluidas.show && filtro.excluidas.choice != null) {
      params = params.append('is-excluida', `${filtro.excluidas.choice}`);
    }

    if (filtro.vencidas && filtro.vencidas.show && filtro.vencidas.choice != null) {
      params = params.append('is-vencida', `${filtro.vencidas.choice}`);
    }

    if (filtro.pagas && filtro.pagas.show && filtro.pagas.choice != null) {
      params = params.append('is-paga', `${filtro.pagas.choice}`);
    }

    if (filtro.repasse && filtro.repasse.show && filtro.repasse.choice != null) {
      params = params.append('is-repasse', `${filtro.repasse.choice}`);
    }

    if (filtro.data) {
      if (filtro.data.emissao && filtro.data.emissao.show) {
        if (filtro.data.emissao.de) {
          params = params.append(
            'data-emissao-maior-igual',
            `${moment(filtro.data.emissao.de).format('DDMMYYYY')}`
          );
        }
        if (filtro.data.emissao.ate) {
          params = params.append(
            'data-emissao-menor-igual',
            `${moment(filtro.data.emissao.ate).format('DDMMYYYY')}`
          );
        }
      }
      if (filtro.data.vencimento && filtro.data.vencimento.show) {
        if (filtro.data.vencimento.de) {
          params = params.append(
            'data-vencimento-maior-igual',
            `${moment(filtro.data.vencimento.de).format('DDMMYYYY')}`
          );
        }
        if (filtro.data.vencimento.ate) {
          params = params.append(
            'data-vencimento-menor-igual',
            `${moment(filtro.data.vencimento.ate).format('DDMMYYYY')}`
          );
        }
      }
      if (filtro.data.pagamento && filtro.data.pagamento.show) {
        if (filtro.data.pagamento.de) {
          params = params.append(
            'data-pagamento-maior-igual',
            `${moment(filtro.data.pagamento.de).format('DDMMYYYY')}`
          );
        }
        if (filtro.data.pagamento.ate) {
          params = params.append(
            'data-pagamento-menor-igual',
            `${moment(filtro.data.pagamento.ate).format('DDMMYYYY')}`
          );
        }
      }
    }

    if (filtro.tipo) {
      params = params.append('tipo', `${filtro.tipo}`);
    }

    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }

    if (filtro.page >= 0) {
      params = params.append('page', `${filtro.page}`);
    }

    if (filtro.size >= 0) {
      params = params.append('size', `${filtro.size}`);
    }

    if (filtro.importacaoCnab && filtro.importacaoCnab.show && filtro.importacaoCnab.choice != null) {
      params = params.append('is-importacaoCnab', `${filtro.importacaoCnab.choice}`);
    }

    if (filtro.protesto && filtro.protesto.show && filtro.protesto.choice != null) {
      params = params.append('is-protesto', `${filtro.protesto.choice}`);
    }

    if (filtro.aProtestar && filtro.aProtestar.show && filtro.aProtestar.choice != null) {
      params = params.append('is-aProtestar', `${filtro.aProtestar.choice}`);
    }

    if (filtro.seuNumero && filtro.seuNumero.show && filtro.seuNumero.choice != null) {
      params = params.append('seuNumero', `${filtro.seuNumero.choice}`);
    }

    if (filtro.nossoNumero && filtro.nossoNumero.show && filtro.nossoNumero.choice != null) {
      params = params.append('nossoNumero', `${filtro.nossoNumero.choice}`);
    }

    if (filtro.idEmpresa) {
      params = params.append('idEmpresa', `${filtro.idEmpresa}`);
    }

    if (filtro.banco000 && filtro.banco000.show && filtro.banco000.choice != null) {
      params = params.append('is-conta-digitals', `${filtro.banco000.choice}`);
    }

    return params;
  }

  gerarParametrosTransferencia(filtro: FiltroTransferenciaDTO) {
    let params = new HttpParams()

    if(!filtro) return params;

    const { data } = filtro;
    if (data) {
      const { data_confirmacao, data_criacao, data_agendamento } = data;
      if (data_confirmacao && data_confirmacao.show) {
        const { de, ate } = data_confirmacao;
        if (de) {
          params = params.append(
            "data-confirmacao-maior-igual",
            `${moment(de).format("DDMMYYYY")}`
          );
        }
        if (ate) {
          params = params.append(
            "data-confirmacao-menor-igual",
            `${moment(ate).format("DDMMYYYY")}`
          );
        }
      }
      if (data_criacao && data_criacao.show) {
        const { de, ate } = data_criacao;
        if (de) {
          params = params.append(
            "data-criacao-maior-igual",
            `${moment(de).format("DDMMYYYY")}`
          );
        }
        if (ate) {
          params = params.append(
            "data-criacao-menor-igual",
            `${moment(ate).format("DDMMYYYY")}`
          );
        }
      }
      if (data_agendamento && data_agendamento.show) {
        const { de, ate } = data_agendamento;
        if (de) {
          params = params.append(
            "data-agendamento-maior-igual",
            `${moment(de).format("DDMMYYYY")}`
          );
        }
        if (ate) {
          params = params.append(
            "data-agendamento-menor-igual",
            `${moment(ate).format("DDMMYYYY")}`
          );
        }
      }
    }

    if (filtro.order) {
      params = params.append("order", `${filtro.order}`);
    }

    if (filtro.page != null) {
        params = params.append("page", `${filtro.page}`);
    }

    if (filtro.query) {
      params = params.append("q", `${filtro.query}`);
    }

    if (filtro.size) {
      params = params.append("size", `${filtro.size}`);
    }

    if (filtro.status && filtro.status.show && filtro.status.dados != null) {
      params = params.append("status", `${filtro.status.dados}`);
    }

    if (filtro.tipo && filtro.tipo.show && filtro.tipo.dados != null) {
      params = params.append("tipo", `${filtro.tipo.dados}`);
    }

    if (filtro.origem && filtro.origem.show && filtro.origem.dados != null) {
      params = params.append("origem", `${filtro.origem.dados}`);
    }

    if(filtro.idEmpresa){ 
      params = params.append("idEmpresa", `${filtro.idEmpresa}`);
    }

    return params;
  }

  gerarParametrosPagamento(filtro: FiltroPagamentoDTO) {
    let params = new HttpParams()

    if(!filtro) return params;

    if (filtro.order) {
      params = params.append('order', `${filtro.order}`);
    }

    if (filtro.page != null) {
      params = params.append('page', `${filtro.page}`);
    }

    if (filtro.query) {
      params = params.append('q', `${filtro.query}`);
    }

    if (filtro.size) {
      params = params.append('size', `${filtro.size}`);
    }

    if (filtro.data) {
      if (filtro.data.criacao && filtro.data.criacao.show) {
        if (filtro.data.criacao.de) {
          params = params.append('data-criacao-maior-igual', `${moment(filtro.data.criacao.de).format('DDMMYYYY')}`);
        }
        if (filtro.data.criacao.ate) {
          params = params.append('data-criacao-menor-igual', `${moment(filtro.data.criacao.ate).format('DDMMYYYY')}`);
        }
      }
    }

    if (filtro.idEmpresa) {
      params = params.append('idEmpresa', `${filtro.idEmpresa}`);
    }

    if(filtro.tipo === "importacaoCnab"){
      params = params.append('is-importacao-cnab', 'true');
    }

    if (filtro.status && filtro.status.show && filtro.status.dados != null) {
      params = params.append("status", `${filtro.status.dados}`);
    }

    return params;
  }

  onFiltroPagamento(params) {
    if (params['data-criacao-maior-igual']) {
      this.filtroPagamento.data = this.filtroPagamento.data || {};
      this.filtroPagamento.data.criacao = this.filtroPagamento.data.criacao || {};
      this.filtroPagamento.data.criacao.de = new Date(parseFloat(params['data-criacao-maior-igual'].slice(13, 17)), parseFloat(params['data-criacao-maior-igual'].slice(11, 13)), parseFloat(params['data-criacao-maior-igual'].slice(9, 11)));
    }
    if (params['data-criacao-menor-igual']) {
      this.filtroPagamento.data = this.filtroPagamento.data || {};
      this.filtroPagamento.data.criacao = this.filtroPagamento.data.criacao || {};
      this.filtroPagamento.data.criacao.ate = new Date(parseFloat(params['data-criacao-menor-igual'].slice(13, 17)), parseFloat(params['data-criacao-menor-igual'].slice(11, 13)), parseFloat(params['data-criacao-menor-igual'].slice(9, 11)));
    }
    if (params['page']) {
      this.filtroPagamento.page = this.filtroPagamento.page || 10;
      this.filtroPagamento.page = params['page'];
    }
    if (params['q']) {
      this.filtroPagamento.query = this.filtroPagamento.query || ' ';
      this.filtroPagamento.query = params['q'];
    }
    if (params['size']) {
      this.filtroPagamento.size = this.filtroPagamento.size || 10;
      this.filtroPagamento.size = params['size'];
    }
    if (params['order']) {
      this.filtroPagamento.order = this.filtroPagamento.order || ' ';
      this.filtroPagamento.order = params['order'];
    }

    return this.filtroPagamento;
  }

  gerarParametrosPix(filtro: FiltroAlcadaPixDTO) {
    let params = new HttpParams()

    if(!filtro) return params;

    const { data } = filtro;
    if (data) {
      const { data_confirmacao, data_criacao, data_agendamento } = data;
      if (data_confirmacao && data_confirmacao.show) {
        const { de, ate } = data_confirmacao;
        if (de) {
          params = params.append(
            "data-confirmacao-maior-igual",
            `${moment(de).format("DDMMYYYY")}`
          );
        }
        if (ate) {
          params = params.append(
            "data-confirmacao-menor-igual",
            `${moment(ate).format("DDMMYYYY")}`
          );
        }
      }
      if (data_criacao && data_criacao.show) {
        const { de, ate } = data_criacao;
        if (de) {
          params = params.append(
            "data-criacao-maior-igual",
            `${moment(de).format("DDMMYYYY")}`
          );
        }
        if (ate) {
          params = params.append(
            "data-criacao-menor-igual",
            `${moment(ate).format("DDMMYYYY")}`
          );
        }
      }
      if (data_agendamento && data_agendamento.show) {
        const { de, ate } = data_agendamento;
        if (de) {
          params = params.append(
            "data-agendamento-maior-igual",
            `${moment(de).format("DDMMYYYY")}`
          );
        }
        if (ate) {
          params = params.append(
            "data-agendamento-menor-igual",
            `${moment(ate).format("DDMMYYYY")}`
          );
        }
      }
    }

    if (filtro.order) {
      params = params.append("order", `${filtro.order}`);
    }

    if (filtro.page != null) {
        params = params.append("page", `${filtro.page}`);
    }

    if (filtro.query) {
      params = params.append("q", `${filtro.query}`);
    }

    if (filtro.size) {
      params = params.append("size", `${filtro.size}`);
    }

    return params;
  }

}
