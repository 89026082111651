<div class="row">
  <div class="col-12">
    <form [formGroup]="form">
      <div class="row alinha" *ngFor="let dado of form.controls; index as i" [formGroup]="dado">

        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <h4 class="titulo-conta-bancaria">
                CONTA BANCÁRIA {{ i + 1 }}
              </h4>
            </div>
            <div class="d-flex justify-content-center">
              <div style="margin-top: 15px; margin-right: 10px;">
                <app-input-ativar-desativar fontSet="fas" fontIcon="fa-star" formControlName="favorito">
                  Favorito
                </app-input-ativar-desativar>
              </div>
              <div *ngIf="dado.value.isDeletavel; else toggleButton" style="margin-top: 5px; margin-bottom: 10px;">
                <button (click)="del(i)" mat-mini-fab color="warn" style="padding-top: 3px;">
                  <mat-icon fontSet="fas" fontIcon="fa-trash"></mat-icon>
                </button>
              </div>
              <ng-template #toggleButton class="col-2" style="margin-top: 5x; margin-bottom: 10px;">
                <mat-slide-toggle formControlName="isAtivo" style="padding-top: 10px;">
                  <span
                    matTooltip="Esta conta já possui transferências registradas e não pode ser alterada/deletada.">{{dado.value.isAtivo
                    ? "Ativada" : "Desativada"}}</span>
                </mat-slide-toggle>
              </ng-template>
            </div>
          </div>
          <div class="row">
          </div>
          <p [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
            style="font-size: 13px">Preencha abaixo seus dados bancários para realização de transferência. Para que as
            transferências ocorram
            normalmente, os dados informados devem estar iguais ao seu cadastro no banco.</p>
        </div>

        <div class="col-12 col-md-8">
          <ng-select placeholder="Banco"
            [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
            [readonly]="!dado.value.isDeletavel" formControlName="bancoItem" (search)="getBanco($event)"
            (change)="preencheCamposBanco($event, i)">
            <ng-option *ngFor="let item of bancoList" [value]="item">
              <span *ngIf="item.numeroCodigo">{{item.numeroCodigo}} -</span>
              {{item.nomeExtenso}}</ng-option>
          </ng-select>
        </div>

        <div class="col-12 col-md-4 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Tipo de Conta</mat-label>
            <mat-select
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [disabled]="!dado.value.isDeletavel" formControlName="tipoConta">
              <mat-option *ngFor="let tipo of listaTipoConta" [value]="tipo.value">
                {{ tipo.label }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getErrorMessage(dado.get('tipoConta')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-9 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Agencia</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" appInteger formControlName="agencia" maxlength="4" required />
            <mat-error>{{ getErrorMessage(dado.get('agencia')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-3 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Dígito</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" appInteger formControlName="agenciaDigito" maxlength="1" />
            <mat-error>{{ getErrorMessage(dado.get('agenciaDigito')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-9 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Conta</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" appInteger formControlName="conta" maxlength="12" required />
            <mat-error>{{ getErrorMessage(dado.get('conta')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-3 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Dígito</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" appInteger formControlName="contaDigito" maxlength="1" />
            <mat-error>{{ getErrorMessage(dado.get('contaDigito')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-12 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Apelido da Conta</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" formControlName="apelidoConta" required />
            <mat-error>{{ getErrorMessage(dado.get('apelidoConta')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Titular da Conta Bancária</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" formControlName="titular" />
            <mat-error>{{ getErrorMessage(dado.get('titular')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Documento do titular da conta</mat-label>
            <input readonly [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}" style="color: gray;"
              matTooltip="Campo bloqueado, pois não pode ser editado." [matTooltipPosition]="position.value" matInput
              type="text" formControlName="documento" readonly />
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Celular do responsável (opcional)</mat-label>
            <input matInput type="text" formControlName="celularResponsavel" mask="(00)000000000" />
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 input-size">
          <mat-form-field appearance="outline">
            <mat-label>Email do responsável (opcional)</mat-label>
            <input
              [matTooltip]=" !dado.value.isDeletavel ? 'Esta conta já possui transferências registradas e não pode ser alterada/deletada.': ''"
              [readonly]="!dado.value.isDeletavel" [ngStyle]="{'color': (!dado.value.isDeletavel) ? 'gray' : 'black'}"
              matInput type="text" formControlName="emailResponsavel" />
            <mat-error>{{ getErrorMessage(dado.get('emailResponsavel')) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row botao-centro">
  <div class="col-12 col-md-6" style="display: flex;">
    <button [disabled]="isDocumentoPreenchido()" class="btn btn-block" style="background-color: #4FA9A9" (click)="add()"
      mat-raised-button>
      <i class="fas fa-plus"></i>
      Dados Bancários
    </button>
    <i class="fas fa-info-circle info-icon" matTooltip="Certifique-se que todos os campos anteriores estão preenchidos"
      style="color: #818d91;margin-right: 5px;padding: 5px 0px 0px 5px;
    font-size: 12pt;"></i>
  </div>
</div>