<h2 mat-dialog-title class="titulo-modal flex-space-between">
    <div class="flex-align-center" style="width: 25%;"></div>
    INTERVALOS
    <div class="flex-align-center" style="width: 25%; justify-content: end;">
        <i (click)="fecha()" class="material-icons btn-fechar">close</i>
    </div>
</h2>

<div [formGroup]="form" class="row" style="display: flex; justify-content: center;">

    <div class="box-body" *ngIf="!naoSolicitar">
        <div class="row" style=" width: 52em; margin-top: 25px;">
            <div class="col-5 col-md-5" style="padding-right: 0">
                <p class="principal-title">Solicitar intervalo de Nosso Número</p>
            </div>
            <div class="col-1 col-md-1" style="margin-left: -40px; margin-top: -4px;">
                <mat-slide-toggle formControlName="solicitarNossoNumero">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="col-12">
            <p style="font-size: 15px;">
                Realize a solicitação de um novo intervalo de Nosso Número caso necessário
            </p>
        </div>

        <div class="row" *ngIf="form.get('solicitarNossoNumero').value === true">
            <div class="col-3 col-md-3 input-menor" style="padding-right: 5px; margin-left: 15px;">
                <mat-form-field appearance="outline">
                    <mat-label>Carteira</mat-label>
                    <mat-select formControlName="carteirasIntervalo">
                        <mat-option *ngFor="let carteira of listaCarteiras" [value]="carteira.enum"
                            (click)="carteiraSelecionada = carteira.valor">
                            {{ carteira.enum }}
                        </mat-option>
                    </mat-select>
                    <mat-error style="font-size: 12px; margin-top: 10px">{{ getErrorMessage(form,
                        "carteirasIntervalo") }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-1 col1-icone">
                <i class="fas fa-info-circle info-icon"
                    matTooltip="Selecione a carteira em que o intervalo de Nosso Número será gerado"></i>
            </div>


            <div class="col-4 input-menor" style="padding-right: 5px;">
                <mat-form-field appearance="outline">
                    <mat-label>Tamanho do intervalo</mat-label>
                    <input matInput formControlName="IntervaloNossoNumero" type="number">
                    <mat-error style="font-size: 12px; margin-top: 10px">{{ getErrorMessage(form,
                        "IntervaloNossoNumero") }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-1 col1-icone">
                <i class="fas fa-info-circle info-icon"
                    matTooltip="Escolha o tamanho do intervalo para o Nosso Número, tamanho máximo de 500.000 (50 mil títulos)"></i>
            </div>

            <div class="col-4">
                <button class="btn btn-block btn-primario button-solicitacao" [disabled]="form.invalid"
                    (click)="solicitarIntervalo()">Solicitar</button>
            </div>
        </div>
    </div>
</div>


<div *ngIf="hasIntervalo" class="d-flex flex-column justify-content-center box-body"
    style="margin: 15px 59px 15px 59px;">
    <div class="d-flex justify-content-center">
        <table class="col-10 table table-striped">
            <thead>
                <tr>
                    <th>Carteira</th>
                    <th>Intervalo Inicial</th>
                    <th>Intervalo Final</th>
                    <th>Intervalo Inicial de 11 dígitos
                        <i class="fas fa-info-circle"
                        style="vertical-align: top; font-size: x-small; margin-left: 3px;"
                        [matTooltip]="'Caso o emissor deseje enviar o dígito verificador, considerar o intervalo inicial abaixo: '">
                      </i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let carteira of carteiras">
                    <tr *ngIf="carteira.codigoCarteira != 2">
                        <td>{{carteira.codigoCarteira}}</td>
                        <td>{{carteira.intervaloNossoNumeroInicial}}
                            <i *ngIf="carteira.intervaloNossoNumeroInicial" class="fas fa-copy icone-copiar"
                                style="padding-left: 5px;"
                                (click)="copiarIntervalo(carteira.intervaloNossoNumeroInicial)"
                                title="Copiar para a área de transferência">
                            </i>
                        </td>
                        <td>{{carteira.intervaloNossoNumeroFinal}}
                            <i *ngIf="carteira.intervaloNossoNumeroFinal" class="fas fa-copy icone-copiar"
                                style="padding-left: 5px;" (click)="copiarIntervalo(carteira.intervaloNossoNumeroFinal)"
                                title="Copiar para a área de transferência">
                            </i>
                        </td>
                        <td>{{carteira.intervalo11Digitos}}
                            <i *ngIf="carteira.intervalo11Digitos" class="fas fa-copy icone-copiar"
                                style="padding-left: 5px;" (click)="copiarIntervalo(carteira.intervalo11Digitos)"
                                title="Copiar para a área de transferência">
                            </i>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <!-- <div class="col-4" style="margin-left: 19rem;">
        <button class="btn btn-block btn-primario button-solicitacao" (click)="atualizarIntervalos()">Atualizar</button>
    </div> -->
</div>