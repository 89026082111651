import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicidadeEndpointService } from '../../service/publicidade-endpoint.service';
import { AdicionaImagemComponent } from './adiciona-imagem/adiciona-imagem.component';
import { EditarImagemComponent } from './editar-imagem/editar-imagem.component';
import { NgbCarousel, NgbCarouselConfig, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-publicidade-fatura',
  templateUrl: './publicidade-fatura.component.html',
  styleUrls: ['./publicidade-fatura.component.less'],
})
export class PublicidadeFaturaComponent implements OnInit {
  listaPublicidade = [];
  processando: boolean = false;
  selectedPub: any;
  activeSlideId: any;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  constructor(
    public dialogRef: MatDialogRef<PublicidadeFaturaComponent>,
    public publicidadeService: PublicidadeEndpointService,
    private dialog: MatDialog,
    private config: NgbCarouselConfig
  ) {
    this.config.showNavigationArrows = true;
    this.config.showNavigationIndicators = true;
    this.config.interval = 10000;
  }

  ngOnInit() {
    this.listarPublicidade();
  }

  listarPublicidade() {
    this.processando = true;
    this.publicidadeService.listarPublicidade('FATURA').subscribe((res) => {
      this.listaPublicidade = res;
      this.processando = false;
      this.selectedPub = this.listaPublicidade[0];
    }, error => {
      this.processando = true;
      console.error(error);
    });
  }

  abreCropper() {
    this.dialog.open(AdicionaImagemComponent, {
      backdropClass: "modal-medio",
    }).afterClosed()
      .subscribe((res) => {
        this.listarPublicidade();
      })
  }

  editaImagem() {
    this.dialog.open(EditarImagemComponent, {
      backdropClass: "modal-medio",
      data: this.selectedPub
    }).afterClosed()
      .subscribe((res) => {
        this.listarPublicidade();
      })
  }


  fecha() {
    this.dialogRef.close();
  }

}
