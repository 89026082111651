import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionamentoEndpointService {
  constructor(private client: HttpClient) {}

  // Envia aqui o component para consultar no banco se o usuário atualmente logado possui permissão em banco para acessar esse compnente
  // component deve ter role no banco
  permite(p): Observable<any> {
    let params = new HttpParams();
    params = params.append('p', `${p}`);
    const url = '/permissao';
    return this.client.get(url, { params });
  }

  editPermissao(permissoes, nroOperador, perfilOperador?: string): Observable<any> {
    const url = '/permissao/permis-operador'
    let params = new HttpParams();
    params = params.append('nroOperador', `${nroOperador}`);
    if(perfilOperador) params = params.append('perfilOperador', perfilOperador);
    return this.client.post(url, permissoes, { params } );
  }

}
