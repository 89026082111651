import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { UsuarioStepper2Model } from './usuario-stepper2.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import 'moment/locale/pt-br';
import { subYears } from 'date-fns';
import { ValidacoesService } from '@service/validacoes.service';
import { DadosEndpointService } from '@service/dados-endpoint.service';
import { debounceTime } from 'rxjs/operators';
import { ConfiguracoesOnboardingDTO } from '@project/src/app/model/configuracoes-onboarding-dto.model';

@Component({
  selector: 'app-stepper2',
  templateUrl: './stepper2.component.html',
  styleUrls: ['./stepper2.component.less']
})
export class Stepper2Component implements OnInit,OnChanges, OnDestroy {
  processando = false;
  usuario: UsuarioStepper2Model;
  dataDezoitoAnos = new Date();
  dataDeHoje = new Date();
  numeroTelefone: string;

  @Input()
  public form: FormGroup;
  @Input()
  public idTransferencia: number;
  @Input()
  configuracoesOnboarding: ConfiguracoesOnboardingDTO

  dataMinima: Date;
  
  @Output()
  public atualizarForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private autenticacao: AutenticacaoEndpointService,
  ) {
    this.dataDezoitoAnos = subYears(new Date(), 18);
    this.dataMinima = subYears(new Date(), 120);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(800)).subscribe(val => {
      this.atualizarForm.emit({atualizar:true});
    });
  }

  findInvalidControls(form:FormGroup) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid.length;
  }

  ngOnInit() {
    this.autenticacao._sessao.pipe(untilDestroyed(this)).subscribe((response) => {
      if (response) {
        this.usuario = response.principal.usuario;
      }
    }, error => {
      console.error(error);
    });
  }

  getErrorMessage(form: FormGroup, field) {
    if (form.get(field)) {
      return form.get(field).hasError('required')
        ? 'Campo requerido'
        : form.get(field).hasError('obrigatorio')
          ? 'Campo requerido'
          : form.get(field).hasError('matDatepickerMax')
            ? 'Apenas usuários maiores de 18 anos podem utilizar a plataforma'
            : form.get(field).hasError('cpfinvalido')
              ? 'CPF inválido'
                : form.get(field).hasError('pattern') 
                ? 'Campo inválido'
                  : '';
    }
  }

  mascaraData(event:any,field:string){
    let data = event.target.value;
    data=data.replace(/\D/g,"");
    data=data.replace(/(\d{2})(\d)/,"$1/$2");
    data=data.replace(/(\d{2})(\d)/,"$1/$2");
    data=data.replace(/(\d{2})(\d{2})$/,"$1$2");
    document.getElementById(field)['value']=data;
  }
  
  get endereco() {
    return this.form.get('endereco');
  }

  set endereco(endereco) {
    this.form.removeControl('endereco');
    this.form.addControl('endereco', endereco);
  }

  ngOnDestroy() { }

  public build() {
    const formUserData = this.form.value;

    const data = {
      id: this.usuario.id,
      nome: this.usuario.nome,
      sobrenome: this.usuario.sobrenome,
      email: this.usuario.email,
      celular: {
        principal: this.usuario.celular.principal || true,
        tipo: this.usuario.celular.tipo,
        ddd: this.usuario.celular.ddd,
        numero: this.usuario.celular.numero,
        ramal: this.usuario.celular.ramal,
        pais: this.usuario.celular.pais
      },
      ...formUserData,
     // dataEmissao:formUserData.dataEmissao._i,
      dataNascimento:formUserData.dataNascimento._i,
      endereco: formUserData.endereco[0]
    };

    return data;
  }
}
