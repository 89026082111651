import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateBarModule } from '@project/src/app/components/date-bar/date-bar.module';
import { UploadArquivoModule } from '@project/src/app/components/upload-arquivo/upload-arquivo.module';
import { IconsModule } from '@project/src/app/icons.module';
import { MaterialModule } from '@project/src/app/material.module';
import { PipeModule } from '@project/src/app/pipe/pipe.module';
import { TokenModule } from '@project/src/app/components/token/token.module';
import { PagamentoLoteSistemaEditarComponent } from './pagamento-lote-sistema-editar/pagamento-lote-sistema-editar.component';
import { PagamentoLoteSistemaModeloComponent } from './pagamento-lote-sistema-editar/pagamento-lote-sistema-modelo/pagamento-lote-sistema-modelo.component';
import { PagamentoLoteSistemaResumoComponent } from './pagamento-lote-sistema-editar/pagamento-lote-sistema-resumo/pagamento-lote-sistema-resumo.component';
import { PagamentoLoteSistemaUploadComponent } from './pagamento-lote-sistema-editar/pagamento-lote-sistema-upload/pagamento-lote-sistema-upload.component';
import { PagamentoLoteItemSistemaListaComponent } from './pagamento-lote-sistema-listar/pagamento-lote-item-sistema-lista/pagamento-lote-item-sistema-lista.component';
import { PagamentoLoteSistemaListarComponent } from './pagamento-lote-sistema-listar/pagamento-lote-sistema-listar.component';

@NgModule({
  declarations: [
    PagamentoLoteSistemaEditarComponent,
    PagamentoLoteSistemaListarComponent,
    PagamentoLoteSistemaModeloComponent,
    PagamentoLoteSistemaResumoComponent,
    PagamentoLoteSistemaUploadComponent,
    PagamentoLoteItemSistemaListaComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    DateBarModule,
    IconsModule,
    UploadArquivoModule,
    PipeModule,
    TokenModule
  ],
})
export class PagamentoLoteSistemaModule { }
