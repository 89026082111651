import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarteiraDTO } from '../../model/cobranca/carteira-dto.model';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CobrancaEndpointService } from '../../service/cobranca-endpoint.service';

@Component({
  selector: 'app-consultar-intervalo-carteira',
  templateUrl: './consultar-intervalo-carteira.component.html',
  styleUrls: ['./consultar-intervalo-carteira.component.less'],
})
export class ConsultarIntervaloCarteiraComponent implements OnInit {
  naoSolicitar: boolean = false;
  carteiras: CarteiraDTO[] = [];
  listaCarteiras = [
    { valor: 9, enum: 'carteira 9' },
    { valor: 16, enum: 'carteira 16' },
  ];
  carteiraSelecionada = 9;
  hasIntervalo: boolean = false;

  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConsultarIntervaloCarteiraComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public cobrancaService: CobrancaEndpointService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this.naoSolicitar = this.dataDialog?.apenasConsulta;
    this.carteiras = this.dataDialog?.carteiras;
    if (this.carteiras?.length > 0) {
      this.hasIntervalo = true;
    }

    this.form = this.fb.group({
      carteirasIntervalo: [null, Validators.required],
      IntervaloNossoNumero: [
        10000,
        [Validators.required, Validators.min(10000), Validators.max(500000)],
      ],
    });
    this.form.addControl(
      'solicitarNossoNumero',
      new FormControl(
        this.dataDialog?.solicitar
          ? this.dataDialog.solicitar
          : !this.hasIntervalo
      )
    );
  }

  fecha(): void {
    this.dialogRef.close();
  }

  getErrorMessage(form: FormGroup, field: string): string {
    const control = form.get(field);

    if (control) {
      if (control.hasError('required') || control.hasError('obrigatorio')) {
        return 'Campo requerido.';
      }
      if (control.hasError('max')) {
        return 'O valor deve ser menor ou igual a 500.000.';
      }
      if (control.hasError('min')) {
        return 'O valor deve ser maior ou igual a 10.000.';
      }
    }

    return '';
  }

  copiarIntervalo(valor: string): void {
    navigator.clipboard.writeText(valor).then(() => {
      this.toastr.success('Intervalo copiado!', '', {
        positionClass: 'toast-center-center',
        timeOut: 1000,
      });
    });
  }

  solicitarIntervalo() {
    this.cobrancaService
      .adicionarCarteira(
        this.dataDialog.documentoBeneficiario,
        this.dataDialog.agencia,
        this.dataDialog.conta,
        parseInt(this.form.get('IntervaloNossoNumero').value, 10),
        this.carteiraSelecionada,
        this.dataDialog.empresaId
      )
      .toPromise()
      .then(
        () => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Solicitação recebida pela CIP. A listagem será atualizada.',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.ngOnInit();
            this.atualizarIntervalos();
          });
        },
        (error) => {
          const errorMessage = error.error?.message || error.error || 'Tente novamente mais tarde ou contate o suporte';
          Swal.fire({
            title: 'Erro',
            text: 'Falha ao solicitar intervalo: ' + errorMessage,
            icon: 'error',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.ngOnInit();
          });
        }
      );
  }

  atualizarIntervalos() {
    this.cobrancaService
      .consultarIntervaloCarteira(
        this.dataDialog.documentoBeneficiario,
        this.dataDialog.agencia,
        this.dataDialog.conta
      )
      .subscribe(
        (response) => {
          this.carteiras = response;
          this.hasIntervalo = true;
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
