import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FiltroTransferenciaDTO } from "@model/filtro/filtro-transferencia-dto.model";
import { TransferenciaLoteDTO } from "@model/financeiro/transferencia/transferencia-lote-dto.model";
import { ListaTransferenciaDTO } from "@model/lista-transferencia-dto.model";
import { PageResponse } from "@model/page-response.model";
import { ArquivoDTO } from "@model/sistema/arquivo-dto.model";
import moment from "moment";
import { Observable } from "rxjs";
import { SimpleFilter, SimpleFilterUtils } from "../model/util/simple-filter.model";
import { FiltroService } from "./filtro.service";
import { TransferenciaLoteSistemaDTO } from "../model/financeiro/transferencia/transferencia-lote-sistema-dto.model";
import { TokenDTO } from "../model/sistema/token-dto.model";

type ModeracaoTransferenciaRepresentante = {
  usuarioId: number,
  transferenciaId: number,
  status: string,
  dataModeracao?: Date,
  nomeUsuario?: string,
  emailUsuario?: string,
  token?: any
}

@Injectable({
  providedIn: "root",
})
export class TransferenciaEndpointService {
  constructor(
    private client: HttpClient,
    private filtroService: FiltroService
  ) { }

  primeiroPasso(transferenciaData): Observable<any> {
    const url = "/transferencia/primeiro-passo";
    return this.client.post(url, transferenciaData);
  }

  getCodAutenticacao(idTransferencia): Observable<any> {
    const url = "/transferencia/gerar-codigo-autenticacao";
    return this.client.patch(url, { id_transferencia: idTransferencia });
  }

  confirmarCodigo(codConfirmacao, idTransferencia): Observable<any> {
    const url = "/transferencia/confirmar-codigo";
    return this.client.patch(url, {
      id_transferencia: idTransferencia,
      codigo: codConfirmacao,
    });
  }

  getComprovanteTransf(idTransferencia, tipoSTR): Observable<any> {
    const url = "/transferencia/ver-comprovante/" + idTransferencia;
    let params = new HttpParams();
    params = params.append("tipoSTR", `${tipoSTR}`);
    return this.client.get(url,{params});
  }

  cancelarTranferencia(idTransferencia): Observable<any> {
    const url = `/transferencia/cancelar/${idTransferencia}`;
    return this.client.delete(url);
  }

  getContaDoc(documento) {
    const url = `/transferencia/buscar-conta-documento/${documento}`;
    return this.client.get(url);
  }

  getContaDocumento(contaInterna): Observable<any> {
    const url = `/transferencia/buscar-conta/${contaInterna}`;
    return this.client.get(url);
  }

  saldoDisponivel() {
    const url = "/transferencia/saldo-disponivel";
    return this.client.get<number>(url);
  }

  sensiabilizaTransferencia(data): Observable<any> {
    const url = "/transferencia/ajuste-saldo";
    return this.client.post(url, data);
  }

  sensiabilizaTaxa(data): Observable<any> {
    const url = "/transferencia/ajuste-taxa";
    return this.client.post(url, data);
  }

  contaPossuiTransferencia(contaID: number) {
    let params = new HttpParams();
    params = params.append("contaId", `${contaID}`);
    const url = "/transferencia/transferencia-conta";
    return this.client.get<Boolean>(url, { params });
  }

  //  /transferencia/lista?page=[integer]&size=[integer]
  listar(
    filtro: FiltroTransferenciaDTO
  ): Observable<PageResponse<any>> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosTransferencia(filtro);

    const url = "/transferencia/listar";
    return this.client.get(url, { params });
  }

  novaTransferencia(transferenciaData): Observable<any> {
    const url = "/transferencia/nova-transferencia";
    return this.client.post(url, transferenciaData);
  }

  novaTransferenciaStr0008(transferenciaData): Observable<any> {
    const url = "/transferencia/nova-transferencia-str0008";
    return this.client.post(url, transferenciaData);
  }

  loteModelo() {
    return this.client.get<ArquivoDTO>(`/transferencia/lote/modelo`);
  }

  versaoModelo() {
    return this.client.get<string>(`/transferencia/lote/versao`);
  }

  validarLote(arquivo: ArquivoDTO) {
    return this.client.post<TransferenciaLoteDTO>(`/transferencia/lote/validar`, arquivo);
  }

  enviarLote(arquivo: ArquivoDTO, token: string, tipoToken: string) {
    return this.client.post<number>(`/transferencia/lote/enviar`, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  listarLote(args?: SimpleFilter) {
    const params = SimpleFilterUtils.toHttpUtils(args);
    return this.client.get<PageResponse<TransferenciaLoteDTO>>(`/transferencia/lote/listar`, {
      params
    });
  }

  listarLotesProgramados(empresaId: number) {
    return this.client.get<TransferenciaLoteDTO[]>(`/transferencia/lote/listar-programados/${empresaId}`);
  }

  downloadFalhas(loteId: number) {
    return this.client.get<TransferenciaLoteDTO>(`/transferencia/lote/download-falhas`, {
      params: new HttpParams()
        .append('loteId', `${loteId}`)
    });
  }

  cancelarLoteProgramado(loteId: number){
    return this.client.patch(`/transferencia/lote/cancelar-programado/${loteId}`, null)
  }

  listarTransferenciasFiltro(
    status: string, dataCriacao: Date, dataAgendamento?: Date
  ): Observable<any> {
    let params = new HttpParams();      
    
    params = params.append("status", `${status}`);
    params = params.append(
      "data-criacao",
      `${moment(dataCriacao).format("DDMMYYYY")}`
    );
    
    if(dataAgendamento){
      params = params.append(
        "data-agendamento", 
        `${moment(dataAgendamento).format("DDMMYYYY")}`
      );
    }
    
    const url = "/transferencia/listar-transferencias-filtro";
    return this.client.get(url, { params });
  }

  calculaDataAgendamento(dataAgendamento): Observable<any>{
    return this.client.get(`/transferencia/calcularDataAgendamento`, {
      params: new HttpParams()
        .append('dataAgendamento', `${dataAgendamento}`)
    });
  }

  cancelarAgendamento(transferenciaId): Observable<any> {
    return this.client.delete(`/transferencia/cancelarAgendamento`, {
      params: new HttpParams()
        .append('transferenciaId', `${transferenciaId}`)
    });
  }

  listarEmAberto(
    filtro: FiltroTransferenciaDTO
  ): Observable<PageResponse<ListaTransferenciaDTO>> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosTransferencia(filtro);

    const url = "/transferencia/transferencias-em-aberto";
    return this.client.get(url, { params });
  }

  aprovarTransferencia(id: number): Observable<any> {
    const url = `/transferencia/aprovar-transferencia/${id}`;
    return this.client.post(url, id);
  }

  listarAlcada(
    filtro: FiltroTransferenciaDTO
  ): Observable<PageResponse<any>> {
    filtro = filtro || {};
    let params = this.filtroService.gerarParametrosTransferencia(filtro);
    
    const url = "/transferencia/listar-alcada";
    return this.client.get(url, { params });
  }

  aprovarTransferenciaAlcada(id: number, token: TokenDTO): Observable<any> {
    const url = `/transferencia/aprovar-transferencia-alcada/${id}`;
    return this.client.post(url, token);
  }

  saldoDisponivelOuNegativo(id: number) {
    let params = new HttpParams();
    if (id) {
      params = params.append("id", `${id}`);
    }
    const url = "/transferencia/saldo-disponivel-ou-negativo";
    return this.client.get<number>(url, { params });
  }

  gerarComprovantePdf(transferenciaId: number): Observable<string> {
    const url = `/transferencia/gerar-comprovante-pdf/${transferenciaId}`;
    return this.client.get<string>(url);
  }

  exportarComprovantesLote(loteId: number, emails): Observable<string>{
    const url = `/transferencia/lote/exportar-comprovantes/${loteId}`;
    let params = new HttpParams();
    params = params.append("emails", emails);
    
    return this.client.get<string>(url, { params });
  }

  moderacaoRepresentante(moderacao: ModeracaoTransferenciaRepresentante){
    const url = "/transferencia/moderacao-representante"
    return this.client.post(url, moderacao)
  }

  listarModeracaoRepresentante(transferenciaId: number){
    const url = `/transferencia/listar-moderacao-representante/${transferenciaId}`
    return this.client.get<ModeracaoTransferenciaRepresentante[]>(url)
  }

  devolverTranferencia(idTransferencia, token: any, motivo: string): Observable<any> {
    const url = `/transferencia/devolver/${idTransferencia}`;

    return this.client.post(url, token, {
      params: new HttpParams()
        .append('motivo', motivo)
    });
  }

  loteModeloSistema() {
    return this.client.get<ArquivoDTO>(`/transferencia/lote/modelo-sistema`);
  }

  versaoModeloSistema() {
    return this.client.get<string>(`/transferencia/lote/versao-sistema`);
  }

  validarLoteSistema(arquivo: ArquivoDTO) {
    return this.client.post<TransferenciaLoteSistemaDTO>(`/transferencia/lote/validar-sistema`, arquivo);
  }

  enviarLoteSistema(arquivo: ArquivoDTO, token: string, tipoToken: string) {
    return this.client.post<number>(`/transferencia/lote/enviar-sistema`, arquivo, {
      params: new HttpParams()
        .append('token', token)
        .append('tipoToken', tipoToken)
    });
  }

  downloadFalhasSistema(loteId: number) {
    return this.client.get<TransferenciaLoteSistemaDTO>(`/transferencia/lote/download-falha-sistema`, {
      params: new HttpParams()
        .append('loteId', `${loteId}`)
    });
  }

  listarLoteSistema(args?: SimpleFilter) {
    const params = SimpleFilterUtils.toHttpUtils(args);
    return this.client.get<PageResponse<TransferenciaLoteSistemaDTO>>(`/transferencia/lote/listar-sistema`, {
      params
    });
  }

  exportarComprovantesLoteSistema(loteId: number, emails): Observable<string>{
    const url = `/transferencia/lote/exportar-comprovantes-sistema/${loteId}`;
    let params = new HttpParams();
    params = params.append("emails", emails);
    
    return this.client.get<string>(url, { params });
  }

  listarItemSistema(
    page: number,
    size: number,
    loteId: string,
    order?: string,
    query?: string,
  ): Observable<PageResponse<any>> {
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    params = params.append('loteId', `${loteId}`);
    order ? params = params.append('order', `${order}`) : null;
    query ? params = params.append('query', `${query}`) : null;

    const url = "/transferencia/lote/listar-item-sistema";
    return this.client.get(url, { params });
  }

  moderarTransferenciasEmLote(transferencias: number[], status: string, token: TokenDTO): Observable<any> { 
    const url = "/transferencia/moderar-transferencias-em-lote";
    return this.client.post(url, { transferencias, status, token });
  }
  
}
