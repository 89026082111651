import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateBarModule } from '@project/src/app/components/date-bar/date-bar.module';
import { UploadArquivoModule } from '@project/src/app/components/upload-arquivo/upload-arquivo.module';
import { IconsModule } from '@project/src/app/icons.module';
import { MaterialModule } from '@project/src/app/material.module';
import { PipeModule } from '@project/src/app/pipe/pipe.module';
import { TokenModule } from '@project/src/app/components/token/token.module';
import { TransferenciaLoteSistemaEditarComponent } from './transferencia-lote-sistema-editar/transferencia-lote-sistema-editar.component';
import { TransferenciaLoteSistemaListarComponent } from './transferencia-lote-sistema-listar/transferencia-lote-sistema-listar.component';
import { TransferenciaLoteSistemaModeloComponent } from './transferencia-lote-sistema-editar/transferencia-lote-sistema-modelo/transferencia-lote-sistema-modelo.component';
import { TransferenciaLoteSistemaResumoComponent } from './transferencia-lote-sistema-editar/transferencia-lote-sistema-resumo/transferencia-lote-sistema-resumo.component';
import { TransferenciaLoteSistemaUploadComponent } from './transferencia-lote-sistema-editar/transferencia-lote-sistema-upload/transferencia-lote-sistema-upload.component';
import { TransferenciaLoteItemSistemaListaComponent } from './transferencia-lote-sistema-listar/transferencia-lote-item-sistema-lista/transferencia-lote-item-sistema-lista.component';

@NgModule({
  declarations: [
    TransferenciaLoteSistemaEditarComponent,
    TransferenciaLoteSistemaListarComponent,
    TransferenciaLoteSistemaModeloComponent,
    TransferenciaLoteSistemaResumoComponent,
    TransferenciaLoteSistemaUploadComponent,
    TransferenciaLoteItemSistemaListaComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    DateBarModule,
    IconsModule,
    UploadArquivoModule,
    PipeModule,
    TokenModule
  ],
})
export class TransferenciaLoteSistemaModule { }
