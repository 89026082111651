<h1 mat-dialog-title style="text-align: center;">Alterar Senha</h1>
<div mat-dialog-content [ngStyle]="login ? {'height': '290px'} : {'overflow': 'hidden', 'height': '210px'}">
  <form [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Senha atual</mat-label>
      <input matInput formControlName="senhaAntiga" [type]="ehVisivelAtual" required />
      <mat-icon *ngIf="ehVisivelAtual === 'password'" (click)="changeVisibilityAtual()" style="cursor: pointer"
        matSuffix>lock</mat-icon>
      <mat-icon *ngIf="ehVisivelAtual === 'text'" (click)="changeVisibilityAtual()" style="cursor: pointer"
        matSuffix>lock_open</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Nova senha</mat-label>
      <input matInput formControlName="novaSenha" [type]="ehVisivelNova" required (keyup)="isValid('novaSenha')" />
      <mat-icon [hidden]="!novaSenhaValida" style="color: #4caf50;display: inline;" matSuffix>done</mat-icon>
      <mat-icon *ngIf="ehVisivelNova === 'password'" (click)="changeVisibilityNova()"
        style="display: inline;cursor: pointer" matSuffix>lock</mat-icon>
      <mat-icon *ngIf="ehVisivelNova === 'text'" (click)="changeVisibilityNova()"
        style="display: inline;cursor: pointer" matSuffix>lock_open</mat-icon>
      <mat-error>{{ getErrorMessage("novaSenha") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Confirme a nova senha</mat-label>
      <input matInput formControlName="confirmaSenha" [type]="ehVisivelConfirma" required
        (keyup)="isValid('confirmaSenha')" />
      <mat-icon [hidden]="!confirmaSenhaValida" style="color: #4caf50;display: inline;" matSuffix>done</mat-icon>
      <mat-icon *ngIf="ehVisivelConfirma === 'password'" (click)="changeVisibilityConfirma()"
        style="cursor: pointer;display: inline;" matSuffix>lock</mat-icon>
      <mat-icon *ngIf="ehVisivelConfirma === 'text'" (click)="changeVisibilityConfirma()"
        style="cursor: pointer;display: inline;" matSuffix>lock_open</mat-icon>
      <mat-error>{{ getErrorMessage("confirmaSenha") }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="row botao-separado">

  <div [hidden]="!processando" class="col-12" style="padding: 10px 0 20px 0;">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="row" style="justify-content: center;">
    <div class="col-12 small ">
      <mat-list>
        <mat-list-item [ngClass]="verificadorSenha.quantidade ? 'feito' : 'pendente'">* Ao menos 8
          caracteres </mat-list-item>
        <mat-list-item [ngClass]="verificadorSenha.letraMaiscula ? 'feito' : 'pendente'">* Ao menos
          uma letra maiúscula </mat-list-item>
        <mat-list-item [ngClass]="verificadorSenha.letraMinuscula ? 'feito' : 'pendente'">* Ao menos
          uma letra minúscula </mat-list-item>
        <mat-list-item [ngClass]="verificadorSenha.numero ? 'feito' : 'pendente'">* Ao menos um
          número</mat-list-item>
        <mat-list-item [ngClass]="verificadorSenha.caracterEspecial ? 'feito' : 'pendente'">* Ao menos um
          caracter especial
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <a class="btn btn-default btn-block" (click)="onNoClick()" style="color: #fff;" mat-raised-button>
      Cancelar
    </a>
    <button [disabled]="form.invalid || !confirmaSenhaValida" (click)="enviar()"
      class="btn btn-block btn-primary fundoCorPrimaria" mat-raised-button>
      Confirmar
    </button>
  </div>
</div>