import { Component, OnInit, Inject, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CobrancaEndpointService } from '@service/cobranca-endpoint.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Router } from '@angular/router';
import { ptBR } from 'date-fns/locale';
import { endOfDay, formatDistanceStrict } from 'date-fns';
import Swal from 'sweetalert2';
import { AutenticacaoEndpointService } from '@service/autenticacao-endpoint.service';
import { environment } from '@src/environments/environment';
import { ConfirmarEditarCobrancaComponent } from '../confirmar-editar-cobranca/confirmar-editar-cobranca.component';
import moment from 'moment';
import { ModalConfirmacaoComponent } from '../../../../components/modal/modal-confirmacao/modal-confirmacao.component';
import { StringFormatService } from "@project/src/app/service/util/stringFormater.service"
import { Stepper1Component } from './stepper1/stepper1.component';
import { Stepper2Component } from './stepper2/stepper2.component';
import { LocalizacaoComponent } from '@project/src/app/components/localizacao/localizacao.component';
import { LocalizacaoEndPointService } from '@project/src/app/service/localizacao-endpoint.service';
import { error } from 'console';
import { RootEndpointService } from '@project/src/app/service/root-endpoint.service';
import { PixEndpointService } from '@project/src/app/service/pix-endpoint.service';
import { PixModalCobrancaComponent } from '../../pix/pix-modal-cobranca/pix-modal-cobranca.component';
import { QrCodePixDTO } from '@project/src/app/model/qrCode-pix-dto.model';
import { HomeEndpointService } from '@project/src/app/service/home-endpoint.service';

@Component({
  selector: 'app-emitir-cobranca',
  templateUrl: './emitir-cobranca.component.html',
  styleUrls: ['./emitir-cobranca.component.less']
})
export class EmitirCobrancaComponent implements OnInit, OnDestroy, AfterViewInit {
  processando = false;
  empresaLogada: any;
  public form: FormGroup;
  env = environment;
  precos = {
    precoPagamentoCartao: 0,
    porcentagemMesPagamentoCartao: 0,
    precoLiquidacaoBoleto: 0
  };
  dataBaixa: any;
  valorParcela: number;
  usuarioLogado: any;
  tipoLocalizacao: string = 'cobranca';
  tipoLocalizacaoLiberado: any;
  urlAjuda = null;
  bloqueiaFuncionalidades: boolean = false;
  configuracoesSistema: any;
  public nextButtonDisabled: boolean = true;


  @ViewChild('elementstepper1', { static: false }) elementstepper1;
  @ViewChild('elementstepper2', { static: false }) elementstepper2;
  @ViewChild(Stepper1Component, { static: false }) Stepper1;
  @ViewChild(Stepper2Component, { static: false }) Stepper2;
  @ViewChild(LocalizacaoComponent, { static: false }) localizacaoComponent: LocalizacaoComponent;

  dataResponse: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private cobrancaService: CobrancaEndpointService,
    private autenticacao: AutenticacaoEndpointService,
    private formBuilder: FormBuilder,
    private rootService: RootEndpointService,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EmitirCobrancaComponent>,
    private verifica: ChangeDetectorRef,
    public stringFormat: StringFormatService,
    public localizacaoService: LocalizacaoEndPointService,
    public pixService: PixEndpointService,
    private homeService: HomeEndpointService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {
    this.precos.precoLiquidacaoBoleto = this.autenticacao.regraEmpresa.empresa.configuracao.precoLiquidacaoBoleto;
    this.precos.precoPagamentoCartao = this.autenticacao.regraEmpresa.empresa.configuracao.precoPagamentoCartao;
    this.precos.porcentagemMesPagamentoCartao = this.autenticacao.regraEmpresa.empresa.configuracao.porcentagemMesPagamentoCartao;
    this.empresaLogada = this.autenticacao.regraEmpresa
      ? this.autenticacao.regraEmpresa.empresa
      : null;
    dialogRef.disableClose = true;

    this.permissaoGeolocalizacao();
  }

  ngOnInit() {
    this.getConfig();
    this.getAjuda();

    this.form = this.formBuilder.group({
      formStepper1: this.formBuilder.group({}),
      formStepper2: this.formBuilder.group({}),
    });
    this.usuarioLogado = this.autenticacao.sessao.principal.usuario;


    if ((this.empresaLogada.numIdentcBenfcrio === null || this.empresaLogada.numIdentcBenfcrio == "0") &&
      this.empresaLogada.tipoConta !== "contaDigitalS") {
      this.bloqueiaFuncionalidades = true;
    }

    this.form.get('formStepper1').statusChanges.subscribe(status => {
      this.toggleNextButton();
    });

  }

  ngAfterViewInit() {
    if (this.dataDialog.idCobranca) {
      this.cobrancaService.getCobrancaById(this.dataDialog.idCobranca).subscribe(
        (response) => {
          this.atualizaForms(response);
          if (response.titulo.quantidadeDiasBaixa > 0) {
            this.buscaDataBaixa();
          }
        }, error => {
          Swal.fire('Erro', error.error, 'error');
          console.error(error);
        }
      )
    } else {
      this.form.get("formStepper1").get("beneficiarioFinal").disable();
      if (this.dataDialog.isEdicaoLote){
        this.form.get('formStepper2.metodoPagamento').disable();
        this.form.get('formStepper2.intervaloAssinatura').disable();
        this.form.get('formStepper2.fimAssinatura').disable();
        this.form.get('formStepper2.numeroParcelas').disable();
        this.form.get('formStepper2.incluirNumeroDocumentoEspecie').disable();
        this.form.get('formStepper2.numeroDocumento').disable();
        this.form.get('formStepper2.especie').disable();
        this.form.get('formStepper2.pagamentoAposVencimento').disable();
        this.form.get('formStepper2.quantidadeDiasBaixa').disable();
        this.elementstepper1.next();      
      }
    }

    this.verifica.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    if (this.dataDialog.isProtesto){
      this.elementstepper1.next();
    }
  }

  ngOnDestroy() { }

  getConfig() {
    this.homeService.getConfiguracoesSistema().subscribe(
      (res) => this.configuracoesSistema = res
    )
  }
  
  toggleNextButton() {
    const stepper1Form = this.form.get('formStepper1');
    if (stepper1Form && !this.dataDialog.isEdicao) {
      const isFormValid = stepper1Form.get('pessoa')?.value.id;
      this.nextButtonDisabled = !isFormValid;
    }
    if (this.dataDialog.isEdicao) {
      this.nextButtonDisabled = false;
    }
  }

  atualizaForms(loadingData) {
    this.form.get('formStepper1').patchValue({
      tipoCobranca: loadingData.tipoCobranca === 'assinatura' ? 2 : loadingData.tipoCobranca === 'parcelada' ? 3 : 1,
      pessoa: {
        pessoa: loadingData.contato.pessoa
      },
      enviarEmail: loadingData.emailsNotificacao ? true : false,
      emailNotificacao: loadingData.emailsNotificacao,
      notificacaoSms: loadingData.celularParaNotificacao,
    });
    this.form.get('formStepper1.tipoCobranca').disable();
    this.form.get('formStepper1.pessoa').disable();

    if (loadingData.contatoBeneficiarioFinal) {
      this.form.get("formStepper1").get("beneficiarioFinal").enable();
      this.form.get('formStepper1').patchValue({
        beneficiarioFinal: {
          contato: {
            pessoa: loadingData.contatoBeneficiarioFinal.pessoa,
          },
          documento: loadingData.documentoBeneficiarioFinal,
          tipoDocumento: loadingData.tipoDocumentoBeneficiarioFinal,
          nome: loadingData.nomeBeneficiarioFinal,
          razaoSocial: loadingData.razaoSocialBeneficiarioFinal,
        }
      });
      if (loadingData.contatoBeneficiarioFinal !== null) {
        this.Stepper1.getNomeNumeroContatoBeneficiarioFinal(loadingData.contatoBeneficiarioFinal);
      }
    } else {
      this.form.get("formStepper1").get("beneficiarioFinal").disable();
    }
    this.form.get('formStepper2').patchValue({
      metodoPagamento: loadingData.tipoPagamento,
      valorNominal: loadingData.titulo.valorNominal,
      intervaloAssinatura: loadingData.intervaloAssinatura,
      dataVencimento: loadingData.titulo.dataVencimento,
      fimAssinatura: loadingData.dataFimAssinatura,
      numeroParcelas: loadingData.numeroParcelas,
      mensagem: loadingData.mensagem,
      aplicarMulta: loadingData.titulo.multa > 0 ? true : false,
      aplicarDesconto: loadingData.titulo.dataLimiteDesconto ? true : false,
      juros: loadingData.titulo.juros,
      multa: loadingData.titulo.multa,
      pagamentoAposVencimento: '', // ?
      quantidadeDiasBaixa: loadingData.titulo.quantidadeDiasBaixa,
      incluirNumeroDocumento: (loadingData.numeroDocumento && loadingData.numeroDocumento !== "") ? true : false,
      numeroDocumento: loadingData.numeroDocumento,
      incluirEspecie: (loadingData.titulo.especie && loadingData.titulo.especie !== "") ? true : false,
      especie: loadingData.titulo.especie,
      incluirProtesto : 
        ((loadingData.titulo.quantidadeDiasProtesto && 
          loadingData.titulo.quantidadeDiasProtesto !== "" &&
          loadingData.titulo.tipoProtesto && 
          loadingData.titulo.tipoProtesto !== "" &&
          loadingData.endossoProtesto && 
          loadingData.endossoProtesto !== "" &&
          loadingData.aceite && 
          loadingData.aceite !== "" &&
          loadingData.autorizacaoRepasse && 
          loadingData.autorizacaoRepasse !== "") || this.dataDialog.isProtesto)
        ? true 
        : false,
      diasDeProtesto: loadingData.titulo.quantidadeDiasProtesto? loadingData.titulo.quantidadeDiasProtesto : this.empresaLogada?.configuracao?.diasDeProtesto,
      tipoProtesto: loadingData.titulo.tipoProtesto,
      endossoProtesto: loadingData.endossoProtesto,
      aceite: loadingData.aceite,
      autorizacaoRepasse:  loadingData.autorizacaoRepasse
    });

    if (loadingData.titulo.dataLimiteDesconto && loadingData.titulo.valorDesconto) {
      const percentual = (Number(loadingData.titulo.valorDesconto) / Number(loadingData.titulo.valorNominal)) * 100
      this.Stepper2.addDesconto(loadingData.titulo.valorDesconto, loadingData.titulo.dataLimiteDesconto, percentual)
    }
    if (loadingData.titulo.dataLimiteDesconto2 && loadingData.titulo.valorDesconto2) {
      const percentual = (Number(loadingData.titulo.valorDesconto2) / Number(loadingData.titulo.valorNominal)) * 100
      this.Stepper2.addDesconto(loadingData.titulo.valorDesconto2, loadingData.titulo.dataLimiteDesconto2, percentual)
    }
    if (loadingData.titulo.dataLimiteDesconto3 && loadingData.titulo.valorDesconto3) {
      const percentual = (Number(loadingData.titulo.valorDesconto3) / Number(loadingData.titulo.valorNominal)) * 100
      this.Stepper2.addDesconto(loadingData.titulo.valorDesconto3, loadingData.titulo.dataLimiteDesconto3, percentual)
    }
    if(this.dataDialog.isEdicao) {
      this.Stepper2.verificaVencimento(loadingData.titulo.dataVencimento)
    }

    this.form.get('formStepper2.metodoPagamento').disable();
    this.form.get('formStepper2.intervaloAssinatura').disable();
    this.form.get('formStepper2.fimAssinatura').disable();
    this.form.get('formStepper2.numeroParcelas').disable();
    this.form.get('formStepper2.incluirNumeroDocumentoEspecie').disable();
    this.form.get('formStepper2.numeroDocumento').disable();
    this.form.get('formStepper2.especie').disable();
    this.form.get('formStepper2.pagamentoAposVencimento').disable();
    this.form.get('formStepper2.quantidadeDiasBaixa').disable();
    if (!this.dataDialog.isEdicao || this.dataDialog.isProtesto){
      this.form.get('formStepper2.aplicarDesconto').disable();
    } else {
      this.form.get('formStepper2.aplicarDesconto').enable();
      this.form.get('formStepper2.descontos').enable();
    }
    
    if(this.form.get('formStepper2.incluirProtesto').value === true){
      this.form.get('formStepper2.aceite').enable();
      this.form.get('formStepper2.autorizacaoRepasse').enable();
      this.form.get('formStepper2.diasDeProtesto').enable();
      this.form.get('formStepper2.tipoProtesto').enable();
      this.form.get('formStepper2.endossoProtesto').enable();
    }
  
    if(this.dataDialog.isProtesto == true && loadingData.statusProtesto){
      this.form.get('formStepper2.tipoProtesto').disable();
      this.form.get('formStepper2.endossoProtesto').disable();
      this.form.get('formStepper2.aceite').disable();
    }
  
    this.verifica.detectChanges();
  }

  fecha(): void {
    this.dialogRef.close();
  }

  getAjuda() {
    this.rootService.getAjudas().pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response[1] != '') this.urlAjuda = response[1];
          // Antecipacoes 0
          // Cobrancas 1
          // Contatos 2
          // Transferencias 3
          // Pagamentos 4

        },
        error => {
          this.processando = false;
          Swal.fire({
            title: 'Atenção',
            text: error.error,
            icon: 'warning',
            confirmButtonText: 'OK'
          });
        }
      );
  }

  async next() {

    // if (this.localizacaoComponent) {
    //   this.localizacaoComponent.getLocationAndCheckPermission();

    //   if (await this.localizacaoComponent.getLocationAndCheckPermission() !== "OK") {
    //     return Swal.fire(
    //       'Atenção',
    //       'Você precisa permitir o acesso a sua localização para prosseguir',
    //       'warning'
    //     );
    //   }
    // }

    if (this.elementstepper1.selectedIndex === 0) {
      if (this.form.get('formStepper1').valid || this.form.get('formStepper1.contatoOuPix').value == 2) {
        this.elementstepper1.next();
      } else {
        this.form.get('formStepper1').markAllAsTouched();
        Swal.fire('Atenção', 'Preencha todos os campos obrigatórios para prosseguir', 'warning');
      }
    }
  }

  public onStepChange(event: any): void {
    this.elementstepper2.selectedIndex = event.selectedIndex;
  }

  nomeOuRazaoSocial(data) {
    const pessoa = data.pessoa;

    if (pessoa) {
      const documentoInput = pessoa.documento.replace(/[^0-9]/g, '');
      if (documentoInput.length <= 11 && documentoInput.length != 0) {
        return pessoa.nome;
      } else {
        return pessoa.razaoSocial;
      }
    }
    return '';
  }

  getValorLiquido() {
    var valorLiquidoUnitario = "";
    const ehBoleto = !(this.form.get('formStepper2.metodoPagamento').value === 'cartaoCredito');

    // Se não é cartão desconta liquidação boleto
    if (ehBoleto) {
      valorLiquidoUnitario = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'BRL' })
        .format(((this.form.get('formStepper2.valorNominal').value) / this.form.get('formStepper2.numeroParcelas').value -
          Number(this.precos.precoLiquidacaoBoleto)));
    } else {
      valorLiquidoUnitario = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'BRL' })
        .format((((this.form.get('formStepper2.valorNominal').value - this.getCustoCobrancaCartao()) / this.form.get('formStepper2.numeroParcelas').value)));
    }

    if (this.form.get('formStepper1.tipoCobranca').value == 3 && !this.dataDialog.idCobranca) {
      var valorLiquidoTotal = "";
      if (ehBoleto) {
        valorLiquidoTotal = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'BRL' })
          .format(((this.form.get('formStepper2.valorNominal').value) -
            (this.form.get('formStepper2.numeroParcelas').value * Number(this.precos.precoLiquidacaoBoleto))));

      } else {
        valorLiquidoTotal = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'BRL' })
          .format(this.form.get('formStepper2.valorNominal').value - this.getCustoCobrancaCartao());

      }
      return valorLiquidoTotal + ' (' + this.form.get('formStepper2.numeroParcelas').value + ' parcelas de ' + valorLiquidoUnitario + ')';
    }
    return valorLiquidoUnitario;
  }

  getCustoCobrancaCartao() {
    return ((this.form.get('formStepper2.valorNominal').value || 0.0) * ((this.precos.porcentagemMesPagamentoCartao / 100))) + this.precos.precoPagamentoCartao
  }

  diasDescontoAntesDoVencimento(dataLimite) {
    if (dataLimite) {
      const vencimento = formatDistanceStrict(
        endOfDay(new Date(dataLimite)),
        endOfDay(new Date(this.form.get('formStepper2.dataVencimento').value)),
        {
          locale: ptBR
        }
      );

      return vencimento;
    }
  }

  verificaPraFinalizar() {
    if (this.elementstepper1.selectedIndex === 1) {

      if (this.dataDialog.idCobranca) {
        this.atualizar();
      } else {
        if (this.dataDialog.isEdicaoLote){
          this.atualizarLote();
        } else {
          this.finalizar();
        }
      }
      //  else {
      //   this.form.get('formStepper2').markAllAsTouched();
      //   Swal.fire('Atenção', 'Preencha todos os campos obrigatórios para prosseguir', 'warning');
      // }
    }
  }

  atualizar() {
    const atualizarData = this.formataEnvioAtualizar();

    this.askAtualizar(atualizarData, this.form.get('formStepper1.tipoCobranca').value, this.form.get('formStepper2.numeroParcelas').value);
  }

  atualizarLote(){
    const atualizarData = this.formataEnvioAtualizarLote();

    this.askAtualizar(atualizarData, 1);
  }

  askAtualizar(atualizarData, tipoCobranca, numeroParcelas?) {
    tipoCobranca = tipoCobranca === 2 ? 'assinatura' : tipoCobranca === 3 ? 'parcelada' : 'avulsa';
    if (tipoCobranca === 'assinatura') {
      numeroParcelas = this.dataDialog.numeroParcelas;
    }

    const dialog = this.dialog.open(ConfirmarEditarCobrancaComponent, {
      data: {
        atualizarData: atualizarData,
        numeroParcelas: numeroParcelas,
        tipoCobranca: tipoCobranca,
        editouCobranca: false,
        isEdicaoLote: this.dataDialog.isEdicaoLote ? this.dataDialog.isEdicaoLote : false,
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        if (result.editouCobranca === true) {
          this.dialog.closeAll();
        }
      }
    });
  }

  finalizar(repetirUltimaCobranca?) {
    this.processando = true;

    const cobrancaData = this.formataEnvioNovo(repetirUltimaCobranca);
    if (!this.form.get('formStepper2.pagamentoAposVencimento').value) {
      this.form.get('formStepper2.dataBaixaAutomatica').setValue(null);
    }
    this.cobrancaService
      .emitirCobranca(cobrancaData)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.processando = false;

          if (response == 0) {
            Swal.fire({
              title: 'Cobrança duplicada',
              icon: 'warning',
              text: 'Uma cobrança com as mesmas informações acabou de ser emitida. Deseja repeti-la?',
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            }).then((result) => {

              if (result.value) {
                this.finalizar(true);
              }
            }, error => {
              console.error(error);
              Swal.fire('Erro', error.error, 'error');
            });
          } else {
            // tipoCobranca: +this.form.get('formStepper1.tipoCobranca').value, // 1 avulsa, 2 assinatura, 3 parcelado
            let subtexto = +this.form.get('formStepper1.tipoCobranca').value == 1 ? "Você poderá acompanhar o status do pagamento da cobrança na aba 'Avulsas' em Cobranças" : +this.form.get('formStepper1.tipoCobranca').value == 2 ? "Você poderá acompanhar o status do pagamento de cada parcela na aba 'Assinaturas' em Cobranças" : "Você poderá acompanhar o status do pagamento de cada parcela na aba 'Parcelas' em Cobranças";
            let texto = +this.form.get('formStepper1.tipoCobranca').value == 1 ?
              `${this.form.get('formStepper1.pessoa').value.pessoa.nome} receberá a cobrança hoje e futuros alertas sobre o vencimento por e-mail` : +this.form.get('formStepper1.tipoCobranca').value == 2 ? `${this.form.get('formStepper1.pessoa').value.pessoa.nome} receberá a primeira cobrança hoje e futuros alertas sobre o vencimento por e-mail` : `${this.form.get('formStepper1.pessoa').value.pessoa.nome} receberá a primeira cobrança hoje e futuros alertas sobre o vencimento por e-mail`;
            const dialog = this.dialog.open(ModalConfirmacaoComponent, {
              data: {
                titulo: 'Cobrança emitida com sucesso!',
                texto: texto,
                subtexto: subtexto,
                btnTexto: 'Emitir nova cobrança',
                whatsapp: true,
                checkout: true
              },
              width: '600px',
              backdropClass: 'modal-alerta'
            });
            dialog.afterClosed().subscribe(result => {
              if (result) {
                if (result.finalizar === true) {
                  this.fecha();
                } else if (result.whatsapp === true) {
                  let target = `https://api.whatsapp.com/send?text=${encodeURIComponent(`Olá uma cobrança foi gerada e enviada para o seu e-mail, você pode acessar pelo link: ${this.env.url.appSite}/#/fatura/visualizar/${response}`)}`
                  let a = document.createElement('a')
                  a.target = '_blank';
                  a.href = target;
                  a.click();
                  this.fecha();
                  this.router.navigate([
                    '/privado/cobranca'
                  ]);
                } else if (result.nova === true) {
                  this.dialogRef.close({
                    nova: true
                  });
                } else if (result.checkout === true) {
                  this.fecha();
                  window.open(`/#/fatura/visualizar/${response}`, '_blank');
                }
              }
              window.location.reload();
            });
          }
        },
        error => {
          console.error(error);
          Swal.fire('Erro', error.error, 'error');
          this.processando = false;
        }
      );
  }

  formataEnvioAtualizar() {
    this.form.get('formStepper2.multa').setValue(this.form.get('formStepper2.multa').value.toString().replace(/,/i, ".").replace(/%/i, ""));
    this.form.get('formStepper2.juros').setValue(this.form.get('formStepper2.juros').value.toString().replace(/,/i, ".").replace(/%/i, ""));
    const data = {
      id_cobranca: +this.dataDialog.idCobranca,
      valor: +this.form.get('formStepper2.valorNominal').value,
      data_vencimento: (moment(this.form.get('formStepper2.dataVencimento').value).set("hour", 23)).toISOString(),
      juros: +this.form.get('formStepper2.juros').value.toString(),
      multa: +this.form.get('formStepper2.multa').value.toString(),
      email_notificacao: this.formataEmailAtualizar(),
      celular_notificacao: this.form.get('formStepper1.notificacaoSms').value,
      descricao: this.form.get('formStepper2.mensagem').value,
      notificar_pagador: null,
      atualizar_demais_cobrancas: null,
      // Dados do protesto
      quantidadeDiasProtesto: 
        this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get('formStepper2.diasDeProtesto').value 
          : 0,
      tipoProtesto: 
        this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get('formStepper2.tipoProtesto').value 
          : null,
      endossoProtesto: 
        this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get('formStepper2.endossoProtesto').value 
          : "BRANCO",
      aceite: 
        this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get("formStepper2.aceite").value 
          : null,
      autorizacaoRepasse: 
        (this.form.get('formStepper2.incluirProtesto').value === true && this.form.get("formStepper2.autorizacaoRepasse").value)
          ? this.form.get("formStepper2.autorizacaoRepasse").value 
          : "NAO",
      // Dados do beneficiário final
      contatoBeneficiarioFinalId:
        this.form.get('formStepper1.beneficiarioFinal.contato').value !== null
          ? this.form.get('formStepper1.beneficiarioFinal.contato').value.id
          : null,
      documentoBeneficiarioFinal:
        this.form.get("formStepper1.beneficiarioFinal.documento").value !== null
          ? this.form.get("formStepper1.beneficiarioFinal.documento").value.replace(/[^0-9]/g, '')
          : null,
      tipoDocumentoBeneficiarioFinal: this.form.get("formStepper1.beneficiarioFinal.tipoDocumento").value,
      nomeBeneficiarioFinal: this.form.get("formStepper1.beneficiarioFinal.nome").value,
      razaoSocialBeneficiarioFinal: this.form.get("formStepper1.beneficiarioFinal.razaoSocial").value,
      email_notificacaoBeneficiarioFinal: this.formataEmailAtualizarBeneficiarioFinal(),
      notificacaoSmsBeneficiarioFinal: this.form.get('formStepper1.beneficiarioFinal.notificacaoSms').value,
      // Número do documento
      numeroDocumento:
        this.form.get('formStepper2.numeroDocumento').value
          ? this.form.get('formStepper2.numeroDocumento').value
          : null,
      // Descontos
      dataLimiteDesconto: 
      (this.form.get('formStepper2.descontos') as FormArray).length > 0
        ? moment((this.form.get('formStepper2.descontos') as FormArray).at(0).get('dataLimiteDesconto').value).set("hour", 23).toISOString()
        : null,
      valorDesconto: 
      (this.form.get('formStepper2.descontos') as FormArray).length > 0
        ? (this.form.get('formStepper2.descontos') as FormArray).at(0).get('valorDesconto').value
        : null,
      dataLimiteDesconto2: 
      (this.form.get('formStepper2.descontos') as FormArray).length > 1
        ? moment((this.form.get('formStepper2.descontos') as FormArray).at(1).get('dataLimiteDesconto').value).set("hour", 23).toISOString()
        : null,
      valorDesconto2: 
      (this.form.get('formStepper2.descontos') as FormArray).length > 1
        ? (this.form.get('formStepper2.descontos') as FormArray).at(1).get('valorDesconto').value
        : null,
      dataLimiteDesconto3: 
      (this.form.get('formStepper2.descontos') as FormArray).length > 2
        ? moment((this.form.get('formStepper2.descontos') as FormArray).at(2).get('dataLimiteDesconto').value).set("hour", 23).toISOString()
        : null,
      valorDesconto3: 
      (this.form.get('formStepper2.descontos') as FormArray).length > 2
        ? (this.form.get('formStepper2.descontos') as FormArray).at(2).get('valorDesconto').value
        : null,
    }
    console.log(data)
    return data;
  }

  formataEnvioAtualizarLote() {
    const data: CobrancaEdicaoLote[] = [];
  
    this.form.get('formStepper2.multa').setValue(
      this.form.get('formStepper2.multa').value.toString().replace(/,/i, ".").replace(/%/i, "")
    );
    this.form.get('formStepper2.juros').setValue(
      this.form.get('formStepper2.juros').value.toString().replace(/,/i, ".").replace(/%/i, "")
    );
  
    this.dataDialog.listaIdcobrancas.forEach((id) => {
        const cobrancaEditada: CobrancaEdicaoLote = {
          id_cobranca: +id,
          valor: +this.form.get('formStepper2.valorNominal').value,
          data_vencimento: (moment(this.form.get('formStepper2.dataVencimento').value).set("hour", 23)).toISOString(),
          juros: +this.form.get('formStepper2.juros').value.toString(),
          multa: +this.form.get('formStepper2.multa').value.toString(),
          descricao: this.form.get('formStepper2.mensagem').value,
          quantidadeDiasProtesto: 
          this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get('formStepper2.diasDeProtesto').value 
          : 0,
          tipoProtesto: 
          this.form.get('formStepper2.incluirProtesto').value === true 
            ? this.form.get('formStepper2.tipoProtesto').value 
            : null,
          aceite: 
          this.form.get('formStepper2.incluirProtesto').value === true 
            ? this.form.get("formStepper2.aceite").value 
            : null,
          autorizacaoRepasse: 
          (this.form.get('formStepper2.incluirProtesto').value === true && this.form.get("formStepper2.autorizacaoRepasse").value)
            ? this.form.get("formStepper2.autorizacaoRepasse").value 
            : "NAO",
          // Descontos
          dataLimiteDesconto: 
          (this.form.get('formStepper2.descontos') as FormArray).length > 0
            ? moment((this.form.get('formStepper2.descontos') as FormArray).at(0).get('dataLimiteDesconto').value).set("hour", 23).toISOString()
            : null,
          valorDesconto: 
          (this.form.get('formStepper2.descontos') as FormArray).length > 0
            ? (this.form.get('formStepper2.descontos') as FormArray).at(0).get('valorDesconto').value
            : null,
          dataLimiteDesconto2: 
          (this.form.get('formStepper2.descontos') as FormArray).length > 1
            ? moment((this.form.get('formStepper2.descontos') as FormArray).at(1).get('dataLimiteDesconto').value).set("hour", 23).toISOString()
            : null,
          valorDesconto2: 
          (this.form.get('formStepper2.descontos') as FormArray).length > 1
            ? (this.form.get('formStepper2.descontos') as FormArray).at(1).get('valorDesconto').value
            : null,
          dataLimiteDesconto3: 
          (this.form.get('formStepper2.descontos') as FormArray).length > 2
            ? moment((this.form.get('formStepper2.descontos') as FormArray).at(2).get('dataLimiteDesconto').value).set("hour", 23).toISOString()
            : null,
          valorDesconto3: 
          (this.form.get('formStepper2.descontos') as FormArray).length > 2
            ? (this.form.get('formStepper2.descontos') as FormArray).at(2).get('valorDesconto').value
            : null,
        };
        data.push(cobrancaEditada);
      });
      return data;
  }
  

  formataEmailAtualizar() {
    let emailNotificacao = [];
    this.form.get('formStepper1.emailNotificacao').value.forEach(element => {
      emailNotificacao.push({ email: element.email, id_cobranca: this.dataDialog.idCobranca });
    });

    return emailNotificacao;
  }

  formataEmailAtualizarBeneficiarioFinal() {
    let emailNotificacao = [];
    this.form.get('formStepper1.beneficiarioFinal.emailNotificacao').value.forEach(element => {
      emailNotificacao.push({ email: element.email, id_cobranca: this.dataDialog.idCobranca });
    });

    return emailNotificacao;
  }

  formataEnvioNovo(repetirUltimaCobranca?) {
    const dataVencimento = (moment(this.form.get('formStepper2.dataVencimento').value).set("hour", 23)).toISOString();
    let emailNotificacao = [];
    this.form.get('formStepper1.emailNotificacao').value.forEach(element => {
      emailNotificacao.push(element.email);
    });
    let emailNotificacaoBeneficiarioFinal = [];
    this.form.get('formStepper1.beneficiarioFinal.emailNotificacao').value.forEach(element => {
      emailNotificacaoBeneficiarioFinal.push(element.email);
    });
    this.form.get('formStepper2.multa').setValue(this.form.get('formStepper2.multa').value.toString().replace(/,/i, ".").replace(/%/i, ""));
    this.form.get('formStepper2.juros').setValue(this.form.get('formStepper2.juros').value.toString().replace(/,/i, ".").replace(/%/i, ""));

    const obj: any = {
      contatoId: this.form.get('formStepper1.pessoa').value.id,
      titulo: {
        dataVencimento: dataVencimento,
        valorNominal: +(this.form.get('formStepper2.valorNominal').value / this.form.get('formStepper2.numeroParcelas').value).toFixed(2),
        quantidadeDiasBaixa: +this.form.get('formStepper2.quantidadeDiasBaixa').value,
        quantidadeDiasMulta: 0,
        juros: (this.form.get('formStepper2.juros').value.toString()),
        multa: (this.form.get('formStepper2.multa').value.toString()),
        // Espécie da cobrança - Envia o código 99 (outros) como padrão
        especie: this.form.get('formStepper2.especie').value
          ? this.form.get('formStepper2.especie').value
          : '99',
        // Dados do protesto
        quantidadeDiasProtesto: 
          this.form.get('formStepper2.incluirProtesto').value === true 
            ? this.form.get('formStepper2.diasDeProtesto').value 
            : 0,
        tipoProtesto: 
          this.form.get('formStepper2.incluirProtesto').value === true 
            ? this.form.get('formStepper2.tipoProtesto').value 
            : null,
      },
      mensagem: this.form.get('formStepper2.mensagem').value,
      tipoCobranca: +this.form.get('formStepper1.tipoCobranca').value, // 1 avulsa, 2 assinatura, 3 parcelado
      intervaloAssinatura: this.form.get('formStepper2.intervaloAssinatura').value,
      inicioAssinatura: +this.form.get('formStepper1.tipoCobranca').value === 2 ? dataVencimento : null,
      fimAssinatura: +this.form.get('formStepper1.tipoCobranca').value === 2
        ? (moment(this.form.get('formStepper2.fimAssinatura').value).set("hour", 6)).toISOString()
        : null,
      numeroParcelas: this.form.get('formStepper2.numeroParcelas').value,
      notificacaoSms: this.form.get('formStepper1.notificacaoSms').value,
      vencimentoPrimeiraParcela: +this.form.get('formStepper1.tipoCobranca').value === 3 ? dataVencimento : null,
      emailNotificacao: this.form.get('formStepper1.enviarEmail').value === true ? emailNotificacao : null,
      repetirUltimaCobranca: repetirUltimaCobranca || false,
      tipoPagamento: this.form.get('formStepper2.metodoPagamento').value, //boleto ou cartaoCredito
      dataBaixaAutomatica: this.form.get("formStepper2.dataBaixaAutomatica").value,
      // Dados do protesto
      aceite: 
        this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get("formStepper2.aceite").value 
          : null,
      autorizacaoRepasse: 
        this.form.get('formStepper2.incluirProtesto').value === true
          ? this.form.get("formStepper2.autorizacaoRepasse").value 
          : "NAO",
      endossoProtesto: 
        this.form.get('formStepper2.incluirProtesto').value === true 
          ? this.form.get('formStepper2.endossoProtesto').value 
          : "BRANCO",
      // Dados do beneficiário final
      documentoBeneficiarioFinal:
        this.form.get("formStepper1.beneficiarioFinal.documento").value !== null
          ? this.form.get("formStepper1.beneficiarioFinal.documento").value.replace(/[^0-9]/g, '')
          : null,
      contatoBeneficiarioFinalId:
        this.form.get('formStepper1.beneficiarioFinal.contato').value !== null
          ? this.form.get('formStepper1.beneficiarioFinal.contato').value.id
          : null,
      tipoDocumentoBeneficiarioFinal: this.form.get("formStepper1.beneficiarioFinal.tipoDocumento").value,
      nomeBeneficiarioFinal: this.form.get("formStepper1.beneficiarioFinal.nome").value,
      razaoSocialBeneficiarioFinal: this.form.get("formStepper1.beneficiarioFinal.razaoSocial").value,
      emailNotificacaoBeneficiarioFinal: emailNotificacaoBeneficiarioFinal.length > 0 ? emailNotificacaoBeneficiarioFinal : null,
      notificacaoSmsBeneficiarioFinal: this.form.get('formStepper1.beneficiarioFinal.notificacaoSms').value,
      // Número do documento
      numeroDocumento:
        this.form.get('formStepper2.numeroDocumento').value !== null && this.form.get('formStepper2.numeroDocumento').value.trim() !== ''
          ? this.form.get('formStepper2.numeroDocumento').value.replace(/\s/g, '').trim()
          : null,

      // carteira
      carteiraCobranca: this.form.get('formStepper2.nossoNumero').value !== null 
      ? this.form.get('formStepper2.carteira').value 
      : "carteira2",
      nossoNumero: this.form.get('formStepper2.nossoNumero').value !== null 
      ? this.form.get('formStepper2.nossoNumero').value 
      : "0000000000000",
    };

    if (this.form.get('formStepper2.aplicarDesconto').value) {
      const formDescontos = this.form.get('formStepper2.descontos') as FormArray
      formDescontos.controls.forEach((desconto: FormGroup, i) => {
        switch (i) {
          case 0:
            obj.titulo.valorDesconto = Number(desconto.get('valorDesconto').value)
            obj.titulo.dataLimiteDesconto = (moment(desconto.get('dataLimiteDesconto').value).set("hour", 23)).toISOString()
            break;
          case 1:
            obj.titulo.valorDesconto2 = Number(desconto.get('valorDesconto').value)
            obj.titulo.dataLimiteDesconto2 = (moment(desconto.get('dataLimiteDesconto').value).set("hour", 23)).toISOString()
            break;
          case 2:
            obj.titulo.valorDesconto3 = Number(desconto.get('valorDesconto').value)
            obj.titulo.dataLimiteDesconto3 = (moment(desconto.get('dataLimiteDesconto').value).set("hour", 23)).toISOString()
            break;
        }
      })
    }

    return obj;
  }

  buscaDataBaixa() {
    if (this.form.get("formStepper2.dataVencimento") && this.form.get("formStepper2.quantidadeDiasBaixa")) {
      this.cobrancaService.buscarDataBaixa(this.form.get("formStepper2.dataVencimento").value, this.form.get("formStepper2.quantidadeDiasBaixa").value)
        .toPromise().then((data) => {
          this.dataBaixa = data;
          this.form.get("formStepper2.dataBaixaAutomatica").setValue(data);
        }, error => {
          Swal.fire('Erro', error.error, 'error');
          console.log(error);
        })
    }
  }

  permissaoGeolocalizacao() {
    this.localizacaoService.listarConfigPorTipo(this.tipoLocalizacao)
      .subscribe((res) => {
        this.tipoLocalizacaoLiberado = res;
      }, error => {
        console.error(error);
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
      })
  }

  buildPixCobranca(): QrCodePixDTO {
    const data = {
      chave: this.form.get('formStepper2.chavePixCobrador').value,
      valor: this.form.get('formStepper2.valorPix').value,
      emailNotificacao: this.form.get('formStepper1.emailNotificacao').value[0].email,
      telefoneNotificacao: this.form.get('formStepper1.notificacaoSms').value,
      descricao: this.form.get('formStepper2.descricaoPix').value,
    };

    return data;
  }

  buildPixCobrancaDinamica() {

    const data = {
      chave: this.form.get('formStepper2.chavePixCobrador').value,
      valor: this.form.get('formStepper2.valorPix').value,
      emailNotificacao: this.form.get('formStepper1.emailNotificacao').value[0].email,
      telefoneNotificacao: this.form.get('formStepper1.notificacaoSms').value,
      modalidadeAlteracao: this.form.get('formStepper2.modalidadeAlteracao').value,
      expiracaoQrInicial: this.form.get('formStepper2.expiracaoQR').value ? this.form.get('formStepper2.expiracaoQR').value : null,
      valorTroco: this.form.get('formStepper2.valorTroco').value ? this.form.get('formStepper2.valorTroco').value : null,
      valorSaque: this.form.get('formStepper2.valorSaque').value ? this.form.get('formStepper2.valorSaque').value : null,
      modalidadeAltTroco: this.form.get('formStepper2.modalidadeAltTroco').value !== null && this.form.get('formStepper2.modalidadeAltTroco').value !== undefined ? this.form.get('formStepper2.modalidadeAltTroco').value : null,
      modalidadeAltSaque: this.form.get('formStepper2.modalidadeAltSaque').value !== null && this.form.get('formStepper2.modalidadeAltSaque').value !== undefined ? this.form.get('formStepper2.modalidadeAltSaque').value : null,
      reutilizavel: this.form.get('formStepper2.reutilizavel').value ? this.form.get('formStepper2.reutilizavel').value : null,
      cpfPagador: this.form.get('formStepper2.documentoPagadorPix').value <= 11 ? this.form.get('formStepper2.documentoPagadorPix').value : null,
      cnpjPagador: this.form.get('formStepper2.documentoPagadorPix').value >= 14 ? this.form.get('formStepper2.documentoPagadorPix').value : null,
      nomePagador: this.form.get('formStepper2.nomePagadorPix').value ? this.form.get('formStepper2.nomePagadorPix').value : null,
      descricao: this.form.get('formStepper2.descricaoPix').value,
    }

    return data;
  }

  buildPixCobrancaVencimento() {
    const data = {
      chave: this.form.get('formStepper2.chavePixCobrador').value,
      valor: this.form.get('formStepper2.valorPix').value,
      emailNotificacao: this.form.get('formStepper1.emailNotificacao').value[0].email,
      telefoneNotificacao: this.form.get('formStepper1.notificacaoSms').value,
      dtVenc: this.form.get('formStepper2.dtVenc').value ? this.form.get('formStepper2.dtVenc').value : null,
      juros: this.form.get('formStepper2.jurosPix').value ? this.form.get('formStepper2.jurosPix').value : null,
      multa: this.form.get('formStepper2.multaPix').value ? this.form.get('formStepper2.multaPix').value : null,
      desconto: this.form.get('formStepper2.descontoPix').value ? this.form.get('formStepper2.descontoPix').value : null,
      abatimento: this.form.get('formStepper2.abatimentoPix').value ? this.form.get('formStepper2.abatimentoPix').value : null,
      descricao: this.form.get('formStepper2.descricaoPix').value,
      cpfPagador: this.form.get('formStepper2.documentoPagadorPix').value.length <= 12 ? this.form.get('formStepper2.documentoPagadorPix').value : null,
      cnpjPagador: this.form.get('formStepper2.documentoPagadorPix').value.length >= 14 ? this.form.get('formStepper2.documentoPagadorPix').value : null,
      nomePagador: this.form.get('formStepper2.nomePagadorPix').value ? this.form.get('formStepper2.nomePagadorPix').value : null
    }

    return data;
  }


  estaticoOuDinamico() {
    if (this.form.get('formStepper2.metodoPagamento').value === 'pixEstatico') {
      this.cobrancaQrCodePix();
    } else if (this.form.get('formStepper2.metodoPagamento').value === 'pixDinamico' && this.form.get('formStepper2.configuracoesVencimento').value === false) {
      this.cobrancaQrCodeDinamicoPix();
    } else if (this.form.get('formStepper2.metodoPagamento').value === 'pixDinamico' && this.form.get('formStepper2.configuracoesVencimento').value === true) {
      this.cobrancaQrCodeVencimentoPix();
    }
  }

  cobrancaQrCodeVencimentoPix() {
    this.pixService.gerarQrCodeVencimento(this.buildPixCobrancaVencimento())
      .subscribe((res) => {
        const dialog = this.dialog.open(PixModalCobrancaComponent, {
          data: {
            qrcode: res.imagemQrCodeInBase64,
            payload: res.qrCodePayload,
            idDocumento: res.idDocumento,
            dadosQr: {
              chave: this.form.get('formStepper2.chavePixCobrador').value,
              valor: this.form.get('formStepper2.valorPix').value,
            }
          },
          width: '581px',
          height: '673px',
        }).afterClosed().subscribe((res) => {
          if (res) {
            this.dialogRef.close();
          }
        });
      }, error => {
        console.error(error);
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
      })
  }


  cobrancaQrCodePix() {
    this.pixService.gerarQrCodeCobranca(this.buildPixCobranca())
      .subscribe((res) => {
        const dialog = this.dialog.open(PixModalCobrancaComponent, {
          data: {
            qrcode: res.imagemQrCodeInBase64,
            payload: res.qrCodePayload,
            idDocumento: res.idDocumento,
            dadosQr: {
              chave: this.form.get('formStepper2.chavePixCobrador').value,
              valor: this.form.get('formStepper2.valorPix').value,
            }
          },
          width: '581px',
          height: '673px',
        }).afterClosed().subscribe((res) => {
          if (res) {
            this.dialogRef.close();
          }
        });
      }, error => {
        console.error(error);
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
      })
  }


  cobrancaQrCodeDinamicoPix() {
    this.pixService.gerarQrCodeDinamico(this.buildPixCobrancaDinamica())
      .subscribe((res) => {
        const dialog = this.dialog.open(PixModalCobrancaComponent, {
          data: {
            qrcode: res.imagemQrCodeInBase64,
            payload: res.qrCodePayload,
            idDocumento: res.idDocumento,
            dadosQr: {
              chave: this.form.get('formStepper2.chavePixCobrador').value,
              valor: this.form.get('formStepper2.valorPix').value,
            }
          },
          width: '581px',
          height: '673px',
        }).afterClosed().subscribe((res) => {
          if (res) {
            this.dialogRef.close();
          }
        });
      }, error => {
        console.error(error);
        Swal.fire({
          title: 'Erro',
          text: error.error,
          icon: 'error',
        })
      })
  }

  abrirModalPix() {
    const dialog = this.dialog.open(PixModalCobrancaComponent, {
      width: '581px',
      height: '673px',
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.dialogRef.close();
      }
    });
  }
}

interface CobrancaEdicaoLote {
  id_cobranca: number;
  valor: number;
  data_vencimento: string;
  juros: number;
  multa: number;
  descricao: string;
  quantidadeDiasProtesto: number;
  tipoProtesto: string | null;
  aceite: string | null;
  autorizacaoRepasse: string;
  dataLimiteDesconto: string | null;
  valorDesconto: string | null;
  dataLimiteDesconto2: string | null;
  valorDesconto2: string | null;
  dataLimiteDesconto3: string | null;
  valorDesconto3: string | null;

}


