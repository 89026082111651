import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { UsuarioEndpointService } from "@service/usuario-endpoint.service";
import { startWith, map } from "rxjs/operators";
import {
  IPessoaService,
  PessoaServiceBuilder,
} from "@service/pessoa-endpoint.service";
import swal from "sweetalert2";
import { AutenticacaoEndpointService } from "@service/autenticacao-endpoint.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { Router } from "@angular/router";
import { EmpresaEndpointService } from "@service/empresa-endpoint.service";
import { environment } from "@src/environments/environment.default";
import { RegraEmpresaDTO } from "@project/src/app/model/pessoa/regra-empresa-dto.model";
import { MatDialog } from "@angular/material/dialog";
import { isNumber, isString } from "highcharts";
import { PrimeirosPassosComponent } from "../primeiros-passos/primeiros-passos.component";

@Component({
  selector: "app-selecionar-corporacao",
  templateUrl: "./selecionar-corporacao.component.html",
  styleUrls: ["./selecionar-corporacao.component.less"],
})
export class SelecionarCorporacaoComponent implements OnInit, OnDestroy {
  private service: IPessoaService
  form: FormGroup;
  corporacaoList: any = [];
  empresaList: any = [];
  filtroCorporacao: Observable<any[]>;
  filtroEmpresa: Observable<any[]>;
  usuarioLogado: any = [];
  colaboradorLogado: any = [];
  processando = false;
  env = environment;
  resetCorporacao: number = 1;
  resetEmpresa: number = 1;
  corporacaoNome: any;
  empresaNome: string;
  criarEmpresa: any;
  usuarioEmail: any;

  constructor(
    private fb: FormBuilder,
    private usuario: UsuarioEndpointService,
    private injector: Injector,
    private autenticacao: AutenticacaoEndpointService,
    private empresaService: EmpresaEndpointService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      corporacao: new FormControl(null, [Validators.required]),
      empresa: new FormControl(null),
    });

    this.loading();

    this.form.get("corporacao").setValue(
      this.autenticacao.regraCorporacao ? this.autenticacao.regraCorporacao.corporacao : ""
    );

    this.corporacaoNome = this.autenticacao.regraCorporacao.corporacao.nome;

    if (this.autenticacao.regraEmpresa) {
      this.empresaNome = this.displayEmp(this.autenticacao.regraEmpresa.empresa);
    }

    this.form.get("empresa").setValue(this.autenticacao.regraEmpresa || "");

    this.service = PessoaServiceBuilder.getInstance(this.injector, "empresa");

    this.getCorporacoesUsuario();
    this.getEmpresasDaCorporacao(this.autenticacao.regraCorporacao.corporacao)
    this.getUsuarioLogado()
  }

  async getCorporacoesUsuario(query?: string) {
    await this.usuario
      .getCorporacoesDoUsuario(query)
      .pipe(untilDestroyed(this))
      .toPromise().then(
        (response) => {
          this.corporacaoList = response;
          this.disloading();
        },
        (error) => {
          this.disloading();
        }
      );
  }

  public _filterEmpresa(searchValue: any): RegraEmpresaDTO[] {
    if (isString(searchValue)) {
      const filterValue = searchValue;

      if (isNumber(filterValue)) {
        if (this.resetEmpresa === 1 && this.empresaNome != searchValue && this.autenticacao.regraEmpresa.empresa.tipoConta != null) {
          return this.empresaList.filter((empresa) =>
            empresa.pessoa.documento.toLowerCase().includes(this.getDifference(searchValue.toString(), this.empresaNome))
          );
        }
        return this.empresaList.filter((empresa) =>
          empresa.pessoa.documento.toLowerCase().includes(filterValue)
        );
      } else {
        if (this.resetEmpresa === 1 && this.empresaNome != searchValue && this.autenticacao.regraEmpresa.empresa.tipoConta != null) {
          return this.empresaList.filter((empresa) =>
            (empresa.pessoa.razaoSocial || empresa.pessoa.nome).toLowerCase().includes(this.getDifference(searchValue, this.empresaNome))
          );
        }
        return this.empresaList.filter((empresa) =>
          (empresa.pessoa.razaoSocial || empresa.pessoa.nome)
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      }
    }
  }

  displayCorp(corporacao): any {
    if (corporacao.nome) {
      this.corporacaoNome = corporacao.nome;
      return this.corporacaoNome;
    } else if (corporacao != null) {
      this.corporacaoNome = corporacao;
      return this.corporacaoNome;
    }
  }

  displayEmp(empresa: any): any {
    if (empresa === "") {
      return empresa;
    }

    if (empresa && !empresa.empresa) {
      if (empresa.tipoConta) {
        let compEmpresa = '';
        let tipoEmpresa = '';

        if (empresa.pessoa) {
          this.empresaNome = empresa.pessoa.razaoSocial;
        }

        const agencia = empresa.agencia ? empresa.agencia.padStart(4, '0') : environment.codAgencia;

        if (empresa.tipoConta === 'contaDigitalS') {
          compEmpresa = '000';
        } else {
          compEmpresa = environment.codBanco;
        }

        switch (empresa.tipoConta) {
          case 'contaDigitalS': {
            tipoEmpresa = 'C/DigitalS';
            break;
          }
          case 'contaCobrancaDigital': {
            tipoEmpresa = 'C/CobrancaDigital';
            break;
          }
          case 'contaCorrenteDigital': {
            tipoEmpresa = 'C/Corrente';
            break;
          }
          case 'contaVinculadaDigital': {
            tipoEmpresa = 'C/Vinculada';
            break;
          }
          case 'contaEscrowDigital': {
            tipoEmpresa = 'C/Escrow';
            break;
          }
          case 'contaBloqueioJudicial': {
            tipoEmpresa = 'C/Bloqueio';
            break;
          }
          case 'contaSalarioDigital': {
            tipoEmpresa = 'C/Salario';
            break;
          }
          case 'contaServicoDigital': {
            tipoEmpresa = 'C/Serviço';
            break;
          }
          case 'contaInvestidorDigital': {
            tipoEmpresa = 'C/Investidor';
            break;
          }
          case 'contaDigital': {
            tipoEmpresa = 'C/Digital';
            break;
          }
        }

        const contaEmpresa = empresa.numeroConta.substring(0, empresa.numeroConta.length - 1) + '-' + empresa.numeroConta.substring(empresa.numeroConta.length - 1, empresa.numeroConta.length);
        return (
          (empresa.pessoa.razaoSocial || empresa.pessoa.nome) + " - " + empresa.pessoa.documento +
          ' Bco ' + compEmpresa + ' - Ag: ' + agencia + ' - ' + tipoEmpresa + ': ' + contaEmpresa
        );
      } else {
        if (empresa.tipoConta === null) {
          if (empresa.pessoa.razaoSocial) {
            return empresa.pessoa.razaoSocial + ' - ' + empresa.pessoa.documento
          } else {
            return empresa.pessoa.nome + ' - ' + empresa.pessoa.documento
          }
        }
        return empresa;
      }
    } else {
      if (empresa.empresa.tipoConta) {
        let compEmpresa = '';
        let tipoEmpresa = '';

        if (empresa.empresa.pessoa) {
          this.empresaNome = empresa.empresa.pessoa.razaoSocial;
        }

        const agencia = empresa.empresa.agencia ? empresa.empresa.agencia.padStart(4, '0') : environment.codAgencia;

        if (empresa.empresa.tipoConta === 'contaDigitalS') {
          compEmpresa = '000';
        } else {
          compEmpresa = environment.codBanco;
        }

        switch (empresa.empresa.tipoConta) {
          case 'contaDigitalS': {
            tipoEmpresa = 'C/DigitalS';
            break;
          }
          case 'contaCobrancaDigital': {
            tipoEmpresa = 'C/CobrancaDigital';
            break;
          }
          case 'contaCorrenteDigital': {
            tipoEmpresa = 'C/Corrente';
            break;
          }
          case 'contaVinculadaDigital': {
            tipoEmpresa = 'C/Vinculada';
            break;
          }
          case 'contaEscrowDigital': {
            tipoEmpresa = 'C/Escrow';
            break;
          }
          case 'contaBloqueioJudicial': {
            tipoEmpresa = 'C/Bloqueio';
            break;
          }
          case 'contaSalarioDigital': {
            tipoEmpresa = 'C/Salario';
            break;
          }
          case 'contaServicoDigital': {
            tipoEmpresa = 'C/Serviço';
            break;
          }
          case 'contaInvestidorDigital': {
            tipoEmpresa = 'C/Investidor';
            break;
          }
          case 'contaDigital': {
            tipoEmpresa = 'C/Digital';
            break;
          }
        }

        const contaEmpresa = empresa.empresa.numeroConta.substring(0, empresa.empresa.numeroConta.length - 1) + '-' + empresa.empresa.numeroConta.substring(empresa.empresa.numeroConta.length - 1, empresa.empresa.numeroConta.length);
        return (
          (empresa.empresa.pessoa.razaoSocial || empresa.empresa.pessoa.nome) + " - " + empresa.empresa.pessoa.documento +
          ' Bco ' + compEmpresa + ' - Ag: ' + agencia + ' - ' + tipoEmpresa + ': ' + contaEmpresa
        );
      } else {
        if (empresa.empresa.tipoConta === null) {
          if (empresa.empresa.pessoa.razaoSocial) {
            return empresa.empresa.pessoa.razaoSocial + ' - ' + empresa.empresa.pessoa.documento
          } else {
            return empresa.empresa.pessoa.nome + ' - ' + empresa.empresa.pessoa.documento
          }
        }
        return empresa;
      }
    }
  }

  getErrorMessage(field) {
    if (this.form.get(field)) {
      return this.form.get(field).hasError("required") ? "Campo requerido" : "";
    }
  }

  getEmpresasDaCorporacao(corporacao) {
    this.loading();
    this.form.get('empresa').setValue('');
    
    let page = 0, size = 20;
    this.empresaService
      .listar({ idCorporacao: corporacao.id, page, size })
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.empresaList = res.content;

          this.filtroEmpresa = this.form.get("empresa").valueChanges.pipe(
            startWith(""),
            map((empresa) => this._filterEmpresa(empresa))
          );
          this.disloading();
        },
        (error) => {
          this.empresaList = [];
          this.form.get("empresa").setValue("");
          this.disloading();
          swal.fire("Erro", error.error, "error");
        }
      );
  }

  loading() {
    this.processando = true;
    this.form.disable();
  }

  disloading() {
    this.processando = false;
    this.form.enable();
  }

  alterar() {
    this.loading();

    const corporacaoId = this.form.get("corporacao").value.id;
    const empresaId = this.form.get("empresa").value
      ? this.form.get("empresa").value.id
      : null;

      if (!corporacaoId || !empresaId) {
        this.disloading();
       return swal.fire("Erro", "Selecione uma corporação e uma empresa", "error");
      }

    this.usuario
      .alterarVisao(corporacaoId, empresaId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.disloading();
          swal
            .fire({
              title: "Sucesso",
              text: response,
              icon: "success",
              confirmButtonText: "OK",
            })
            .then(() => {
              this.router.navigate(["/privado/principal"]).then(() => {
                window.location.reload();
              });
            });
        },
        (error) => {
          this.disloading();
          swal.fire("Erro", error.error, "error");
        }
      );
  }

  cadastrarEmpresa() {
    this.dialog.open(PrimeirosPassosComponent, {
      width: "1240px",
      data: {
        apenasCadastroPessoa: true,
      },
      backdropClass: "modal-maior",
    });
  }

  getComp(empresa: any) {
    if (empresa.tipoConta === 'contaDigitalS') {
      return '000';
    } else {
      return this.env.codBanco;
    }
  }

  getTipo(empresa: any) {
    if (empresa.tipoConta) {
      switch (empresa.tipoConta) {
        case 'contaDigitalS': { 
          return 'C/DigitalS';
        }
        case 'contaCobrancaDigital': {
          return 'C/ContaCobrancaDigital';
        }
        case 'contaCorrenteDigital': {
          return 'C/Corrente';
        }
        case 'contaVinculadaDigital': {
          return 'C/Vinculada';
        }
        case 'contaEscrowDigital': {
          return 'C/Escrow';
        }
        case 'contaBloqueioJudicial': {
          return 'C/Bloqueio';
        }
        case 'contaSalarioDigital': {
          return 'C/Salario';
        }
        case 'contaServicoDigital': {
          return 'C/Serviço';
        }
        case 'contaInvestidorDigital': {
          return 'C/Investidor';
        }
        case 'contaDigital': {
          return 'C/Digital';
        }
      }
    }
  }

  getAgencia(empresa: any) {
    if (empresa.agencia) {
      return empresa.agencia.padStart(4, '0');
    } else
      return this.env.codAgencia
  }

  calculaNroConta(empresa: any) {
    if (empresa.tipoConta != null) {
      var contaInst = empresa.numeroConta.substring(0, empresa.numeroConta.length - 1) + '-' + empresa.numeroConta.substring(empresa.numeroConta.length - 1, empresa.numeroConta.length);
      return contaInst;
    }
  }

  getUsuarioLogado() {
    this.usuario.obterUsuarioLogado()
      .pipe(untilDestroyed(this))
      .subscribe((usuario) => {
        this.usuarioLogado = usuario;
      })
  }

  resetFieldCorp(event: any) {
    if (this.resetCorporacao === 1) {
      if (this.corporacaoNome.length < event.length) {
        this.resetCorporacao = 0;
        event = this.getDifference(this.corporacaoNome, event);
      } else if (this.corporacaoNome.length > event.length) {
        this.resetCorporacao = 0;
        event = '';
      }
      this.displayCorp(event)
    }
    this.getCorporacoesUsuario(event);
  }

  atualizarCorporacao(corporacao) {
    this.resetCorporacao = 1;
    this.corporacaoNome = corporacao.nome;
  }

  resetFieldEmp(event: any) {
    if (this.resetEmpresa === 1) {
      if (this.form.get('empresa').value.length > event.length) {
        event = this.getDifference(this.form.get('empresa').value, event);
        this.resetEmpresa = 0;
      } else if (this.form.get('empresa').value.length < event.length) {
        event = '';
        this.resetEmpresa = 0;
      }
      this.displayEmp(event);
    }
  }

  atualizarEmpresa(empresa) {
    this.filtroEmpresa = this.form.get("empresa").valueChanges.pipe(
      startWith(""),
      map((empresa) => this._filterEmpresa(empresa))
    );

    this.resetEmpresa = 1;
    this.empresaNome = empresa;
  }

  getDifference(a: string, b: string) {
    var i = 0, j = 0;
    var vetorA = a.split('');
    var vetorB = b.split('');
    for (i = 0; i < vetorA.length; i++) {
      if (vetorA[i] !== vetorB[i]) {
        return vetorA[i];
      }
    }
    return '';
  }

  exibirTooltip(empresa: any) {
    if (empresa.tipoConta != null) {
      let bloqueada: string = empresa.moderacaoEmpresa == "bloqueada" ? " BLOQUEADA" : "";
      let encerrada: string = empresa.moderacaoEmpresa == "encerrada" ? " ENCERRADA" : "";
      switch (empresa.tipoConta) {
        case 'contaDigitalS': { 
          return 'Conta DigitalS - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case 'contaCobrancaDigital': {
          return 'Conta Cobrança Digital - ' + this.calculaNroConta(empresa) + bloqueada
        }
        case 'contaCorrenteDigital': {
          return 'Conta Corrente Digital - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaVinculadaDigital': {
          return 'Conta Vinculada Digital- ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaEscrowDigital': {
          return 'Conta Escrow Digital - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaBloqueioJudicial': {
          return 'Conta Bloqueio Judicial - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaSalarioDigital': {
          return 'Conta Salario Digital - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaServicoDigital': {
          return 'Conta Servico Digital - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaInvestidorDigital': {
          return 'Conta Investidor Digital - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
        case 'contaDigital': {
          return 'Conta Digital - ' + this.calculaNroConta(empresa) + bloqueada + encerrada
        }
      }
    }
  }

  ngOnDestroy() { }
}
