<div mat-dialog-content style="background: #EEEEEE; padding: 0; margin: 0 !important;">
  <div class="main-content" style="background: #EEEEEE;">
    <div class="container-fluid" style="padding: 0 !important;">
      <div class="row" style="justify-content: center; margin: 0 !important">
        <div class="col-12" style="padding: 0 !important;">

          <div class="card">
            <div [hidden]="!processando" style="padding: 10px 0 20px 0;">
              <div class="col-12">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            </div>

            <div class="card-body" *ngIf="!processando" style="padding: 0 !important;">
              <h2 mat-dialog-title class="titulo-modal" style="display: flex;">
                <div style="width: 100%">
                  <span id="empresa-logada">
                    <p></p>
                  </span>
                  Nova Exportação
                </div>
                <div style="display: flex; position: absolute; justify-content: end; width: 100%;">
                  <span id="fechar" (click)="fecha()" style="margin-right: 1%;">FECHAR</span>
                  <i (click)="fecha()" class="material-icons btn-fechar" style="margin-right: 3%;">close</i>
                </div>

              </h2>
              <div class="box-body" style="margin: 15px">
                <h4 class="col-12 mb-3 texto-principal">Escolha um tipo de extrato para prosseguir</h4>
                <div class="col-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Tipo de extrato</mat-label>
                    <mat-select [(ngModel)]="tipoExtrato">
                      <mat-option value="1">Extrato Personalizável</mat-option>
                      <mat-option value="2">Extrato Movimentações entre e na agência </mat-option>
                      <mat-option value="3">Extrato Integralização <small>(Arquivo único com todas as
                          receitas)</small></mat-option>
                      <mat-option value="4">Extrato diário por correntista <small>(Agrupado por
                          documento)</small></mat-option>
                      <div *ngIf="env.modulos.tesouraria.movimentacoesPorConta">
                        <mat-option value="5">Extrato movimentações por conta <small>(Arquivo zip com uma planilha para
                            cada conta do sistema)</small>
                        </mat-option>
                      </div>
                      <mat-option value="6">Relatório Simplificado</mat-option>
                      <mat-option value="7">Extrato Layout Contábil</mat-option>
                      <mat-option value="8">Extrato de Tarifas</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div [ngSwitch]="tipoExtrato">
                  <div *ngSwitchCase="'1'"><ng-container *ngTemplateOutlet="extratoPersonalizavel"></ng-container></div>
                  <div *ngSwitchCase="'2'"><ng-container *ngTemplateOutlet="extratoMovimentacoes"></ng-container></div>
                  <div *ngSwitchCase="'3'"><ng-container *ngTemplateOutlet="extratoIntegralizacao"></ng-container></div>
                  <div *ngSwitchCase="'4'"><ng-container *ngTemplateOutlet="extratoDiario"></ng-container></div>
                  <div *ngSwitchCase="'5'"><ng-container
                      *ngTemplateOutlet="extratoMovimentacoesPorConta"></ng-container></div>
                  <div *ngSwitchCase="'6'"><ng-container *ngTemplateOutlet="relatorioSimplificado"></ng-container></div>
                  <div *ngSwitchCase="'7'"><ng-container *ngTemplateOutlet="layoutContabil"></ng-container></div>
                  <div *ngSwitchCase="'8'"><ng-container *ngTemplateOutlet="extratoTarifas"></ng-container></div>
                </div>

                <div class="mt-4" style="text-align: center;">
                  <button [disabled]="isNotPreenchido()" mat-raised-button (click)="exportarDownload()"
                    class="btn mat-primary btn-acao">
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #extratoPersonalizavel>
  <form [formGroup]="formAgencia">
    <div class="w-100 d-flex flex-column" style="gap: 1rem">
      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar período:</label>
        <mat-form-field appearance="outline" style="width: 30%;">
          <mat-label>De</mat-label>
          <input readonly matInput [matDatepicker]="de" [max]="today" (click)="de.open()" formControlName="de">
          <mat-datepicker-toggle matSuffix [for]="de"></mat-datepicker-toggle>
          <mat-datepicker #de></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 30%;">
          <mat-label>Até</mat-label>
          <input readonly matInput [matDatepicker]="ate" [max]="today" (click)="ate.open()" formControlName="ate">
          <mat-datepicker-toggle matSuffix [for]="ate"></mat-datepicker-toggle>
          <mat-datepicker #ate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar banco:</label>
        <mat-button-toggle-group formControlName="banco">
          <mat-button-toggle value="000">Banco 000</mat-button-toggle>
          <mat-button-toggle [value]="this.env.codBanco">Banco {{this.env.codBanco}}</mat-button-toggle>
          <mat-button-toggle value="">Todos</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar agência:</label>
        <mat-button-toggle-group formControlName="agencia">
          <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
            {{agencia.codigo}}</mat-button-toggle>
          <mat-button-toggle value="">Todas</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="col-12 d-flex flex-row" style="gap: 1rem">
        <label class="texto-principal">Conteúdo:</label>
        <div class="col-12 d-flex flex-row" style="gap: 1rem 2rem; flex-wrap: wrap;">
          <mat-checkbox
            [checked]="formAgencia.controls.boletos.value && formAgencia.controls.teds.value && formAgencia.controls.pagamentos.value && formAgencia.controls.antecipacoes.value && formAgencia.controls.manutencao.value && formAgencia.controls.cartao.value"
            (change)="tudo()" formControlName="tudo">Tudo</mat-checkbox>
          <mat-checkbox formControlName="boletos">Boletos</mat-checkbox>
          <mat-checkbox formControlName="teds">Transferências</mat-checkbox>
          <mat-checkbox formControlName="pagamentos">Pagamentos</mat-checkbox>
          <mat-checkbox formControlName="antecipacoes">Antecipações</mat-checkbox>
          <mat-checkbox formControlName="cartao">Cartão</mat-checkbox>
          <mat-checkbox formControlName="manutencao">Manutenção de conta</mat-checkbox>
        </div>
      </div>

      <div class="col-12 d-flex flex-row" style="gap: 1rem">
        <label class="texto-principal">Opções:</label>
        <mat-slide-toggle (change)="Str($event,'fechamentoDiario')" formControlName="fechamentoDiario" color="corBase">
          Incluir fechamento diário
          <i class="fas fa-info-circle info-icon" style="margin: 0px 5px;color: grey"
            matTooltip="Adiciona linhas com o saldo total do banco no final de cada dia."></i>
        </mat-slide-toggle>
        <mat-slide-toggle (change)="Str($event,'conciliacaoSTR')" formControlName="conciliacaoSTR" color="corBase">
          Conciliação STR
          <i class="fas fa-info-circle info-icon" style="margin: 0px 5px;color: grey"
            matTooltip="Mostra datas de taxas e pagamentos em D+1."></i>
        </mat-slide-toggle>
      </div>

      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Procurar Correntista:<i class="fas fa-info-circle info-icon"
            matTooltip="Caso queira todas, mantenha a seleção vazia" style="color: #818d91;margin-right: 5px;padding: 5px 0px 0px 5px;
          font-size: 12pt;"></i></label>
        <div class="col-9" style="display: flex; ">
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>Selecione o grupo</mat-label>
            <span style="position: absolute; top: 14.1%!important" class="material-icons">account_balance</span>
            <input style="padding-left: 10.1%!important;" type="text" placeholder="Selecione o grupo" matInput
              formControlName="corporacao" [matAutocomplete]="autoCorp"
            />

            <mat-autocomplete #autoCorp="matAutocomplete" [displayWith]="displayCorp"
              (optionSelected)="getEmpresasDaCorporacao($event.option.value)">
              <mat-option *ngFor="let corporacao of filtroCorporacao" [value]="corporacao"
                (click)="atualizarCorporacao(corporacao)">
                <span [style.color]="corporacao?.status == 'bloqueada' ? 'darkred' : ''">{{ !corporacao ? "" :
                  corporacao.status == 'ativada' ? corporacao.nome : corporacao.nome + " - BLOQUEADA" }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance="outline" style=" margin-left: 10px;">
            <mat-label>Selecione a empresa</mat-label>
            <span style="position: absolute; top: 14.1%!important" class="material-icons">home</span>
            <input style="margin-left: 10.1%!important;max-width: 69ch;overflow: hidden;width: -webkit-fill-available;
            white-space: nowrap;" type="text" placeholder="Selecione a empresa" matInput formControlName="empresa"
              [matAutocomplete]="autoEmp"/>
            <mat-autocomplete #autoEmp="matAutocomplete" [displayWith]="displayEmp">
              <mat-option *ngFor="let empresa of filtroEmpresa" [value]="empresa"
                (click)="atualizarEmpresa(empresa)" matTooltip="{{exibirTooltip(empresa)}}" matTooltipPosition="after">
                <span [style.color]="empresa.moderacaoEmpresa == 'bloqueada' ? 'rgb(139, 0, 0)' : ''">{{
                  empresa.pessoa.razaoSocial ? empresa.pessoa.razaoSocial : empresa.pessoa.nome }} -
                  {{ empresa.pessoa.documento }} -
                  Bco: {{ this.getComp(empresa) }} -
                  Ag: {{ env.codAgencia }} -
                  {{ this.getTipo(empresa)}}:
                  {{ this.calculaNroConta(empresa) }}
                  {{ empresa.moderacaoEmpresa == 'bloqueada' ? ' - BLOQUEADA' : ""}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Tipos de conta:<i class="fas fa-info-circle info-icon"
            matTooltip="Caso queira todas, mantenha a seleção vazia" style="color: #818d91;margin-right: 5px;padding: 5px 0px 0px 5px;
          font-size: 12pt;"></i></label>
        <mat-form-field appearance="outline" style="width: 78%">
          <mat-label>Tipos de conta</mat-label>
          <mat-select formControlName="tiposConta" multiple style="overflow-x: hidden;">
            <mat-option *ngFor="let conta of tiposConta" [value]="conta.tipo"
              [disabled]="formAgencia.get('banco').value === env.codBanco && conta.tipo === 'contaDigitalS'">
              {{conta.nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #extratoMovimentacoes>
  <form [formGroup]="formInterno">
    <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar período:</label>
      <mat-form-field appearance="outline" style="width: 30%;">
        <mat-label>De</mat-label>
        <input readonly matInput [matDatepicker]="de2" [max]="today" (click)="de2.open()" formControlName="de">
        <mat-datepicker-toggle matSuffix [for]="de2"></mat-datepicker-toggle>
        <mat-datepicker #de2></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 30%;">
        <mat-label>Até</mat-label>
        <input readonly matInput [matDatepicker]="ate2" [max]="today" (click)="ate2.open()" formControlName="ate">
        <mat-datepicker-toggle matSuffix [for]="ate2"></mat-datepicker-toggle>
        <mat-datepicker #ate2></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar agência:</label>
      <mat-button-toggle-group formControlName="agencia">
        <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
          {{agencia.codigo}}</mat-button-toggle>
        <mat-button-toggle value="">Todas</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</ng-template>

<ng-template #extratoIntegralizacao>
  <form [formGroup]="formIntegral">
    <div class="w-100 d-flex flex-column" style="gap: 1rem">
      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar período:</label>
        <mat-form-field appearance="outline" style="width: 30%;">
          <mat-label>De</mat-label>
          <input readonly matInput [matDatepicker]="de3" [max]="today" (click)="de3.open()" formControlName="de">
          <mat-datepicker-toggle matSuffix [for]="de3"></mat-datepicker-toggle>
          <mat-datepicker #de3></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 30%;">
          <mat-label>Até</mat-label>
          <input readonly matInput [matDatepicker]="ate3" [max]="today" (click)="ate3.open()" formControlName="ate">
          <mat-datepicker-toggle matSuffix [for]="ate3"></mat-datepicker-toggle>
          <mat-datepicker #ate3></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar banco:</label>
        <mat-button-toggle-group formControlName="banco">
          <mat-button-toggle value="000">Banco 000</mat-button-toggle>
          <mat-button-toggle [value]="this.env.codBanco">Banco {{this.env.codBanco}}</mat-button-toggle>
          <mat-button-toggle value="">Todos</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar agência:</label>
        <mat-button-toggle-group formControlName="agencia">
          <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
            {{agencia.codigo}}</mat-button-toggle>
          <mat-button-toggle value="">Todas</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #extratoDiario>
  <form [formGroup]="formRelatorio">
    <div class="w-100 d-flex flex-column" style="gap: 1rem">
      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar período:</label>
        <mat-form-field appearance="outline" style="width: 40%">
          <mat-label>Data</mat-label>
          <input readonly matInput [matDatepicker]="de4" [max]="today" (click)="de4.open()" formControlName="de">
          <mat-datepicker-toggle matSuffix [for]="de4"></mat-datepicker-toggle>
          <mat-datepicker #de4></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar banco:</label>
        <mat-button-toggle-group formControlName="banco">
          <mat-button-toggle value="000">Banco 000</mat-button-toggle>
          <mat-button-toggle [value]="this.env.codBanco">Banco {{this.env.codBanco}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar agência:</label>
        <mat-button-toggle-group formControlName="agencia">
          <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
            {{agencia.codigo}}</mat-button-toggle>
          <mat-button-toggle value="">Todas</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #extratoMovimentacoesPorConta>
  <form [formGroup]="formPlanilhas">
    <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar período:</label>
      <mat-form-field appearance="outline" style="width: 30%;">
        <mat-label>De</mat-label>
        <input readonly matInput [matDatepicker]="de5" [max]="today" (click)="de5.open()" formControlName="de">
        <mat-datepicker-toggle matSuffix [for]="de5"></mat-datepicker-toggle>
        <mat-datepicker #de5></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 30%;">
        <mat-label>Até</mat-label>
        <input readonly matInput [matDatepicker]="ate5" [max]="today" (click)="ate5.open()" formControlName="ate">
        <mat-datepicker-toggle matSuffix [for]="ate5"></mat-datepicker-toggle>
        <mat-datepicker #ate5></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar agência:</label>
      <mat-button-toggle-group formControlName="agencia">
        <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
          {{agencia.codigo}}</mat-button-toggle>
        <mat-button-toggle value="">Todas</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</ng-template>

<ng-template #relatorioSimplificado>
  <form [formGroup]="formRelatorioSimplificado">
    <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar agência:</label>
      <mat-button-toggle-group formControlName="agencia">
        <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
          {{agencia.codigo}}</mat-button-toggle>
        <mat-button-toggle value="">Todas</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</ng-template>

<ng-template #layoutContabil>
  <form [formGroup]="formContabil">
    <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <mat-form-field appearance="outline">
        <mat-label>Tipo de layout</mat-label>
        <mat-select formControlName="tipo">
          <mat-option value="">Zap Contábil</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Conta contábil</mat-label>
        <input matInput type="text" formControlName="contaContabil">
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar período:</label>
      <mat-form-field appearance="outline" style="width: 30%;">
        <mat-label>De</mat-label>
        <input readonly matInput [matDatepicker]="de6" [max]="today" (click)="de6.open()" formControlName="de">
        <mat-datepicker-toggle matSuffix [for]="de6"></mat-datepicker-toggle>
        <mat-datepicker #de6></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 30%;">
        <mat-label>Até</mat-label>
        <input readonly matInput [matDatepicker]="ate6" [max]="today" (click)="ate6.open()" formControlName="ate">
        <mat-datepicker-toggle matSuffix [for]="ate6"></mat-datepicker-toggle>
        <mat-datepicker #ate6></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
      <label class="texto-principal">Selecionar agência:</label>
      <mat-button-toggle-group formControlName="agencia">
        <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
          {{agencia.codigo}}</mat-button-toggle>
        <mat-button-toggle value="">Todas</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</ng-template>

<ng-template #extratoTarifas>
  <form [formGroup]="formTarifas">
    <div class="w-100 d-flex flex-column" style="gap: 1rem">
      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar período:</label>
        <mat-form-field appearance="outline" style="width: 30%;">
          <mat-label>De</mat-label>
          <input readonly matInput [matDatepicker]="de" [max]="today" (click)="de.open()" formControlName="de">
          <mat-datepicker-toggle matSuffix [for]="de"></mat-datepicker-toggle>
          <mat-datepicker #de></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 30%;">
          <mat-label>Até</mat-label>
          <input readonly matInput [matDatepicker]="ate" [max]="today" (click)="ate.open()" formControlName="ate">
          <mat-datepicker-toggle matSuffix [for]="ate"></mat-datepicker-toggle>
          <mat-datepicker #ate></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="env.modulos.multiagencias" class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Selecionar agência:</label>
        <mat-button-toggle-group formControlName="agencia">
          <mat-button-toggle *ngFor="let agencia of agencias" [value]="agencia.codigo">Agência
            {{agencia.codigo}}</mat-button-toggle>
          <mat-button-toggle value="">Todas</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="col-12 d-flex flex-row align-items-baseline" style="gap: 1rem">
        <label class="texto-principal">Procurar Correntista:<i class="fas fa-info-circle info-icon"
            matTooltip="Caso queira todas, mantenha a seleção vazia" style="color: #818d91;margin-right: 5px;padding: 5px 0px 0px 5px;
          font-size: 12pt;"></i></label>
        <div class="col-9" style="display: flex; ">
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>Selecione o grupo</mat-label>
            <span style="position: absolute; top: 14.1%!important" class="material-icons">account_balance</span>
            <input style="padding-left: 10.1%!important;" type="text" placeholder="Selecione o grupo" matInput
              formControlName="corporacao" [matAutocomplete]="autoCorp"
            />

            <mat-autocomplete #autoCorp="matAutocomplete" [displayWith]="displayCorp"
              (optionSelected)="getEmpresasDaCorporacao($event.option.value)">
              <mat-option *ngFor="let corporacao of filtroCorporacao" [value]="corporacao"
                (click)="atualizarCorporacao(corporacao)">
                <span [style.color]="corporacao?.status == 'bloqueada' ? 'darkred' : ''">{{ !corporacao ? "" :
                  corporacao.status == 'ativada' ? corporacao.nome : corporacao.nome + " - BLOQUEADA" }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance="outline" style=" margin-left: 10px;">
            <mat-label>Selecione a empresa</mat-label>
            <span style="position: absolute; top: 14.1%!important" class="material-icons">home</span>
            <input style="margin-left: 10.1%!important;max-width: 69ch;overflow: hidden;width: -webkit-fill-available;
            white-space: nowrap;" type="text" placeholder="Selecione a empresa" matInput formControlName="empresa"
              [matAutocomplete]="autoEmp" />
            <mat-autocomplete #autoEmp="matAutocomplete" [displayWith]="displayEmp">
              <mat-option *ngFor="let empresa of filtroEmpresa" [value]="empresa"
                (click)="atualizarEmpresa(empresa)" matTooltip="{{exibirTooltip(empresa)}}" matTooltipPosition="after">
                <span [style.color]="empresa.moderacaoEmpresa == 'bloqueada' ? 'rgb(139, 0, 0)' : ''">{{
                  empresa.pessoa.razaoSocial ? empresa.pessoa.razaoSocial : empresa.pessoa.nome }} -
                  {{ empresa.pessoa.documento }} -
                  Bco: {{ this.getComp(empresa) }} -
                  Ag: {{ env.codAgencia }} -
                  {{ this.getTipo(empresa)}}:
                  {{ this.calculaNroConta(empresa) }}
                  {{ empresa.moderacaoEmpresa == 'bloqueada' ? ' - BLOQUEADA' : ""}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</ng-template>