import { PermissaoGuard } from "./guard/permissao.guard";
import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "@pages/layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "@pages/layouts/auth/auth-layout.component";
import { AcessoInternoService } from "./service/auth/acesso-interno.service";
import { AcessoRootService } from "./service/auth/acesso-root.service";
import { JaLogadoService } from "./service/auth/ja-logado.service";
import { acessoRecursosService } from "./service/auth/acesso-recursos.service";
import { acessoTipoContaService } from "./service/auth/acesso-tipoConta.service";
import { acessoContaAprovadaService } from "./service/auth/acesso-conta-aprovada.service";
import { BloqueioCadastro } from "./service/auth/bloqueio-cadastro.service";
import { acessoPixService } from "./service/auth/acesso-pix.service";

const invalido = { path: "**", redirectTo: "/privado/principal" };

export const AppRoutes: Routes = [
  {
    path: "acesso",
    canActivate: [JaLogadoService],
    runGuardsAndResolvers: "always",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/externo/login/login.module").then(
            (module) => module.LoginModule
          ),
      },
      {
        path: "esqueci",
        loadChildren: () =>
          import("./pages/externo/alterar-senha/alterar-senha.module").then(
            (module) => module.AlterarSenhaModule
          ),
      },
      {
        path: "usuario",
        canActivate: [BloqueioCadastro],
        runGuardsAndResolvers: "always",
        loadChildren: () =>
          import(
            "./pages/externo/cadastrar-usuario/cadastrar-usuario.module"
          ).then((module) => module.CadastrarUsuarioModule),
      },
      {
        path: "usuario/:id",
        loadChildren: () =>
          import(
            "./pages/externo/cadastrar-usuario/cadastrar-usuario.module"
          ).then((module) => module.CadastrarUsuarioModule),
      },
      {
        path: "usuario-operador",
        loadChildren: () =>
          import(
            "./pages/externo/cadastrar-usuario-operador/cadastrar-usuario-operador.module"
          ).then((module) => module.CadastrarUsuarioOperadorModule),
      },
      {
        path: "usuario-representante",
        loadChildren: () =>
          import(
            "./pages/externo/cadastrar-usuario-representante/cadastrar-usuario-representante.module"
          ).then((module) => module.CadastrarUsuarioRepresentanteModule),
      },
    ],
  },
  {
    path: "privado-acesso-codigo",
    canActivate: [AcessoInternoService],
    runGuardsAndResolvers: "always",
    component: AuthLayoutComponent,
  },
  {
    path: "privado",
    canActivate: [AcessoInternoService],
    runGuardsAndResolvers: "always",
    component: AdminLayoutComponent,
    children: [
      {
        path: "principal",
        loadChildren: () =>
          import("./pages/interno/dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: "pessoa/:tipo",
        pathMatch: "full",
        canActivate: [PermissaoGuard, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_PESSOAS_VISUALIZAR", "ROLE_CONTATO_LISTAR"] },
        loadChildren: () =>
          import(
            "./pages/interno/pessoa/pessoa-listar/pessoa-listar.module"
          ).then((module) => module.PessoaListarModule),
      },
      {
        path: "empresa",
        loadChildren: () =>
          import("./pages/interno/empresa/empresa.module").then(
            (module) => module.EmpresaModule
          ),
      },
      {
        path: "cobranca",
        canActivate: [PermissaoGuard, acessoRecursosService, acessoTipoContaService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_COBRANCAS_VISUALIZAR", "ROLE_COBRANCA_LISTAR"], param: "cobranca" },
        loadChildren: () =>
          import("./pages/interno/cobranca/cobranca.module").then(
            (module) => module.CobrancaModule
          ),
      },
      {
        path: "extrato",
        canActivate: [PermissaoGuard, acessoRecursosService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_EXTRATOFINANCEIRO_VISUALIZAR", "ROLE_EXTRATO_LISTAR"] , param: "extrato" },
        loadChildren: () =>
          import("./components/relatorio/extrato/extrato.module").then(
            (module) => module.ExtratoModule
          ),
      },
      {
        path: 'investimentos',
        canActivate: [PermissaoGuard, acessoRecursosService, acessoTipoContaService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_INVESTIMENTOS_ATUALIZAR", "ROLE_INVESTIMENTO_ATUALIZAR"], param: "investimentos" },
        loadChildren: () =>
          import('./pages/interno/investimentos/investimentos.module').then(
            (module) => module.InvestimentosModule
          ),
      },
      {
        path: "transferencia",
        canActivate: [PermissaoGuard, acessoRecursosService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_TRANSFERENCIAS_VISUALIZAR", "ROLE_TRANSFERENCIA_LISTAR"], param: "transferencia" },
        loadChildren: () =>
          import("./pages/interno/transferencia/transferencia.module").then(
            (module) => module.TransferenciaModule
          ),
      },
      {
        path: "antecipacao",
        canActivate: [PermissaoGuard],
        data: { roles: ["ROLE_EMPRESA_ANTECIPACOES_VISUALIZAR", "ROLE_ANTECIPACAO_LISTAR"], param: "antecipacao" },
        loadChildren: () =>
          import("./pages/interno/antecipacao/antecipacao.module").then(
            (module) => module.AntecipacaoModule
          ),
      },
      {
        path: "credito",
        canActivate: [PermissaoGuard, acessoRecursosService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_CREDITO_VISUALIZAR"], param: "credito" },
        loadChildren: () =>
          import("./pages/interno/credito/credito.module").then(
            (module) => module.CreditoModule
          ),
      },
      {
        path: "emprestimo",
        canActivate: [PermissaoGuard, acessoRecursosService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_CREDITO_VISUALIZAR"], param: "credito" },
        loadChildren: () =>
          import("./pages/interno/emprestimo/emprestimo.module").then(
            (module) => module.EmprestimoModule),
      },
      {
        path: "pix",
        canActivate: [PermissaoGuard, acessoRecursosService, acessoContaAprovadaService, acessoPixService],
        data: { roles: ["ROLE_EMPRESA_PIX_VISUALIZAR"], param: "pix" },
        loadChildren: () =>
          import("./pages/interno/pix/pix.module").then(
            (module) => module.PixModule
          ),
      },
      {
        path: "pague-contas",
        canActivate: [PermissaoGuard, acessoTipoContaService, acessoRecursosService, acessoContaAprovadaService],
        data: { roles: ["ROLE_EMPRESA_PAGAMENTOS_VISUALIZAR", "ROLE_PAGAMENTOCONTAS_LISTAR"], tipoConta: "contaDigitalS", param: "pague-contas" },
        loadChildren: () =>
          import(
            "./pages/interno/pagamento/listar-pagamento/listar-pagamento.module"
          ).then((module) => module.ListarPagamentoModule),
      },
      {
        path: "indicar-amigos",
        canActivate: [acessoRecursosService],
        data: { param: 'convite' },
        loadChildren: () =>
          import("./pages/interno/indicar-amigo/indicar-amigo.module").then(
            (module) => module.IndicarAmigoModule
          ),
      },
    ],
  },
  {
    path: "fatura",
    loadChildren: () =>
      import(
        "./pages/interno/cobranca/visualizar-fatura/visualizar-fatura.module"
      ).then((module) => module.VisualizarFaturaModule),
  },
  {
    path: "pagamento", // comprovante de pagamento e exclusão de cobrança
    loadChildren: () =>
      import(
        "./pages/interno/cobranca/comprovante-pagamento/comprovante-pagamento.module"
      ).then((module) => module.ComprovantePagamentoModule),
  },
  {
    path: "pagamento-conta", // comprovante de pagamento de contas
    loadChildren: () =>
      import(
        "./pages/interno/cobranca/comprovante-pagamento-conta/comprovante-pagamento-conta.module"
      ).then((module) => module.ComprovantePagamentoContaModule),
  },
  {
    path: "comprovante", // comprovante de transferencia
    canActivate: [AcessoInternoService],
    loadChildren: () =>
      import(
        "./pages/interno/transferencia/comprovante-transferencia/comprovante-transferencia.module"
      ).then((module) => module.ComprovanteTransferenciaModule),
  },
  {
    path: "pagamento-tributo", // comprovante de pagamento de contas (tributos)
    loadChildren: () =>
      import(
        "./pages/interno/pagamento/comprovante-pagamento-tributo/comprovante-pagamento-tributo.module"
      ).then((module) => module.ComprovantePagamentoTributoModule),
  },
  {
    path: "boleto",
    // canActivate: [JaLogadoService],
    // runGuardsAndResolvers: 'always',
    // trocar os componentes pra documento ao inves de boleto
    loadChildren: () =>
      import("./pages/interno/cobranca/boleto/boleto.module").then(
        (module) => module.BoletoModule
      ),
  },
  {
    path: "protesto",
    loadChildren: () =>
      import("./pages/interno/cobranca/informacoes-protesto/informacoes-protesto.module").then(
        (module) => module.InformacoesProtestoModule
      ),
  },
  {
    path: "comprovante-pix", // comprovante de transferencia
    loadChildren: () =>
      import(
        "./pages/interno/pix/pix-comprovante-transferencia/pix-comprovante-transferencia.module"
      ).then((module) => module.PixComprovanteTransferenciaModule),
  },
  {
    path: "comprovante-qr-code",
    loadChildren: () =>
      import(
        "./pages/interno/pix/pix-modal-cobranca/comprovante-qr-code/comprovante-qr-code.module"
      ).then((module) => module.ComprovanteQrCodeModule),
  },
  {
    path: "placa-legal",
    loadChildren: () =>
      import("./components/placa-legal/placa-legal.module").then(
        (module) => module.PlacaLegalModule
      ),
  },
  {
    path: "root",
    canActivate: [AcessoRootService],
    runGuardsAndResolvers: "always",
    component: AdminLayoutComponent,
    children: [
      {
        path: "cobrancas",
        loadChildren: () =>
          import(
            "./components/relatorio/cobrancas-lista/cobrancas-lista.module"
          ).then((module) => module.CobrancasListaModule),
      },
      {
        path: "lista-aumentos",
        loadChildren: () =>
          import("./pages/interno/aumento/aumento.module").then(
            (module) => module.AumentoModule),
      },
      {
        path: "antecipacao",
        loadChildren: () =>
          import(
            "./pages/interno/antecipacao/antecipacao-gerenciar-root/antecipacao-gerenciar-root.module"
          ).then((module) => module.AntecipacaoGerenciarRootModule),
      },
      {
        path: "pessoa/:tipo",
        pathMatch: "full",
        loadChildren: () =>
          import(
            "./pages/interno/pessoa/pessoa-gerenciar-root/pessoa-gerenciar-root.module"
          ).then((module) => module.PessoaGerenciarRootModule),
      },
      {
        path: "empresa",
        loadChildren: () =>
          import("./pages/interno/empresa/empresa.module").then(
            (module) => module.EmpresaModule
          ),
      },
      {
        path: "lista-logs",
        canActivate: [acessoRecursosService],
        data: { param: "lista-logs" },
        loadChildren: () =>
          import("./pages/interno/lista-logs/lista-logs.module").then(
            (module) => module.ListaLogsModule
          ),
      },
      {
        path: "moderacao",
        loadChildren: () =>
          import("./pages/interno/root/lista-cadastro/lista-cadastro.module").then(
            (module) => module.ListaCadastroModule
          ),
      },
      {
        path: "moderacao-iaas",
        loadChildren: () =>
          import("./pages/interno/root/lista-cadastro-iaas/lista-cadastro-iass.module").then(
            (module) => module.ListaCadastroIassModule
          ),
      },
      {
        path: "moderacao-fundos",
        loadChildren: () =>
          import("./pages/interno/root/lista-cadastro-fundos/lista-cadastro-fundos.module").then(
            (module) => module.ListaCadastroFundosModule
          ),
      },
      {
        path: "exportar-planilha",
        loadChildren: () =>
          import(
            "./components/relatorio/exportar-planilha/exportar-planilha.module"
          ).then((module) => module.ExportarPlanilhaModule),
      },
      {
        path: "transferencias",
        loadChildren: () =>
          import(
            "./pages/interno/gerenciar-transferencias/gerenciar-transferencias.module"
          ).then((module) => module.GerenciarTransferenciasModule),
      },
      {
        path: "pagamento-contas",
        loadChildren: () =>
          import(
            "./pages/interno/gerenciar-pagamentos/gerenciar-pagamentos.module"
          ).then((module) => module.GerenciarPagamentosModule),
      },
      {
        path: "fundos",
        loadChildren: () =>
          import(
            "./pages/interno/gerenciar-fundos/gerenciar-fundos.module"
          ).then((module) => module.GerenciarFundosModule),
      },
      {
        path: "usuarios",
        loadChildren: () =>
          import(
            "./pages/interno/root/usuarios/usuarios.module"
          ).then((module) => module.UsuariosModule),
      },
      {
        path: "operadores",
        loadChildren: () =>
          import(
            "./pages/interno/root/operadores/operadores.module"
          ).then((module) => module.OperadoresModule),
      },
      {
        path: "perfil-usuario",
        loadChildren: () =>
          import(
            "./pages/interno/root/perfil-usuario/perfil-usuario.module"
          ).then((module) => module.PerfilUsuarioModule),
      },
      {
        path: 'tarifas-padroes',
        loadChildren: () =>
          import(
            "./pages/interno/tarifas-padroes/tarifas-padroes.module"
          ).then((module) => module.TarifasPadroesModule),
      },
      {
        path: 'moderar-emprestimos',
        loadChildren: () =>
          import(
            "./pages/interno/root/moderar-emprestimo/moderar-emprestimo.module"
          ).then((module) => module.ModerarEmprestimoModule),
      },
      {
        path: 'gerenciar-emprestimos',
        loadChildren: () =>
          import(
            "./pages/interno/gerenciar-emprestimo/gerenciar-emprestimo.module"
          ).then((module) => module.GerenciarEmprestimoModule),
      },
      {
        path: 'codigos-convenio',
        loadChildren: () =>
          import(
            "./pages/interno/convenio/convenio.module"
          ).then((module) => module.ConvenioModule),
      },
      {
        path: "pix-analisar-notificacao",
        loadChildren: () =>
          import(
            "./pages/interno/pix/pix-analisar-notificacao/pix-analisar-notificacao.module"
          ).then((module) => module.PixNotificacaoInfracaoModule),
      },
      {
        path: "pix-analisar-solicitacao",
        loadChildren: () =>
          import(
            "./pages/interno/pix/pix-analisar-solicitacao/pix-analisar-solicitacao.module"
          ).then((module) => module.PixAnalisarSolicitacaoModule),
      }, {
        path: 'lista-sensibilizacao',
        loadChildren: () =>
          import(
            "./pages/interno/lista-sensibilizacao/lista-sensibilizacao.module"
          ).then((module) => module.ListaSensibilizacaoModule),
      },
      {
        path: "pix-listar-reivindicadas",
        loadChildren: () =>
          import(
            "./pages/interno/pix/lista-chaves-reivindicadas/lista-chaves-reivindicadas.module"
          ).then((module) => module.ListaChavesReivindicadasModule),
      },
      {
        path: "pix-listar-cadastradas",
        loadChildren: () =>
          import(
            "./pages/interno/pix/lista-chaves-cadastradas/lista-chaves-cadastradas.module"
          ).then((module) => module.ListaChavesCadastradasModule),
      },
      {
        path: "pix-listar-transferencias",
        loadChildren: () =>
          import(
            "./pages/interno/pix/lista-transferencias-pix/lista-transferencias-pix.module"
          ).then((module) => module.ListaTransferenciasPixModule),
      },
      {
        path: "pix-gerenciar",
        loadChildren: () =>
          import(
            "./pages/interno/gerenciar-pix/gerenciar-pix.module"
          ).then((module) => module.GerenciarPixModule),
      },
    ],
  },
  {
    path: 'excluir-conta-info',
    loadChildren: () =>
      import(
        "./pages/externo/excluir-conta-info/excluir-conta-info.module"
      ).then((module) => module.ExcluirContaInfoModule),
  },
  invalido,
];
