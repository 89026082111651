<div mat-dialog-content class="div-body" style="background: #eeeeee">
  <div class="main-content" style="background: #eeeeee">
    <div class="container-fluid" style="padding-left: 0px; padding-right: 0px">
      <div class="row" style="justify-content: center">
        <div class="col-12">
          <div class="card">
            <div [hidden]="!processando" style="padding: 10px 0 20px 0">
              <div class="col-12">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            </div>

            <div class="card-body" *ngIf="!processando && data !== null">
              <!-- Título do modal -->
              <h2 mat-dialog-title class="titulo-modal" style="justify-content: center;">
                <div id="avatar-icon">
                  <i class="fas fa-building fa" style="font-size: 0.8em !important"></i>
                </div>
                <span id="empresa-logada" [matTooltip]="
                    empresaLogada.pessoa.razaoSocial ||
                    empresaLogada.pessoa.nome
                  ">
                  <span>{{
                    empresaLogada.pessoa.razaoSocial ||
                    empresaLogada.pessoa.nome
                    }}</span>
                  <p>
                    {{
                    stringFormat.adicionarPontosDocumento(
                    empresaLogada.pessoa.documento
                    )
                    }}
                  </p>
                </span>
                DADOS DO PROTESTO
                <i (click)="fecha()" class="material-icons btn-fechar" style="margin-right: 3%">close</i>
                <span id="fechar" (click)="fecha()" style="margin-right: 1%; margin-left: 10%;">FECHAR</span>
                <!-- </div> -->
              </h2>
              <!-- Título do modal -->

              <!-- Menu -->
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="visualizarFatura()">
                  <i class="fas fa-eye"></i>
                  Visualizar Fatura
                </button>
                <div>
                  <button
                    [disabled]="status === 'PAGAMENTO EFETUADO'" mat-menu-item
                    (click)="editarProtesto()">
                    <i class="fa-solid fa-scale-balanced"></i>
                    Editar Protesto
                  </button>
                </div>
                <div *ngIf="
                    env.modulos.cobranca.protesto &&
                    empresaLogada.configuracao?.enviarParaProtesto &&
                    !data.dataPagamento &&
                    !data.dataDesativacao &&
                    data.dataCalculadaParaEnvioProtesto &&
                    !data.statusProtesto &&
                    !atualizeContatoProtesto
                  " [matTooltip]="
                    !protestoPermitido(data.dataCalculadaParaEnvioProtesto)
                      ? 'Não é possível protestar cobranças que não estejam no prazo de emissão do protesto. Data prevista para envio do protesto: ' +
                        (data.dataCalculadaParaEnvioProtesto
                          | date : 'dd/MM/yyyy')
                      : 'Cobrança pode ser protestada. Data de envio permitida para protesto: ' +
                        (data.dataCalculadaParaEnvioProtesto
                          | date : 'dd/MM/yyyy') +
                        '. Quantidade de dias para envio para protesto após o vencimento: ' +
                        data.quantidadeDiasProtesto +
                        ' dia(s)'
                  ">
                  <button mat-menu-item (click)="protestarCobranca(data)" [disabled]="
                      !protestoPermitido(data.dataCalculadaParaEnvioProtesto) ||
                      data.dataPagamento ||
                      data.dataDesativacao ||
                      !data.dataCalculadaParaEnvioProtesto ||
                      data.statusProcessamentoBanco === 'erro' ||
                      data.statusProcessamentoBanco === 'processando'
                    ">
                    <i class="fas fa-scale-balanced"></i>
                    Protestar cobrança
                  </button>
                </div>
                <button mat-menu-item *ngIf="atualizeContatoProtesto" (click)="editarContato(data.cliente.id)">
                  <i class="fas fa-edit"></i>
                  Atualizar dados do contato
                </button>
                <div *ngIf="
                    env.modulos.cobranca.protesto &&
                    empresaLogada.configuracao?.enviarParaProtesto &&
                    !data.dataPagamento &&
                    !data.dataDesativacao &&
                    data.dataEnvioProtesto &&
                    data.statusProtesto
                  " [matTooltip]="
                    !cancelamentoProtestoPermitido(data.statusProtesto)
                      ? 'Não é possível cancelar o protesto com o status: ' +
                        data.statusProtesto
                      : 'Cancelar protesto'
                  ">
                  <div
                    *ngIf="env.modulos.cobranca.protesto && empresaLogada?.configuracao.enviarParaProtesto && !data.dataDesativacao && data.dataEnvioProtesto && data.statusProtesto;                    ">
                    <button mat-menu-item (click)="cancelarProtestoCobranca(data)" [disabled]="
                      !cancelamentoProtestoPermitido(data.statusProtesto) ||
                      data.dataPagamento ||
                      data.dataDesativacao ||
                      data.statusProcessamentoBanco === 'erro' ||
                      data.statusProcessamentoBanco === 'processando'
                    ">
                      <i class="fas fa-ban" style="color: rgb(223, 67, 19)"></i>
                      Cancelar Protesto
                    </button>
                  </div>
                </div>
                <div *ngIf="informacoesProtesto">
                  <button mat-menu-item (click)="baixarProtesto()"
                    [disabled]="data?.statusProcessamentoBanco === 'erro'">
                    <i class="fa-solid fa-file-arrow-down"></i>
                    Download Informações Protesto
                  </button>
                </div>
                <div [matTooltip]="
                    data.dataAssinaturaContratoAntecipacao
                      ? 'Não é possível liquidar em dinheiro cobranças antecipadas.'
                      : ''
                  ">
                  <button mat-menu-item (click)="liquidarCobranca()" [disabled]="
                      data.dataPagamento ||
                      data.dataDesativacao ||
                      data.dataAssinaturaContratoAntecipacao ||
                      data?.statusProcessamentoBanco === 'erro'
                    ">
                    <i class="fas fa-money-bill"></i>
                    Confirmar Recebimento em Dinheiro
                  </button>
                </div>
              </mat-menu>
              <!-- Menu -->

              <!-- Dados Protesto -->
              <div class="box-body">
                <i [matMenuTriggerFor]="menu" class="fas fa-bars btn-fechar"
                  style="font-size: 26px; border: solid 0px white"
                  [hidden]="!authCobranca && !authCobrancaOperador"></i>
                <p>
                  <span class="dado">Situação do Protesto: </span>
                  <span class="situacao"
                  [style.background-color]="destaqueSituacao(informacoesProtesto?.status)">
                  {{ informacoesProtesto?.status }}</span>
                </p>
                <div class="linha-3">
                  <p class="dado-item">
                    <span class="dado">Conta Cobrança: </span>
                    {{ empresaLogada.pessoa.documento }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Tipo Cobrança / Modalidade: </span>
                    <span class="dado-importante">{{
                      data?.tipoCobranca?.toUpperCase()
                      }}</span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Expedição: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Borderô: </span>
                    {{ data.idAntecipacao }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Espécie: </span>
                    {{ descricaoEspecie(data.especie) }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Cobrador: </span>
                    {{ empresaLogada.pessoa.nome }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Valor do Título: </span>
                    {{
                    (data.valorOriginal ? data.valorOriginal : 0)
                    | currency : 'BRL' : 'R$'
                    }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Valor Recebido: </span>
                    {{
                    (informacoesProtesto?.valorRepasse ? informacoesProtesto?.valorRepasse : 0)
                    | currency : 'BRL' : 'R$'
                    }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Valor Pago: </span>
                    {{
                    (informacoesProtesto?.valorRepasse ? informacoesProtesto?.valorRepasse : 0)
                    | currency : 'BRL' : 'R$'
                    }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">IOF (%): </span>
                    {{ data.iof | number : '1.2-2' }}%
                  </p>
                  <p class="dado-item">
                    <span class="dado">Prazo Protesto: </span>
                    {{
                    data.dataCalculadaParaEnvioProtesto | date : 'dd/MM/yyyy'
                    }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Prazo Baixa/Devolução.: </span>
                    {{ data.dataCalculadaParaEnvioBaixa | date : 'dd/MM/yyyy' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Seu Número: </span>
                    {{ data.seuNumero }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Nosso Número: </span>
                    {{
                    data.nossoNumero === '0000000000000'
                    ? ''
                    : data.nossoNumero
                    }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Número Documento: </span>
                    {{  data.numeroDocumento }}
                  </p>
                </div>
              </div>
              <!-- Dados Protesto -->

              <!-- Dados do título -->
              <h3 class="card-title corPrimaria" style="font-weight: 600; margin-top: 20px">
                Dados do título
              </h3>
              <div class="box-body">
                <div class="linha-6">
                  <p class="dado-item">
                    <span class="dado">Vencimento: </span>
                    {{ data.dataVencimentoRecalculada | date : 'dd/MM/yyyy' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Emissão: </span>
                    {{ data.dataRegBanco | date : 'dd/MM/yyyy' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Entrada: </span>
                    {{ data.dataEnvioProtesto | date : 'dd/MM/yyyy' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Baixa: </span>
                    {{ data?.dataDesativacao | date : 'dd/MM/yyyy'}}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Liquidação: </span>
                    {{ informacoesProtesto?.dataRepasse | date : 'dd/MM/yyyy'}}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Situação do Protesto: </span>
                    <span class="dado-importante">{{ informacoesProtesto?.status }}</span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Moeda: </span>
                    {{ 'REAL' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Desconto: </span>
                    {{ data.valorDesconto ? (data.valorDesconto | currency : 'BRL' : 'R$') : 'Isento' }} {{
                    data.dataLimiteDesconto ? ' - ' + ( data.dataLimiteDesconto | date : 'dd/MM/yyyy') : ''}}<br>
                    {{ data.valorDesconto2 !== 0 ? (data.valorDesconto2 | currency:'BRL':'R$') : '' }} {{
                    data.dataLimiteDesconto2 ? ' - ' + ( data.dataLimiteDesconto2 | date : 'dd/MM/yyyy') : ''}}<br>
                    {{ data.valorDesconto3 !== 0 ? (data.valorDesconto3 | currency:'BRL':'R$') : '' }} {{
                    data.dataLimiteDesconto3 ? ' - ' + ( data.dataLimiteDesconto3 | date : 'dd/MM/yyyy') : ''}}<br>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Abatimento: </span>
                    {{ data.abatimento | currency : 'BRL' : 'R$' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Mora dia (%): </span>
                    {{ data.juros ? (data.juros | number : '1.2-2') + '%': 'Isento' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Taxa Juros Mês: </span>
                    {{
                    data.multa != 0
                    ? data.porcentagemMulta +
                    '% (' +
                    (data.multa | currency : 'BRL' : 'R$') +
                    ')'
                    : 'Isento'
                    }}
                  </p>
                </div>
                <div class="linha-6">
                  <p class="dado-item">
                    <span class="dado">Tipo de Pagamento: </span>
                    <span class="dado-importante">{{
                      data.formaPagamento
                      }}</span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">% Mínimo: </span>
                    {{ 0 | number : '1.2-2' }}%
                  </p>
                  <p class="dado-item">
                    <span class="dado">% Máximo: </span>
                    {{ 0 | number : '1.2-2' }}%
                  </p>
                  <p class="dado-item">
                    <span class="dado">Valor Mínimo: </span>
                    {{ data.valorMinimo | currency : 'BRL' : 'R$' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Valor Máximo: </span>
                    {{ data.valorMaximo | currency : 'BRL' : 'R$' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Quantidade de Pagamentos Possíveis: </span>
                    {{
                    (data.quantidadeParcelas ? data.quantidadeParcelas : 0)
                    | number : '3.0-0'
                    }}
                  </p>
                </div>
              </div>
              <!-- Dados do título  -->

              <!-- Dados do cartório -->
              <h3 class="card-title corPrimaria" style="font-weight: 600; margin-top: 20px" *ngIf="informacoesProtesto">
                Dados do cartório
              </h3>
              <div class="box-body" *ngIf="informacoesProtesto">
                <div class="linha-4">
                  <p class="dado-item">
                    <span class="dado">Praça: </span>
                    {{ informacoesProtesto?.praca }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Número Cartório: </span>
                    {{ informacoesProtesto?.cartorio?.numero }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Protocolo: </span>
                    {{ informacoesProtesto?.cartorio?.protocolo }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Comarca: </span>
                    {{ informacoesProtesto?.cartorio?.comarca }}
                  </p>
                </div>
                <div class="linha-4">
                  <p class="dado-item">
                    <span class="dado">Valor Custas: </span>
                    {{ informacoesProtesto?.custas?.despesas | currency : 'BRL' : 'R$' }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Situação do Protesto: </span>
                    <span class="dado-importante">{{
                      informacoesProtesto?.status
                      }}</span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Cidade - UF: </span>
                    {{
                    informacoesProtesto?.cartorio?.cidade
                    }} - {{
                    informacoesProtesto?.cartorio?.uf
                    }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Nome do Cartório: </span>
                    {{
                    informacoesProtesto?.cartorio?.nome
                    }}
                  </p>
                </div>
                <div class="linha-4">
                  <p class="dado-item">
                    <span class="dado">Previsão Ordem Protesto: </span>
                    {{ informacoesProtesto?.dataCriacao | date : 'dd/MM/yyyy'}}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Emissão Remessa Cartório: </span>
                    {{ data.dataEnvioProtesto | date : 'dd/MM/yyyy'}}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Confirmação Recebimento Cartório: </span>
                    {{ informacoesProtesto?.cartorio?.cartorioData | date : 'dd/MM/yyyy'}}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Protesto Efetivado: </span>
                    <span class="dado-importante">{{verificaStatus(informacoesProtesto?.status) ? 'EFETIVADO' :
                      'PENDENTE'}}</span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Pedido de Sustação: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Emissão de Sustação: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Sustação Acolhida pelo Cartório: </span>
                  </p>
                </div>
                <div class="linha-4">
                  <p class="dado-item">
                    <span class="dado">Cancelamento Ordem Protesto: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Pedido de Cancelamento: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Liberação do Cancelamento: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Retorno Cancelamento Cartório: </span>
                  </p>
                </div>
              </div>
              <!-- Dados do cartório -->

              <!-- Dados do pagador -->
              <h3 class="card-title corPrimaria" style="font-weight: 600; margin-top: 20px">
                Dados do pagador
              </h3>
              <div class="box-body">
                <div class="linha-4">
                  <p class="dado-item">
                    <span class="dado">Sacador: </span>
                    {{ data.nomeBenFinal }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Pagador: </span>
                    {{ data?.cliente?.pessoa?.nome }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">CPF/CNPJ: </span>
                    {{ data?.cliente?.pessoa?.documento }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Título DDA: </span>
                  </p>
                  <p class="dado-item">
                    <span class="dado">Endereço: </span>
                    {{ data?.cliente?.pessoa?.enderecos[0]?.logradouro }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">Cidade: </span>
                    {{ data?.cliente?.pessoa?.enderecos[0]?.cidade }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">UF: </span>
                    {{ data?.cliente?.pessoa?.enderecos[0]?.estado }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">CEP: </span>
                    {{ data?.cliente?.pessoa?.enderecos[0]?.cep }}
                  </p>
                </div>
              </div>
              <!-- Dados do pagador -->

              <!-- Dados do Beneficiário Final -->
              <h3 class="card-title corPrimaria" style="font-weight: 600; margin-top: 20px">
                Dados do Beneficiário Final
              </h3>
              <div class="box-body">
                <div class="linha-4">
                  <p class="dado-item">
                    <span class="dado">Nome do beneficiário: </span>
                    {{ data.nomeBenFinal }}
                  </p>
                  <p class="dado-item">
                    <span class="dado">CPF/CNPJ: </span>
                    {{ data.documentoBenFinal }}
                  </p>
                </div>
              </div>
              <!-- Dados do Beneficiário Final -->

              <!--  Ocorrências -->
              <h3 *ngIf="informacoesProtesto?.ocorrencias.length > 0" class="card-title corPrimaria"
                style="font-weight: 600; margin-top: 20px">
                Ocorrências
              </h3>
              <div *ngIf="informacoesProtesto?.ocorrencias.length > 0" class="box-body">
                <div class="table-responsive">
                  <app-listar-ocorrencias-protesto [listaOcorrenciasProtesto]="
                      informacoesProtesto?.ocorrencias
                    "></app-listar-ocorrencias-protesto>
                </div>
              </div>
              <!--  Ocorrências -->

              <div *ngIf="!processando && data === null" class="card-body"
                style="padding-top: 1.8rem !important; text-align: center">
                <i class="fas fa-triangle-exclamation" style="font-size: 6em"></i>
                <h2 style="padding-top: 1em">Cobrança não encontrada</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>