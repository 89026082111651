import { PipeModule } from './../../../../pipe/pipe.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadArquivosModule } from '../../../../components/upload-arquivos/upload-arquivos.module';
import { InputAtivarDesativarModule } from '../../../../components/input-ativar-desativar/input-ativar-desativar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PessoaCoreEditarComponent } from './pessoa-core-editar.component';
import { GeralComponent } from './geral/geral.component';
import { EnderecoComponent } from './endereco/endereco.component';
import { TelefoneComponent } from './telefone/telefone.component';
import { EmailComponent } from './email/email.component';
import { DadosBancariosComponent } from './dados-bancarios/dados-bancarios.component';
import { JurosDescontoComponent } from './juros-desconto/juros-desconto.component';
import { NotificacoesComponent } from './notificacoes/notificacoes.component';
import { PersonalizacaoComponent } from './personalizacao/personalizacao.component';
import { ResumoComponent } from './resumo/resumo.component';
import { DocumentacaoComponent } from './documentacao/documentacao.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutenticacaoComponent } from './autenticacao/autenticacao.component';
import { IconsModule } from '@app/icons.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { RepresentanteLegalComponent } from './representante-legal/representante-legal.component';
import { UploadArquivoModalComponent } from '../../antecipacao/antecipacao-modal/faturas-antecipaceis-lista/upload-arquivo-modal/upload-arquivo-modal.component';
import { UploadArquivoModalModule } from '../../antecipacao/antecipacao-modal/faturas-antecipaceis-lista/upload-arquivo-modal/upload-arquivo-modal.module';
import { PrecosETaxasComponent } from './precos-e-taxas/precos-e-taxas.component';
import { GerenciarArquivosListaComponent } from '../../../../components/gerenciar-arquivos-lista/gerenciar-arquivos-lista.component';
import { GerenciarArquivosListaModule } from '../../../../components/gerenciar-arquivos-lista/gerenciar-arquivos-lista.module';
import { DirectiveModule } from '@project/src/app/directive/directive.module';
import { TelefoneNotificacaoComponent } from './telefone-notificacao/telefone-notificacao.component';
import { EmailNotificacaoComponent } from './email-notificacao/email-notificacao.component';
import { ArquivosComponent } from './arquivos/arquivos.component';
import { IntegracaoComponent } from './integracao/integracao.component';
import { PlanoEmpresaComponent } from './plano-empresa/plano-empresa.component';
import { RepresentantesComponent } from './arquivos/representantes/representantes.component';
import { AddRepresentanteComponent } from './arquivos/representantes/add-representante/add-representante.component';
import { Stepper1Component } from './arquivos/stepper1/stepper1.component';
import { Stepper2Component } from './arquivos/stepper2/stepper2.component';
import { LimitesComponent } from './limites/limites.component';
import { EncerrarContaComponent } from './encerrar-conta/encerrar-conta.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChavesPixComponent } from './chaves-pix/chaves-pix.component';
import { LimitePixComponent } from './limite-pix/limite-pix.component';
import { MatSliderModule } from '@angular/material/slider';
import { PortabilidadePixComponent } from './portabilidade-pix/portabilidade-pix.component';
import { ChavesPixContatoComponent } from './chaves-pix-contato/chaves-pix-contato.component';
import { ModalMotivoEncerramentoComponent } from './encerrar-conta/modal-motivo-encerramento/modal-motivo-encerramento.component';

@NgModule({
  declarations: [
    PessoaCoreEditarComponent,
    GeralComponent,
    EnderecoComponent,
    TelefoneComponent,
    EmailComponent,
    DadosBancariosComponent,
    JurosDescontoComponent,
    NotificacoesComponent,
    PersonalizacaoComponent,
    AutenticacaoComponent,
    ResumoComponent,
    DocumentacaoComponent,
    RepresentanteLegalComponent,
    PrecosETaxasComponent,
    TelefoneNotificacaoComponent,
    EmailNotificacaoComponent,
    ArquivosComponent,
    IntegracaoComponent,
    PlanoEmpresaComponent,
    RepresentantesComponent,
    AddRepresentanteComponent,
    Stepper1Component,
    Stepper2Component,
    LimitesComponent,
    EncerrarContaComponent,
    ChavesPixComponent,
    LimitePixComponent,
    PortabilidadePixComponent,
    ChavesPixContatoComponent,
    ModalMotivoEncerramentoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgxMaskModule.forChild(),
    NgxCurrencyModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatTabsModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatButtonModule,
    IconsModule,
    NgSelectModule,
    UploadArquivoModalModule,
    GerenciarArquivosListaModule,
    InputAtivarDesativarModule,
    DirectiveModule,
    UploadArquivosModule,
    FlexLayoutModule,
    PipeModule,
    MatStepperModule,
    NgxMaskModule,
    MatSliderModule,
  ],
  exports: [
    PessoaCoreEditarComponent,
    GeralComponent,
    EnderecoComponent,
    TelefoneComponent,
    EmailComponent,
    RepresentanteLegalComponent,
    DocumentacaoComponent,
    TelefoneNotificacaoComponent,
    EmailNotificacaoComponent,
    PlanoEmpresaComponent
  ],
})
export class PessoaCoreEditarModule { }
