import { PermissionamentoEndpointService } from "@project/src/app/service/permissionamento.service";
import { Component, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@src/environments/environment";
import { AutenticacaoEndpointService } from "@service/autenticacao-endpoint.service";
import { PaginaService } from "@service/pagina.service";
import { UsuarioEndpointService } from "@service/usuario-endpoint.service";
import { AdminService } from "../admin.service";
import { StringFormatService } from "@project/src/app/service/util/stringFormater.service";
import { EmpresaEndpointService } from "@project/src/app/service/empresa-endpoint.service";
import { CheckAuthoritiesService } from "@project/src/app/service/util/checkAuthorities.service";
import { Router } from "@angular/router";
import { SelecionarCorporacaoComponent } from "@project/src/app/components/selecionar-corporacao/selecionar-corporacao.component";

declare const $: any;

export interface RouteInfo {
  path: string;
  info: string;
  role?: string;
  load?: boolean;
  permite?: boolean;
  collapse?: string;
  children?: RouteInfo[];
}

export var ROUTES: RouteInfo[];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.less"],
})
export class SidebarComponent implements OnDestroy {
  public menuItems: any[];
  corporacaoLogada: any;
  empresaLogada: any;
  usuarioLogado: any;
  empresaList: any = [];
  corporacaoList: any = [];
  escondeTitulos = true;
  env = environment;
  doc: string;
  sidebarOpen: boolean = false;
  public matToltipText = "";
  modifiedText = false;
  ehAdmin = false;
  ehOperador = false;
  naoBloquearCobranca = false;
  areaPix = false;
  checkAuthorities: CheckAuthoritiesService;
  emModeracao: boolean;

  constructor(
    public paginaService: PaginaService,
    public autenticacao: AutenticacaoEndpointService,
    public dialog: MatDialog,
    private usuario: UsuarioEndpointService,
    private adminService: AdminService,
    private empresaService: EmpresaEndpointService,
    public _rolePermission: PermissionamentoEndpointService,
    public stringFormat: StringFormatService,
    private router: Router
  ) {
    const userData = this.autenticacao?.sessao;
    this.checkAuthorities = new CheckAuthoritiesService(userData);
    this.ehAdmin = this.checkAuthorities?.isAdministrador;
    this.ehOperador = this.checkAuthorities?.isOperador;
    this.getRotas()
    this.usuarioLogado = userData?.principal?.usuario;
    this.getItensLogados()

    this.adminService.pegaUpdate().subscribe((sidebarBool) => {
      this.sidebarOpen = sidebarBool;
    });
  }

  async getItensLogados(){
    this.emModeracao = ['aguardandoModeracao', 'incompleto'].includes(this.usuarioLogado?.moderacao)

    if (this.usuarioLogado.moderacao !== 'incompleto') { 
      const [ regraCorporacaoLogada, regraEmpresaLogada] = await Promise.all([
        this.usuario.getRegraCorporacaoLogada().toPromise(),
        this.usuario.getRegraEmpresaLogada().toPromise()
      ])
      this.corporacaoLogada = regraCorporacaoLogada;
      this.empresaLogada = regraEmpresaLogada.empresa;
      this.areaPix = this.empresaLogada.configuracao?.areaPix;
      this.atualizarMenu()
    }
  }

  atualizarMenu(){
    this.getEmpresasDaCorporacao(this.corporacaoLogada.corporacao.id);
    this.menuItems = ROUTES.filter((menuItem) =>
      menuItem.load == null ? true : menuItem.load
    );
          
    for (const item of this.menuItems) {
      if (item.role && !this.ehAdmin) {
        item.permite = this.checkAuthorities.permissaoGuard(item.role, this.empresaLogada.id);
      } else {
        item.permite = true;
      }
    }
  }

  getRotas(){
    ROUTES = [
      {
        path: "principal",
        info: "dashboard",
      },
      {
        path: "pessoa/contato",
        info: "contato",
        role: this.ehOperador ? "ROLE_EMPRESA_PESSOAS_VISUALIZAR" : "ROLE_CONTATO_LISTAR",
      },
      {
        path: "cobranca",
        info: "cobranca",
        load: environment.modulos.cobranca.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_COBRANCAS_VISUALIZAR" : "ROLE_COBRANCA_LISTAR",
      },
      {
        path: "pix",
        info: "pix",
        load: environment.modulos.pix.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_PIX_VISUALIZAR" : ""
      },
      {
        path: "pague-contas",
        info: "pague-contas",
        load: environment.modulos.pagamentoContas.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_PAGAMENTOS_VISUALIZAR" : "ROLE_PAGAMENTOCONTAS_LISTAR",
      },
      {
        path: "extrato",
        info: "extrato",
        load: environment.modulos.extrato.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_EXTRATOFINANCEIRO_VISUALIZAR" : "ROLE_EXTRATO_LISTAR",
      },
      {
        path: "investimentos",
        load: environment.modulos.investidor.sidebar,
        info: "investimentos",
        role: this.ehOperador ? "ROLE_EMPRESA_INVESTIMENTOS_ATUALIZAR" : "ROLE_INVESTIMENTO_ATUALIZAR",
      },
      {
        path: "transferencia",
        info: "transferencia",
        load: environment.modulos.transferencia.sidebar,
        role: this.ehOperador ? "ROLE_EMPRESA_TRANSFERENCIAS_VISUALIZAR" : "ROLE_TRANSFERENCIA_LISTAR",
      },
      {
        path: "cartao",
        load: environment.modulos.cartaoCredito.sidebar,
        info: "cartao",
      },
      {
        path: "credito",
        load: environment.modulos.credito.sidebar,
        info: "credito",
        role: this.ehOperador ? "ROLE_EMPRESA_CREDITO_VISUALIZAR" : ""
      },
      {
        path: "indicar-amigos",
        info: "indicar-amigos",
        load: environment.modulos.convite.indicarAmigos,
        role: this.ehOperador ? "ROLE_EMPRESA_PESSOAS_VISUALIZAR" : "ROLE_CONVIDARAMIGO_LISTAR",
      }
    ];
  }

  ngOnDestroy() { }

  async getEmpresasDaCorporacao(idCorporacao) {
    let page = 0, size = 20;
    if (this.ehAdmin) {
      return true;
    }
    const [corporacoes, empresas] = await Promise.all([
      this.usuario.getCorporacoesDoUsuario().toPromise(),
      this.empresaService.listar({ idCorporacao, page, size }).toPromise()
    ])

    this.corporacaoList = corporacoes;
    this.empresaList = empresas.content;
  }

  visualiza(route: RouteInfo) {
    if (this.empresaLogada) {
      if (this.empresaLogada.tipoConta === "contaDigitalS" && route.info === "investimentos") {
        return false;
      }
      if (!['aprovada', 'bloqueada'].includes(this.empresaLogada.moderacaoEmpresa) && !this.ehAdmin && !this.checkAuthorities.isColaborador){
        return false;
      }else{
        return route.permite;
      }
    }
    
    if (!!route.children && route.children.length) {
      for (const children of route.children) {
        if (!this.visualiza(children)) {
          return false;
        }
      }
    }
    const pagina = this.paginaService.obter(route.info);
    return this.autenticacao.permite(pagina.permissoes);
  }

  selecionaCorporacao() {
    this.dialog.open(SelecionarCorporacaoComponent, {
      width: "800px",
      panelClass: "selecionar-corporacao",
    })
  }

  adicionarPontosDocumento() {
    const documento = this.empresaLogada.pessoa.documento;
    return (
      documento.substring(0, 2) +
      "." +
      documento.substring(2, 5) +
      "." +
      documento.substring(5, 8) +
      "/" +
      documento.substring(8, 12) +
      "-" +
      documento.substring(12, 14)
    );
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  updatePS(): void {
    // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    //   const elemSidebar = <HTMLElement>(
    //     document.querySelector('.sidebar .sidebar-options')
    //   );
    //   const ps = new PerfectScrollbar(elemSidebar, {
    //     wheelSpeed: 2,
    //     suppressScrollX: true
    //   });
    // }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  mudaTexto(menuItem) {
    if (!this.visualiza(menuItem)) {
      if (
        menuItem.info === "investimentos" &&
        this.empresaLogada.tipoConta === "contaDigitalS" && !this.ehAdmin
      ) {
        return "Funcionalidade não disponível para Conta Cobrança Digital";
      }

      return "Você não pode acessar essa funcionalidade";
    }  else if (
      (
        ((this.empresaLogada.numIdentcBenfcrio === null || this.empresaLogada.numIdentcBenfcrio == "0")
        && this.empresaLogada.tipoConta !== "contaDigitalS") ||
        (this.env.modulos.pix.gerarQrCode && !this.areaPix && (this.empresaLogada.numIdentcBenfcrio === null || this.empresaLogada.numIdentcBenfcrio == "0") && this.empresaLogada.tipoConta !== "contaDigitalS")
      ) && menuItem.info === "cobranca"
    ) {
      return "Conta não cadastrada para emissão de boletos. Entre em contato com seu gerente.";
    } else if (
      !this.empresaLogada.configuracao.habilitaPagamentos && menuItem.info === "pague-contas" && !this.ehAdmin
    ) {
      return "Conta não cadastrada para pagamentos. Entre em contato com seu gerente.";
    } else if (
      (this.env.modulos.pix.gerarQrCode === true && this.areaPix === false) && menuItem.info === "pix"
    ) {
      return "Conta não cadastrada para Pix. Entre em contato com seu gerente.";
    }
    else {
      return null;
    }
  }

  navegar(menuItem) {
    if (this.visualiza(menuItem)) {
      this.router.navigate([`privado/${menuItem.path}`])
    }
  }
}
