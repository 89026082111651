<div>
    <h2 style="padding-left: 10%;" mat-dialog-title class="titulo-modal">
        PERSONALIZAÇÃO GERAL <i (click)="fecha()" class="material-icons btn-fechar">close</i>
        <span id="fechar" (click)="fecha()">FECHAR</span>
    </h2>

    <div mat-dialog-content class="mat-dialog-content-responsive" >
        <div class=" mudaCor input-size row" style="margin: 0px 10px; border-radius: 5px; padding: 0.5rem; gap: 0.5rem">
            <mat-tab-group mat-vertical-tabs class="col-12">
                <mat-tab>
                    <ng-template mat-tab-label class="tab-label">
                      <i class="icon-tab fas fa-gears"></i>
                      <span style="font-size: 0.8vw;">Geral</span>
                    </ng-template>
                    <div class="box-body d-flex flex-row justify-content-around" style="flex-wrap: wrap;">
                        <div class="config-baixa" *ngIf="env?.modulos?.onboard?.pessoaFisica">
                            <div class="icon-config">
                                <i class="fas fa-user"></i>
                            </div>                            
                            <p class="descricao-cobranca">Permitir cadastro pessoa física</p>
                            <div class="card-toggle">
                                <mat-slide-toggle (change)="config.permitirCadastroPessoaFisica = !config.permitirCadastroPessoaFisica" 
                                    class="d-flex-start" [checked]="config?.permitirCadastroPessoaFisica ">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="config-baixa" *ngIf="env?.modulos?.onboard?.pessoaJuridica">
                            <div class="icon-config">
                                <i class="fas fa-building"></i>
                            </div> 
                            <p class="descricao-cobranca">Permitir cadastro pessoa jurídica</p>
                            <mat-slide-toggle (change)="config.permitirCadastroPessoaJuridica = !config.permitirCadastroPessoaJuridica" 
                                    class="d-flex-start" [checked]="config?.permitirCadastroPessoaJuridica ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-left-right"></i>
                            </div> 
                            <p class="descricao-cobranca">Fixar barra lateral expandida</p>
                            <mat-slide-toggle (change)="config.fixaSidebar = !config.fixaSidebar" 
                                    class="d-flex-start" [checked]="config?.fixaSidebar ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-dollar-sign"></i>
                            </div> 
                            <p class="descricao-cobranca">Step de informações financeiras</p>
                            <mat-slide-toggle (change)="configuracoesOnboarding.informacoesFinanceiras = !configuracoesOnboarding.informacoesFinanceiras" 
                                    class="d-flex-start" [checked]="configuracoesOnboarding?.informacoesFinanceiras">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-user-tie"></i>
                            </div> 
                            <p class="descricao-cobranca">Código de assessor obrigatório</p>
                            <mat-slide-toggle (change)="configuracoesOnboarding.codigoAssessorObrigatorio = !configuracoesOnboarding.codigoAssessorObrigatorio" 
                                    class="d-flex-start" [checked]="configuracoesOnboarding?.codigoAssessorObrigatorio">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-user-tie"></i>
                            </div> 
                            <p class="descricao-cobranca">Código de assessor ativado</p>
                            <mat-slide-toggle (change)="configuracoesOnboarding.codigoAssessorAtivado = !configuracoesOnboarding.codigoAssessorAtivado" 
                                    class="d-flex-start" [checked]="configuracoesOnboarding?.codigoAssessorAtivado">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-user-tie"></i>
                            </div> 
                            <p class="descricao-cobranca">Código de gerente de contas ativado</p>
                            <mat-slide-toggle (change)="configuracoesOnboarding.codigoGerenteContaAtivado = !configuracoesOnboarding.codigoGerenteContaAtivado" 
                                    class="d-flex-start" [checked]="configuracoesOnboarding?.codigoGerenteContaAtivado">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-rectangle-list"></i>
                            </div> 
                            <p class="descricao-cobranca">Propósito de abertura de conta obrigatório</p>
                            <mat-slide-toggle (change)="configuracoesOnboarding.propositoAberturaObrigatorio = !configuracoesOnboarding.propositoAberturaObrigatorio" 
                                    class="d-flex-start" [checked]="configuracoesOnboarding?.propositoAberturaObrigatorio">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label class="tab-label">
                      <i class="icon-tab fas fa-right-left"></i>
                      <span style="font-size: 0.8vw;">Transferências</span>
                    </ng-template>
                    <div class="box-body d-flex flex-row justify-content-around" style="flex-wrap: wrap;">
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-address-card"></i>
                            </div>   
                            <p class="descricao-cobranca">Somente transferências de mesma titularidade</p>    
                            <mat-slide-toggle (change)="config.somenteMesmaTitularidade = !config.somenteMesmaTitularidade" 
                                    class="d-flex-start" [checked]="config?.somenteMesmaTitularidade ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-money-bill-transfer"></i>
                            </div>   
                            <p class="descricao-cobranca">Ativar transferências externas</p>    
                            <mat-slide-toggle (change)="config.transferenciaExternaAtivada = !config.transferenciaExternaAtivada" 
                                    class="d-flex-start" [checked]="config?.transferenciaExternaAtivada ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-money-bill-transfer"></i>
                            </div>   
                            <p class="descricao-cobranca">Ativar transferências internas</p>    
                            <mat-slide-toggle (change)="config.transferenciaInternaAtivada = !config.transferenciaInternaAtivada" 
                                    class="d-flex-start" [checked]="config?.transferenciaInternaAtivada ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-money-bill-transfer"></i>
                            </div>   
                            <p class="descricao-cobranca">Ativar transferências entre agências</p>    
                            <mat-slide-toggle (change)="config.transferenciaEntreAgenciasAtivada = !config.transferenciaEntreAgenciasAtivada" 
                                    class="d-flex-start" [checked]="config?.transferenciaEntreAgenciasAtivada ">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label class="tab-label">
                      <i class="icon-tab fas fa-barcode"></i>
                      <span style="font-size: 0.8vw;">Pagamento de Contas</span>
                    </ng-template>
                    <div class="box-body d-flex flex-row justify-content-around" style="flex-wrap: wrap;">
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-barcode"></i>
                            </div>       
                            <p class="descricao-cobranca">Ativar alçada de pagamento de contas</p>
                            <mat-slide-toggle (change)="config.alcadaPagamentoAtivada = !config.alcadaPagamentoAtivada" 
                                    class="d-flex-start" [checked]="config?.alcadaPagamentoAtivada ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-barcode"></i>
                            </div>       
                            <p class="descricao-cobranca">Ativar pagamento de contas</p>
                            <mat-slide-toggle (change)="config.pagamentoContasAtivado = !config.pagamentoContasAtivado" 
                                    class="d-flex-start" [checked]="config?.pagamentoContasAtivado ">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label class="tab-label">
                      <i class="icon-tab fas fa-copy"></i>
                      <span style="font-size: 0.8vw;">Cobranças</span>
                    </ng-template>
                    <div class="box-body d-flex flex-row justify-content-around" style="flex-wrap: wrap;">
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-copy"></i>
                            </div>       
                            <p class="descricao-cobranca">Ativar cobranças</p>
                            <mat-slide-toggle (change)="config.cobrancaAtivada = !config.cobrancaAtivada" 
                                    class="d-flex-start" [checked]="config?.cobrancaAtivada ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-file-signature"></i>
                            </div>       
                            <p class="descricao-cobranca">Exibir nome beneficiário boleto</p>
                            <mat-slide-toggle (change)="config.exibirNomeBeneficiarioBoleto = !config.exibirNomeBeneficiarioBoleto" 
                                    class="d-flex-start" [checked]="config?.exibirNomeBeneficiarioBoleto ">
                            </mat-slide-toggle>
                        </div>
                        <div class="config-baixa">
                            <div class="icon-config">
                                <i class="fas fa-file-image"></i>
                            </div>       
                            <p class="descricao-cobranca">Exibir logo personalizado boleto</p>
                            <mat-slide-toggle (change)="config.exibirLogoPersonalizadoBoleto = !config.exibirLogoPersonalizadoBoleto" 
                                    class="d-flex-start" [checked]="config?.exibirLogoPersonalizadoBoleto ">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div mat-dialog-actions style="display: block;overflow: initial; margin: 0;border-radius: 4px;">
        <span class="div-interna flex-end" style="align-items: center; padding: 0px 40px; border-radius: 4px; margin: 0;">
            <button (click)="salvaConfig()" class="btn btn-default next fundoCorPrimaria" style="width: 15%;" >Salvar</button>
        </span>
    </div>
</div>

