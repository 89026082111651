import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ArquivoDTO } from '@project/src/app/model/sistema/arquivo-dto.model';
import { UtilCaniveteService } from '@project/src/app/service/util/util-canivete.service';
import moment from 'moment';
import Swal from 'sweetalert2';
import { PagamentoLoteSistemaDTO } from '@project/src/app/model/financeiro/pagamento/pagamento-lote-sistema-dto.model';
import { environment } from '@project/src/environments/environment.default';
import { PagamentoEndpointService } from '@project/src/app/service/pagamento-endpoint.service';

@Component({
  selector: 'app-pagamento-lote-sistema-upload',
  templateUrl: './pagamento-lote-sistema-upload.component.html',
  styleUrls: ['./pagamento-lote-sistema-upload.component.less']
})
export class PagamentoLoteSistemaUploadComponent implements OnInit {
  form: FormGroup;
  dadosValidacao: PagamentoLoteSistemaDTO;
  mostrarResumo = false;
  env = environment;
  dataValidacao: Date;
  consultaRealizada = false;

  @Input()
  data: PagamentoLoteSistemaDTO;

  constructor(
    private pagamentoService: PagamentoEndpointService,
    private changeDetectorRef: ChangeDetectorRef,
    private utilCaniveteService: UtilCaniveteService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({ });
    this.form.addControl('locale', new FormControl(null, Validators.required));
    this.form.addControl('base64', new FormControl(null, Validators.required));
    this.form.addControl('mimeType', new FormControl(null, Validators.required));
    this.form.addControl('nome', new FormControl(null, Validators.required));
    this.form.addControl('tamanho', new FormControl(null, Validators.required));
    this.form.addControl('validado', new FormControl(null, Validators.required));
    this.downloadFalhas();
  }

  async downloadFalhas() {
    if (this.data && this.data.id >= 0) {
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      });
      this.dadosValidacao = await this.pagamentoService.downloadFalhasSistema(this.data.id).toPromise();
      this.form.patchValue(this.dadosValidacao.arquivo);
      if (this.dadosValidacao && this.dadosValidacao.status) {
        this.form.get('validado').patchValue(true);
      } else {
        this.form.get('validado').patchValue(null);
      }
      this.changeDetectorRef.detectChanges();
      Swal.close();
    }
  }

  getForm(): AbstractControl {
    return this.form;
  }

  isValid(): boolean {
    return this.form.valid && this.consultaRealizada && this.dadosValidacao.itens.filter(i => !!i.status).length > 0;
  }
  
  parse(model: PagamentoLoteSistemaDTO): PagamentoLoteSistemaDTO {
    model = model || { };
    model.arquivo = this.form.value;
    return model;
  }

  async upload(arquivos: ArquivoDTO[]) {
    if (arquivos && arquivos.length) {
      Swal.fire({
        title: 'Processando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      });
      const arquivo = arquivos[0];
      this.form.patchValue(arquivo);
      this.dadosValidacao = await this.pagamentoService.validarLoteSistema(arquivo).toPromise();
      if (this.dadosValidacao && this.dadosValidacao.status) {
        this.form.get('validado').patchValue(true);
        this.mostrarResumo = true;
        this.dataValidacao = new Date()
        this.consultarBoletos()
      } else {
        this.form.get('validado').patchValue(null);
      }
      this.changeDetectorRef.detectChanges();
      await Swal.close();
    }
  }

  async consultarBoletos(){
    for (const item of this.dadosValidacao.itens) {
      const updatedItem = await this.pagamentoService.consultarBoletoSistema(item).toPromise();
      Object.assign(item, updatedItem); 
    }

    const loteValidado = await this.pagamentoService.validacaoFinalLoteSistema(this.dadosValidacao).toPromise();
    Object.assign(this.dadosValidacao, loteValidado)
    
    this.consultaRealizada = true;
  }

  download() {
    this.utilCaniveteService.downloadFileXlsx(this.dadosValidacao.arquivo.base64,
      `v${moment().format('YYYY-MM-DD-HH-mm-SS')}_${this.dadosValidacao.arquivo.nome}`);
  }
}